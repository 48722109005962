/**
 * handles key controls for biome 2
 */
import * as THREE from 'three'

export const keyboardMixin = {
  methods: {
    /**
     * 'Space' or 'Enter': grab or drop the closest container
     * 'V': show controls
     * 'C': debug in console
     */
    onKeyPress: function(event) {
      if (['Space', 'Enter'].includes(event.code)) {
        if (!this.newBrick && this.nearestCharacter) {
          this.createBrick()
        }
      } else if (event.code == 'KeyV') {
        this.showControls = !this.showControls
      } else if (event.code == 'KeyC') {
        console.debug(this.player.position)
      }
    },

    /**
     * Set player movements
     * 'left' or 'A': go left
     * 'right' or 'D': go right
     * 'shift': run
     */
    onKeyDown: function(event) {
      const keyCode = event.code
      if (keyCode == 'ArrowLeft' || keyCode == 'KeyA') {
        this.player.moveDir.setX(-1)
      }
      if (keyCode == 'ArrowRight' || keyCode == 'KeyD') {
        this.player.moveDir.setX(1)
      }
      if (keyCode == 'ArrowUp' || keyCode == 'KeyW') {
        this.player.moveDir.setZ(-1)
      }
      if (keyCode == 'ArrowDown' || keyCode == 'KeyS') {
        this.player.moveDir.setZ(1)
      }
      if (['ShiftLeft', 'ShiftRight'].includes(event.code)) {
        this.player.runningFactor = 5
      }
    },

    /**
     * Stop player movements
     * 'up' or 'W': go forward
     * 'down' or 'S': go backward
     * 'left' or 'A': rotate left
     * 'right' or 'D': rotate right
     * 'shift': run
     */
    onKeyUp: function(event) {
      const keyCode = event.code
      if (keyCode == 'ArrowLeft' || keyCode == 'KeyA') {
        this.player.moveDir.setX(0)
      }
      if (keyCode == 'ArrowRight' || keyCode == 'KeyD') {
        this.player.moveDir.setX(0)
      }
      if (keyCode == 'ArrowUp' || keyCode == 'KeyW') {
        this.player.moveDir.setZ(0)
      }
      if (keyCode == 'ArrowDown' || keyCode == 'KeyS') {
        this.player.moveDir.setZ(0)
      }
      if (['ShiftLeft', 'ShiftRight'].includes(event.code)) {
        this.player.runningFactor = 1
      }
    }
  }
}
