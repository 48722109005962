<template>
  <div>
    <h1>
      <img
        src="@/assets/images/fr/about.png"
        class="image-title"
      />
    </h1>
    <div class="text">
      <p>
        <i>Sphinx</i> propose la traversée d’une énigme, celle de l’incarnation des idées.
      </p>
      <p>
        Entreprendre l’idée et les concepts comme une matière permet de les placer à hauteur d’homme, pour pouvoir les manipuler. La figure du Sphinx incarne, dans le jeu, l’idée que le savoir est à utiliser comme n’importe quelle autre matière. Ainsi, le jeu vidéo convoque la théorie de la Noosphère. Définie par Vladimir Vernadsky et Pierre Teilhard de Chardin comme la Sphère des idées, la Noosphère englobe la terre, comme la stratosphère ou l'atmosphère, et se trouve incarnée en termes physiques. Elle est reconfigurée en permanence par la transformation qu’on en fait. Les idées semblent descendues de cette émanation vaporeuse qu’est la noosphère, et alimentent nos pensées. Digérées, elles s'incarnent par des mots, par une formulation. Cette traduction opère une modification de la matière brute de l’idée initiale et vient réalimenter la noosphère.
      </p>
      <p>    
        <i>Sphinx</i> est né à l’occasion d’ateliers menés par l'artiste Io Burgard auprès des élèves de la classe Ulis du Collège Beau Soleil de Chelles, Seine-et-Marne, programme porté par Orange Rouge. Engagés sur les questions de rencontre, les élèves et l’artiste ont travaillé à un espace la favorisant. Ces échanges ont mené l'artiste à imaginer une œuvre interactive qui reprend les codes du jeu vidéo, l’espace digital étant devenu un espace de rencontre privilégié pour les adolescents.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutFr'
}
</script>

<style scoped src="@/assets/css/menus.css" />
