<template>
  <div class="home">
    <transition-group name="home" tag="div">
      <div
        v-show="idle"
        class="main-logo"
        key="sphinx"
        @click="idle = false"
      >
        <img src="@/assets/images/sphinx.png" alt="Sphinx" />
      </div>

      <div
        v-show="!idle"
        class="container"
        key="menu"
        @mouseover="onMouseMove"
      >  
        <div class="links">
          <a
            v-for="(button, idx) in links"
            :key="`button-link-${idx}`"
            :ref="`button-link-${idx}`"
            :class="['link', { active: idx == currentButton }]"
            @click="button.action"
            @mouseenter="currentButton = idx"
            @mouseleave="currentButton = 0"
          >
            <img :src="button.imgSrc" />
          </a>
        </div>
      
        <div class="languages">
          <template v-for="(button, idx) in langButtons">
            <a
              :key="`button-lang-${idx}`"
              :ref="`button-lang-${idx}`"
              :class="['link', { active: idx + links.length == currentButton }]"
              @click="button.action"
              @mouseover="currentButton = idx + links.length"
              @mouseleave="currentButton = 0"
            >
              <img :src="button.imgSrc" class="image-title" />
            </a>
            <img
              v-if="idx == 0"
              src="@/assets/images/slash.png"
              alt="/"
              class="image-title"
              >
          </template>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
import { mapState } from 'vuex'

const IDLE_TIME = 5000

export default {
  name: 'Home',
  /**
   * data to keep track of the state of the Home screen
   * intervalId: id of the interval function that rotates screen
   * idle: state of the game
   * - true: no interaction, display SPHINX
   * - false: interaction within the latest 5 seconds, display menu
   * currentButton: position in the menu
   */
  data: function () {
    return {
      intervalId: null,
      idle: true,
      currentButton: 0,
      hadoken: '',
    }
  },
  computed: {
    ...mapState({
      lang: (state) => state.lang,
      currentLevel: (state) => state.currentLevel,
      currentMenu: (state) => state.currentMenu,
    }),
    /**
     * array of objects to define action buttons on home screen
     * - 'action': callback function when clicked
     * - 'imgSrc': button's image asset
     */
    links: function () {
      return [
        {
          action: () => this.startGame(),
          imgSrc: require('@/assets/images/' + this.lang + '/play.png'),
        },
        {
          action: () => this.$store.commit('setMenu', 'Instructions'),
          imgSrc: require('@/assets/images/' + this.lang + '/instructions.png'),
        },
        {
          action: () => this.$store.commit('setMenu', 'About'),
          imgSrc: require('@/assets/images/' + this.lang + '/about.png'),
        },
        {
          action: () => this.$store.commit('setMenu', 'Credits'),
          imgSrc: require('@/assets/images/' + this.lang + '/credits.png'),
        },
      ]
    },
    /**
     * array of available languages
     * - 'action': callback function when clicked (change language)
     * - 'imgSrc': button's image asset
     */
    langButtons: function () {
      return [
        {
          action: () => this.$store.commit('setLang', 'en'),
          imgSrc: require('@/assets/images/en.png'),
        },
        {
          action: () => this.$store.commit('setLang', 'fr'),
          imgSrc: require('@/assets/images/fr.png'),
        },
      ]
    },
    /**
     * It includes both the 'links' and the 'languages'
     * It is used in 'keyup' method to go from one button to the next
     * (or previous)
     */
    buttons: function () {
      return [...this.links, ...this.langButtons]
    },
  },
  mounted: function () {
    /* enables idle timer */
    this.setRotation()
    /* keyboard event listener init */
    window.addEventListener('keydown', this.onKeyDown)
    window.addEventListener('keyup', this.onKeyUp)
  },
  beforeDestroy: function () {
    /* removes idle timer */
    clearInterval(this.intervalId)
    /* remove keyboard event listener */
    window.removeEventListener('keydown', this.onKeyDown)
    window.removeEventListener('keyup', this.onKeyUp)
  },
  methods: {
    /**
     * Start the game by going to first level
     */
    startGame: function () {
      this.$store.commit('setLevel', 1)
    },
    /**
     * Starts rotation between screens.
     */
    setRotation: function () {
      if (this.intervalId) {
        clearInterval(this.intervalId)
      }
      this.intervalId = setInterval(() => {
        // to reset buttons after a rotation
        this.currentButton = 0
        // to rotate screen using transition-group
        this.idle = !this.idle
      }, IDLE_TIME)
    },
    onMouseMove: function(event) {
      this.setRotation()
      this.idle = false
    },
    /**
     * callback for 'keydown' event:
     * - simply check what key was pressed to match an hadoken combo
     */
    onKeyDown: function (event) {
      const kc = event.code
      
      if (kc == 'KeyS' || kc == 'ArrowDown') {
        this.hadoken = 's'
      } else if (kc == 'KeyD' || kc == 'ArrowRight') {
        if (this.hadoken == 's') {
          this.hadoken = 'sd'
        }
      } else if (kc == 'Enter' || kc == 'Space') {
        if (this.hadoken == 'sd') {
          this.hadoken = 'sd1'
        }
      } else {
        this.hadoken = ''
      }
    },
    /**
     * callback for 'keyup'  event:
     * - delay rotation
     * - if home screen > display menu
     * - else > check if key has an effect (arrows, enter or space)
     * - then check what key was pressed to match an hadoken combo
     * and redirect to art screen
     * NOTE DEV: can be factorized for all menus
     */
    onKeyUp: function (event) {
      const kc = event.code
      /* restarts idle timer */
      this.setRotation()
      if (this.idle) {
        this.idle = false
      }
      else {
        /* keys handlers */
        if (kc == 'KeyW' || kc == 'ArrowUp' || kc == 'KeyA' || kc == 'ArrowLeft') {
          this.currentButton =
            (this.currentButton - 1 + this.buttons.length) % this.buttons.length
        }
        if (kc == 'KeyS' || kc == 'ArrowDown' || kc == 'KeyD' || kc == 'ArrowRight') {
          this.currentButton = (this.currentButton + 1) % this.buttons.length
          if (this.hadoken != 'sd') {
            this.hadoken = ''
          }
        }
        if (kc == 'Enter' || kc == 'Space') {
          if (this.hadoken == 'sd1') {
            this.$store.commit('setMenu', 'Art')
          } else {
            this.buttons[this.currentButton].action()
          }
        }
        // console.log(this.currentButton)
      }
    }
  }
}
</script>

<style src="@/assets/css/menus.css" scoped></style>
