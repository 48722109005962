import * as THREE from 'three'
import Stats from 'three/examples/jsm/libs/stats.module'

export const sceneMixin = {
  data: function() {
    return {
      /* Main scene */
      canvas: {},
      sceneContainer: {},
      scene: {},
      sceneCenter: new THREE.Vector3(0, 0, 0),
      renderWidth: 0,
      renderHeight: 0,
      axesHelper: new THREE.AxesHelper(5),
      renderer: {},
    }
  },
  methods: {
    freeScene: function() {
      window.removeEventListener('resize', this.onResize)
      window.removeEventListener('pointermove', this.onPointerMove)
      window.removeEventListener('keypress', this.onKeyPress)
      window.removeEventListener('keydown', this.onKeyDown)
      window.removeEventListener('keyup', this.onKeyUp)
      window.removeEventListener('click', this.onMouseClick)
    },

    initScene: function() {
      /* THREEJS 3D CANVAS AND RENDERER */
      this.sceneContainer = document.getElementById('biome')
      this.canvas = this.sceneContainer.querySelector('canvas')
      this.renderWidth = this.sceneContainer.clientWidth
      this.renderHeight = this.sceneContainer.clientHeight

      /* SCENE initialisation */
      this.scene = new THREE.Scene()
      // this.scene.add(this.axesHelper)

      /* RENDERER */
      this.renderer = new THREE.WebGLRenderer({
        antialias: true,
        canvas: this.canvas,
      })
      
      //this.renderer.shadowMap.enabled = true
      // this.renderer.physicallyCorrectLights = true
      // this.renderer.toneMappingExposure = 1.0
      // this.renderer.outputEncoding = THREE.sRGBEncoding
      // this.renderer.toneMapping = THREE.ACESFilmicToneMapping

      const ambiant = new THREE.AmbientLight(0xaa9966, 1)
      this.scene.add(ambiant)
      const sun = new THREE.DirectionalLight(0xffffff, .3)
      sun.position.set(-5.1, 3, -5)
      sun.target.position.set(0, 0, 0)
      
      this.scene.add(sun)
      
      /* FPS-meter */
      if (this.debug) {
        this.stats = new Stats()
        this.$el.querySelector('#stats-container').append(this.stats.dom)
      }
      
      /* Raycasting initialisation */
      this.raycaster = new THREE.Raycaster()
      this.raycaster.far = 0.02

      /* axis helper */
      // const ax = new THREE.AxesHelper(5)
      // this.scene.add(ax)
      this.$store.commit("setHidden", false)
    },

    /**
     * Scene renderers update
     */
    render: function() {
      for (const mixer of this.mixers) {
        mixer.update(this.dt)
      }
      this.renderer.render(this.scene, this.mainCamera)
    },

    setEnvironmentalMap: function() {
      this.scene.environment = this.assets.env
      this.scene.environment.mapping = THREE.EquirectangularReflectionMapping
    }
    
  }
}
