<template>
  <div id="app" :class="[appVersion, { toggling: toggling, }]">
    <template v-if="assetsReady && userAction">
      <div
        key="game"
        id="biome"
        :class="[{ hidden: hidden, animating: animating && !isPaused }]"
      >
        <div class="band" />
        <!-- <TestScene /> -->
        <Biome1 v-if="currentLevel == 1" key="level1" />
        <Biome2 v-if="currentLevel == 2" key="level2" />
        <Biome3 v-if="currentLevel == 3" key="level3" />
        <Biome4 v-if="currentLevel == 4" key="level4" />
        <!-- <ToBeContinued v-if="currentLevel > 3" key="tbc" /> -->
      </div>
      <div v-show="isPaused" key="menu" id="menu">
        <Pause v-if="isPaused" />
      </div>
      <div v-if="currentLevel == 0">
        <Menu />
      </div>
    </template>
    <template v-else>
      <div id="app-loader">
        <p>{{ appName }} {{ appRelease }}
          <template v-if="appVersion == 'web'"><br>For a better experience, please use the Chrome browser.</template>
        </p>
        <div class="bar">
          <div
            :style="`width: calc(100% * ${loadedElements} / ${loadingElements})`"
          />
        </div>
        <p>
          &nbsp;
          <span v-if="assetsReady && loadedElements == loadingElements">
            ROM loaded. Press button A to boot.
          </span>
          &nbsp;
        </p>
      </div>
    </template>
  </div>
</template>

<script>
/* vue imports */
import { mapState } from 'vuex'
/* game levels imports */
import TestScene from '@/components/biomes/TestScene'
import Biome1 from '@/components/biomes/biome1/Biome1'
import Biome2 from '@/components/biomes/biome2/Biome2'
import Biome3 from '@/components/biomes/biome3/Biome3'
import Biome4 from '@/components/biomes/biome4/Biome4'
// import ToBeContinued from '@/components/biomes/ToBeContinued'
/* menu components */
import Menu from '@/components/Menu'
import Pause from '@/components/Pause'

export default {
  components: {
    Biome1,
    Biome2,
    Biome3,
    Biome4,
    Menu,
    Pause,
    TestScene,
    // ToBeContinued,
  },
  data: function () {
    return {
      userAction: false,
      toggling: false,
      assetsReady: false,
    }
  },
  /**
   * compute general game, display, and loading values
   */
  computed: {
    appName: () => process.env.VUE_APP_TITLE.toUpperCase(),
    appRelease: () => process.env.VUE_APP_RELEASE,
    appVersion: () => process.env.VUE_APP_VERSION,
    ...mapState({
      loadingElements: (state) => state.loadingElements,
      loadedElements: (state) => state.loadedElements,
      currentLevel: (state) => state.currentLevel,
      isPaused: (state) => state.pause,
      animating: (state) => state.animating,
      hidden: (state) => state.hidden,
    }),
  },
  /**
   * preload biome assets upon creation
   */
  created: function () {
    Promise.all([
      this.$store.dispatch('biome1/preload'),
      this.$store.dispatch('biome2/preload'),
      this.$store.dispatch('biome3/preload'),
      this.$store.dispatch('biome4/preload'),
    ]).then(() => {
      this.assetsReady = true
    })
  },
  mounted: function () {
    window.addEventListener('keydown', this.onKeyDown)
  },
  beforeDestroy: function () {
    window.removeEventListener('keydown', this.onKeyDown)
  },
  methods: {
    /**
     * creates a general audio context if none is available
     * biome/audio components should:
     * - use it if available,
     * - resume it if suspended, or
     * - renew it if closed
     */
    initAudioContext: function () {
      this.userAction = true
      if (!this.$store.state.audioCtx) {
        const audioCtx = new AudioContext({
          sampleRate: 44100,
        })
        console.debug('new audio context', this.$store.state.audioCtx, audioCtx)
        this.$store.commit('setAudioCtx', audioCtx)
      }
    },
    
    /**
     * keydown callback to pause the game.
     * always available
     */
    onKeyDown: function (event) {
      if (this.loadedElements != this.loadingElements) return
      if (!this.userAction) this.initAudioContext()
      const kc = event.code
      if (kc == 'KeyP') {
        this.toggling = true
        setTimeout(() => {
          this.$store.commit('togglePause')
          this.toggling = false
        }, 400)
      }
    },
  },
}
</script>

<style src="@/assets/css/main.css"></style>

<style scoped>
#app {
  height: 100vh;
  overflow: hidden;
  background-color: #000;
  --screen-width: 100vw;
  --screen-height: 100vh;
}

#app.arcade {
  --screen-width: calc(5 / 4 * 95vh);
  --screen-height: 95vh;
}

#app-loader {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  align-items: center;
}
#app-loader p {
  color: #fff;
  font-size: 16px;
  font-family: 'Diary of a 8-bit mage', 'Consolas', 'courrier new';
}
#app-loader .bar {
  width: 250px;
  height: 20x;
  border: 2px solid #fff;
  padding: 2px;
  margin-bottom: 1em;
}
#app-loader .bar > div {
  background-color: #fff;
  height: 16px;
}

@keyframes slidein {
  from {
    top: 100%;
  }

  to {
    top: 0%;
  }
}
@keyframes slideout {
  from {
    top: 0%;
  }

  to {
    top: -100%;
  }
}


/* BIOME / CANVAS */
#biome,
#menu {
  width: var(--screen-width);
  height: var(--screen-height);
  overflow: hidden;
  opacity: 1;
  transition: opacity 1s linear;
  position: absolute;
  animation: 0.5s slidein linear;
  animation-fill-mode: forwards;
  left: 50%;
  transform: translate(-50%, calc(50vh - var(--screen-height) / 2));
}

.toggling #biome,
.toggling #menu {
  animation: 0.5s slideout linear;
  animation-fill-mode: forwards;
}

#biome.hidden {
  opacity: 0;
}

#biome .band {
  position: absolute;
  width: var(--screen-width);
  height: var(--screen-height);
  border-top: 0 solid black;
  border-bottom: 0 solid black;
  box-sizing: border-box;
  transition: all 1s linear;
}

#biome.animating .band {
  border-top: 12vh solid black;
  border-bottom: 12vh solid black;
}

#biome canvas {
  width: 100vw;
  height: 100vh;
}
</style>
