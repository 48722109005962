import { render, staticRenderFns } from "./Biome2.vue?vue&type=template&id=5aa3f0b5&scoped=true&"
import script from "./Biome2.vue?vue&type=script&lang=js&"
export * from "./Biome2.vue?vue&type=script&lang=js&"
import style0 from "./Biome2.vue?vue&type=style&index=0&id=5aa3f0b5&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../../usr/local/share/envs/nodeenv-sphinx/lib/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5aa3f0b5",
  null
  
)

export default component.exports