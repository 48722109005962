export default {
  // defines gameplay states
  BIOME_STATES: [
    'INTRO',
    'WALKING',
    'FILLING_CONTAINER',
    'OUTRO',
  ],
  

  // animation durations
  INTRO_DURATION: 5,  // s
  CINEMATIC0_DURATION: 1000, // ms
  CINEMATIC1_DURATION: 3000,
  CINEMATIC2_DURATION: 3000,
  CINEMATIC3_DURATION: 3000,
  CINEMATIC_END_DURATION: 3000,

  // sound equalizer
  AMBIANT_VOLUME: .5,
  VOICE_VOLUME: -10, // This is decibels /!\
  WATERFALL_VOLUME: 1,
  EARTHQUAKE_VOLUME: 1,
  
  // container/waterfall animation states
  WC_ANIMATION_STATES: [
    'WAITING',
    'MOVING_CAMERA',
    'ANIMATING_OBJECTS',
    'OBJECTS_DONE',
    'DIVING',
    'OVER'
  ],
  
  // player
  MOVE_AXIAL_SPEEDFACTOR: 0.5,
  MOVE_GYRO_SPEEDFACTOR: 0.025,
  EYE_LEVEL: 2.5,

  // cave
  FLOOR_WIDTH: 250,
  FLOOR_HEIGHT: 250,

  // siflinx
  GLOW_RADIUS: 4,
  GLOW_VARIATION: 0.05,

  // interactivity
  INTERACTION_DISTANCE: 8,
  
  // containers
  CONTAINER_HEIGHT: 6,
  HINT_EMISSIVITY: 0.1,
  
  // waterfalls
  NB_DROPLETS: 300,
  //PITCH_VARIATION: 50, /* ± cents */

  // bumps
  NB_ROCKS: 20,

}
