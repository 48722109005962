import * as THREE from 'three'

import dropletsVertexShader from '@/shaders/biome2/droplets-vx.glsl'

import constants from '@/components/biomes/biome2/constants.js'


export const waterfallMixin = {
  data: function() {
    return {
      /* waterfall */
      waterfalls: new THREE.Group(),
      waterfallMaterial: {},
      activeWaterfallMaterial: {},
      nearestWaterfall: null,
    }
  },
  methods: {
    createLadder: function () {
      const ladder = this.assets.ladder.clone()
      ladder.name = 'ladder'
      ladder.geometry.computeBoundingBox()
      ladder.position.y =
        -(ladder.geometry.boundingBox.max.y - ladder.geometry.boundingBox.min.y) - 1
      
      const mat = new THREE.MeshStandardMaterial({
        color: 0x777777,
      })
      ladder.material = mat
      return ladder
    },

    // animateLadders: function() {
    //   for (const waterfall of this.waterfalls) {
    //     if (waterfall.ladder.mesh.material.userData.uniforms) {
    //       waterfall.ladder.mesh.material.userData.uniforms.temps.value =
    //         this.now * 0.001
    //     }
    //   }
    // },

    createWaterfallsMaterial: function () {
      const mat = new THREE.MeshStandardMaterial({
        color: 0xffffff,
        metalness: 1.0,
        roughness: 0.0,
      })
      this.waterfallMaterial = mat.clone()
      this.waterfallMaterial.onBeforeCompile = (shader) => {
        shader.vertexShader = dropletsVertexShader
        shader.uniforms.time = { value: 0.0 }
        this.waterfallMaterial.userData.shader = shader
        this.waterfallMaterial.userData.uniforms = shader.uniforms
      }
    },

    createWaterfall: function (dropletGeo, mat, pos) {
      const waterfall = new THREE.Group()
      waterfall.position.set(pos.x, 0, pos.z)
      waterfall.sourcePointHeight = pos.y

      // flow
      waterfall.flow = new THREE.InstancedMesh(dropletGeo, mat, constants.NB_DROPLETS)
      waterfall.flow.name = "flow"
      waterfall.flow.instanceMatrix.setUsage(THREE.DynamicDrawUsage)
      waterfall.flow.instanceMatrix.needsUpdate = true
      waterfall.add(waterfall.flow)

      // droplets
      const dummy = new THREE.Object3D()
      const diff = new THREE.Vector3(1, pos.y, 1)
      const offset = new THREE.Vector3(0, pos.y, 0)
      for (let i = 0; i < constants.NB_DROPLETS; i++) {
        let rand = Math.random()
        const rv = new THREE.Vector3()
              .random()
              .normalize()
              .subScalar(0.5)
              .multiply(diff)
              .add(offset)
        dummy.scale.set(
          0.1 + 0.2 * rand,
          0.1 + 0.2 * rand,
          0.1 + 0.2 * rand
        )
        dummy.position.copy(rv)
        dummy.updateMatrix()
        waterfall.flow.setMatrixAt(i, dummy.matrix)
      }

      /* ladder */
      waterfall.add(this.createLadder())
      return waterfall
    },

    /**
     * Waterfalls creation and initialisation
     */
    createWaterfalls: function () {
      this.createWaterfallsMaterial()
      const dropletGeo = this.assets.droplet.geometry
      this.assets.sources.scene.children.forEach((source) => {
        const waterfall = this.createWaterfall(dropletGeo, this.waterfallMaterial, source.position)
        this.waterfalls.add(waterfall)
      })
      this.scene.add(this.waterfalls)
    },

    updateWaterfalls: function () {
      if (this.waterfallMaterial.userData.uniforms) {
        this.waterfallMaterial.userData.uniforms.time.value = this.now * 0.001
      }
    },

    /**
     * 1. finds the closest flowing waterfall to attach the positional audio
     * 2. memorises this waterfall if it s in sight and close enough to drop a 
     * container under the flow.
     *
     */    
    findNearestWaterfall: function () {
      const sp = this.player.position.clone().setY(0)
      let nearestWaterfall = null
      let nearestDistance = 60
      this.nearestWaterfall = null
      
      // 1
      for (const waterfall of this.waterfalls.children) {
        if (!waterfall.getObjectByName("container")) {
          const distance = waterfall.position
                .clone()
                .setY(0)
                .distanceTo(sp)
          if (distance < nearestDistance) {
            nearestDistance = distance
            nearestWaterfall = waterfall
            nearestWaterfall.add(this.waterfallSound)
          }           
        }
      }
      // 2
      if (nearestDistance < constants.INTERACTION_DISTANCE && this.isInSight(nearestWaterfall)) {
        this.nearestWaterfall = nearestWaterfall
      }
      return nearestWaterfall
    },

    /**
     * starts animation when an container is put under a waterfall
     */
    startWCWaterfallAnimation: function() {
      const waterfall = this.nearestWaterfall
      const flow = waterfall.getObjectByName("flow")
      const container = waterfall.getObjectByName("container")
      const ladder = waterfall.getObjectByName("ladder")
      ladder.position.y += 1 // was moved above the ground

      waterfall.startY = waterfall.position.y
      waterfall.endY = waterfall.startY + constants.CONTAINER_HEIGHT + Math.random() * constants.CONTAINER_HEIGHT
 
      const mat = flow.material.clone()
      mat.onBeforeCompile = (shader) => {
        shader.vertexShader = dropletsVertexShader
        shader.uniforms.time = { value: 0.0 }
        shader.uniforms.containerHeight = { value: container.height }
        shader.uniforms.stopFlow = { value: true }
        mat.userData.uniforms = shader.uniforms
      }
      flow.material = mat
    },

    updateWCWaterfallAnimation: function(animT) {
      const waterfall = this.nearestWaterfall
      const flow = this.nearestWaterfall.getObjectByName("flow")
      const container = this.nearestWaterfall.getObjectByName("container")

      waterfall.position.setY(THREE.MathUtils.lerp(waterfall.startY, waterfall.endY, animT))
      // makes the flow a bit faster to compensate the y position variation
      flow.material.userData.uniforms.time.value = this.now * 0.0018

      // dry up the flow by decreasing the InstancedMesh.count (elements to display)
      flow.count = Math.floor(THREE.MathUtils.lerp(constants.NB_DROPLETS, 0, animT))
    },
  }
}
