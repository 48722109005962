export default {
  // defines gameplay states
  BIOME_STATES: [
    'INTRO',
    'PLAYING',
    'TALKING',
    'BRIDGE_BUILDING',
    'OUTRO'
  ],

  BOUNDS: [-3500, 2900, 750, 2500],

  // sound equalizer
  AMBIANT_VOLUME: .5,
  VOICE_VOLUME: 1,

  INTRO_KEYFRAMES: [0, 5],
  INTRO_TOTAL_DURATION: 5,
  
  MOVE_SPEED: 20,
  ZOOM_SPEED: 10,

  AVATAR_INITS : [
    {'scale': 21.13883, 'rotation': [Math.PI / 2, 0, -Math.PI / 2]},
    {'scale': 0.136674112, 'rotation': [Math.PI / 2, 0, -Math.PI / 2]},
  ],

  CHARACTERS: ['Kuato', 'Zebulon', 'Itchy', 'Unko', 'Eldritch', 'MobyDick', 'Luggage'],
  
  INTERACTION_DISTANCE: 300,

  CLOUD_START_THRESHOLD: 0.3,
  CLOUD_END_THRESHOLD: 0.7,
  BRICK_START_OPACITY: -0.05,
  BRICK_END_OPACITY: 1.0,
  BRICK_START_POSITION: 1,
  BRICK_END_POSITION: 0.6,

  TALK_ANIMATION_STATES: [
    'WAITING',
    'MOVING_CAMERA',
    'MATERIALIZING_BRICK',
    'BRICK_DONE',
    'OVER'
  ],
  TALK_ANIMATION_DURATIONS: [
    0,
    1500,
    6000,
    1500,
    0
  ],
}
