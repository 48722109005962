<template>
  <div>
    <h1>
      <img
        src="@/assets/images/en/credits.png"
        class="image-title"
      />
    </h1>
    <div class="text">
      <div class="scrollable">
        <p>
          Direction &mdash; Io Burgard<br/>
          Production &mdash; billie_ness<br/>
          <br/>
          Video game development &mdash; Out of Pluto<br/>
          Arcade cabinet and game controller &mdash; Out of Pluto<br />
          <br/>
          3D modeling &mdash; Anaïs Goupy, Lauren Sanchez<br/>
          Music &mdash; Pierre Lucas
        </p>    
        <p>
          This project was selected and supported by the Fondation des artistes' patronage committee.<br>
          With the support of the CNC (Centre national du cinéma et de l'image animée) and SCAM. 
        </p>
        <p>
          The game controller was produced with the support of the CAC (Centre d'Art Contemporain) La Traverse, Alfortville.
        </p>
        <br/>
        <p>
          Special thanks to
          <br/>
          Programme Orange Rouge
          <br/>
          Eva Vlasamitzi
          <br/>
          Pupils of the class ULIS of Beau soleil high school at Chelles:
          <br/>
          AIT IDIR Shana
          <br/>
          CALDEIRA Alex
          <br/>
          DIAKHITE Khassa
          <br/>
          DOVGAL Nikita EDDAM Nawel
          <br/>
          GUILLEMOT Océane
          <br/>
          LOUIS Romuald
          <br/>
          GHARSALLAH Chahine
          <br/>
          MIELLE Lucas
          <br/>
          POMMARAIS Bruce
          <br/>
          RAVINDRARAJAH Talisha
          <br/>
          OGBONI Enzo
          <br/>
          RODRIGUES-JAMAULT Chloé
          <br/>
          SIMOES Angelo
          <br/>
          And their teacher Charlotte Veglia
          <br/><br/>
          Iommy Sanchez
          <br/>
          Anthony Baillard
          <br/>
          Jonathan Cotant
          <br/>
          Pauline Ohanna
          <br/>
          Gil Gharbi
          <br/>
          Laureline Deloingce
          <br/>
          Roch Burgard
        </p>
        <div class="logos">
          <div>
            <img src="@/assets/images/logos/billieness.png" alt="billie_ness" class="logo">
          </div>
          <div>
            <img src="@/assets/images/logos/outofpluto.png" alt="Out of Pluto" class="logo">
          </div>
        </div>
        <div class="logos">
          <div>
            <img src="@/assets/images/logos/cnc.png" alt="CNC" class="logo">
          </div>
          <div>
            <img src="@/assets/images/logos/scam.png" alt="SCAM" class="logo">
          </div>
          <div>
            <img src="@/assets/images/logos/fondation-des-artistes.png" alt="Fondation des artistes" class="logo">
          </div>
          <div>
            <img src="@/assets/images/logos/latraverse.png" alt="CAC La Traverse" class="logo">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CreditsEn'
}
</script>

<style scoped src="@/assets/css/menus.css" />
