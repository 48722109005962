import * as THREE from 'three'
import * as Tone from 'tone'
import { Midi } from '@tonejs/midi'
import { PositionalAudioHelper } from 'three/examples/jsm/helpers/PositionalAudioHelper'

import constants from '@/components/biomes/biome3/constants.js'

export const audioMixin = {
  data: function() {
    return {
      listener: null,
      ambiantMusic: null,
    }
  },
  methods: {
    /**
     * Audio initialisation:
     * - get, start, or resume audio context
     * - set Tone and audio listener to follow player
     * - init ambiant music
     */
    initAudio: function() {
      if (!this.audioCtx || this.audioCtx.state == 'closed') {
        console.debug('no available audiocontext, starting one')
        const audioCtx = new AudioContext()
        this.$store.commit('setAudioCtx', audioCtx)
      }
      if (this.audioCtx.state == 'suspended') {
        console.debug('Resuming available audioctx')
        this.audioCtx.resume()
      }
      THREE.AudioContext.setContext(this.audioCtx)

      this.listener = new THREE.AudioListener()

      /* ambiance music init */
      this.initAmbiantMusic()
    },
    
    /**
     * ambiant music based on 1 component:
     * - wav/mp3 file of the score
     */
    initAmbiantMusic: async function() {
      /* score */
      this.ambiantMusic = new THREE.Audio(this.listener)
      this.ambiantMusic.setBuffer(this.assets.ambiantMusicBuffer)
      this.ambiantMusic.setLoop(true)
      this.ambiantMusic.setVolume(constants.AMBIANT_VOLUME)
      this.ambiantMusic.play()
    },
  }
}
