import assets from './assets'

import BrickModel from '@/assets/biomes/biome3/3D/brick.glb'
import AnimatedCharacterModels from '@/assets/biomes/biome3/3D/characters.glb'
import DoorModel from '@/assets/biomes/biome3/3D/door.glb'
import LadderModels from '@/assets/biomes/biome3/3D/ladders.glb'
import PathModels from '@/assets/biomes/biome3/3D/paths.glb'
import AnimatedPlayerModels from '@/assets/biomes/biome3/3D/players.glb'

import exrFile from '@/assets/biomes/biome3/3D/envmap.exr'
import background from '@/assets/biomes/biome3/3D/background.jpg'
import inventory from '@/assets/biomes/biome3/3D/inventory.jpg'

import ambiantMusic from '@/assets/biomes/biome3/audio/jazz-120bpm.mp3'
import Voice1 from '@/assets/biomes/biome3/audio/voice1.mp3'
import Voice2 from '@/assets/biomes/biome3/audio/voice2.mp3'
import Voice3 from '@/assets/biomes/biome3/audio/voice3.mp3'
import Voice4 from '@/assets/biomes/biome3/audio/voice4.mp3'
import Voice5 from '@/assets/biomes/biome3/audio/voice5.mp3'
import Voice6 from '@/assets/biomes/biome3/audio/voice6.mp3'
import Voice7 from '@/assets/biomes/biome3/audio/voice7.mp3'

export default {
  namespaced: true,
  state: {
    ...assets.state,
    name: 'biome3',

    background: null,
    inventory: null,

    env: null,

    brick: null,
    characters: null,
    animations: null,
    door: null,
    ladders: null,
    paths: null,
    players: null,
    voices: [],
    ambiantMusicBuffer: null,

  },
  mutations: {
    ...assets.mutations,
    setVoice: function(state, val) {
      state.voices.push(val)
    },
    setCharactersAndAnimations: function(state, val) {
      state.characters = val.scene.children.slice(7)
      state.animations = val.animations
    },
  },
  actions: {
    ...assets.actions,
    /* BIOME 3 ASSETS PRELOADING */
    preload: async function({dispatch, state}) {
      return Promise.all([
        dispatch('preloadAsset', [state.TL, background, 'background']),
        dispatch('preloadAsset', [state.TL, inventory, 'inventory']),

        dispatch('preloadAsset', [state.EL, exrFile, 'env']),
        dispatch('preloadAsset', [state.GL, BrickModel, 'brick']),
        dispatch('preloadAsset', [state.GL, AnimatedCharacterModels, 'characters', 'setCharactersAndAnimations']),
        dispatch('preloadAsset', [state.GL, DoorModel, 'door']),
        dispatch('preloadAsset', [state.GL, LadderModels, 'ladders']),
        dispatch('preloadAsset', [state.GL, PathModels, 'paths']),
        dispatch('preloadAsset', [state.GL, AnimatedPlayerModels, 'players']),

        dispatch('preloadAsset', [state.AL, Voice1, 'voices', 'setVoice']),
        dispatch('preloadAsset', [state.AL, Voice2, 'voices', 'setVoice']),
        dispatch('preloadAsset', [state.AL, Voice3, 'voices', 'setVoice']),
        dispatch('preloadAsset', [state.AL, Voice4, 'voices', 'setVoice']),
        dispatch('preloadAsset', [state.AL, Voice5, 'voices', 'setVoice']),
        dispatch('preloadAsset', [state.AL, Voice6, 'voices', 'setVoice']),
        dispatch('preloadAsset', [state.AL, Voice7, 'voices', 'setVoice']),
        dispatch(
          'preloadAsset', [
            state.AL, ambiantMusic, 'ambiantMusicBuffer'
          ]),
      ])
    }
  },
}
