import Vue from 'vue'
import Vuex from 'vuex'

import biome1 from './modules/biome1'
import biome2 from './modules/biome2'
import biome3 from './modules/biome3'
import biome4 from './modules/biome4'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loadingElements: 0,
    loadedElements: 0,
    /**
     * language for menus and texts. 'en' or 'fr'
     */
    lang: 'fr',
    pause: false,
    /**
     * current level
     * 0: currently no playing
     * 1: tender sound
     * 2: fountains
     * 3: wandering paths
     * 4: desert
     */
    currentLevel: 0,
    currentMenu: 'Home',
    /* biome visual effects */
    animating: false,
    hidden: true,
    
    /* all biomes */
    audioCtx: null,
  },
  mutations: {
    /* language selector */
    setLang: function (state, val) {
      state.lang = val
    },
    /* level progression */
    setLevel: function (state, val) {
      state.currentLevel = val
    },
    /* change pause status */
    togglePause: function (state) {
      if (state.currentLevel != 0) state.pause = !state.pause
    },
    /* display status for the biome container */
    setAnimating: function (state, val) {
      state.animating = val
    },
    setHidden: function (state, val) {
      state.hidden = val
    },

    /* go to a home page or sub menu */
    setMenu: function (state, val) {
      state.currentMenu = val
    },
    /* reset game and go to home */
    restart: function (state) {
      state.currentLevel = 0
      state.pause = false
      state.currentMenu = 'Home'
    },
    /* audio */
    setAudioCtx: function (state, val) {
      state.audioCtx = val
    },
    
    /* for preloader state */
    setLoading: function (state) {
      state.loadingElements += 1
    },
    setLoaded: function (state) {
      state.loadedElements += 1
    },
    
  },
  modules: {
    biome1, biome2, biome3, biome4
  },
})
