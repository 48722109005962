export default {
// defines gameplay states
  BIOME_STATES: [
    'INTRO',
    'PLAY_INTRO_SOUND',
    'LISTENING',
    'RECORDING',
    'OUTRO',
  ],

  INTRO_DURATION: 5000,
  LISTENING_DURATION: 3000,
  RECORDING_DURATION: 4000,
  FULL_DURATION: 7000, // sum of LISTEN and RECORD
  DECREASE_DURATION: 3000,

  // defines camera default
  CAM_RADIUS: 92, //81 //28 //50
  CAM_H_ANGLE_DEG: 10, // 20 //12

/* defines scene and ocean constants */
  BOUNDS: 500,
  OCEAN_HEIGHT: 150,
  OCEAN_WIDTH: 150,
  OCEAN_WIDTH_SUBDIV: 196,
  
  /* defines sound and micro settings */
  /* default mic volume threshold (before calibration) */
  DEFAULT_MIC_VOL_THRESHOLD: 7,
  MIC_FFT_SIZE: 64,
  INTRO_FFT_SIZE: 64,
}
