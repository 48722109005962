/**
 * builds the paths
 * that is non interactive elements 
 */
import * as THREE from 'three'

export const pathMixin = {
  data: function() {
    return {
      paths: new THREE.Group(),
      ladders: new THREE.Group(),
    } 
  },
  methods: {
    /**
     * creates the wander paths, including ladders
     */
    createPaths: async function() {
      /* wander paths */
      let path;
      this.paths = new THREE.Group()

      this.assets.paths.scene.children.forEach((c) => {
        path = c.clone()
        if (path.name == "Chemin4") {
          path.material = new THREE.MeshLambertMaterial({
            color: 0x000000,
            combine: THREE.MixOperation,
            envMap: this.scene.environment,
            reflectivity: .9
          })
        }
        this.paths.add(path)

      })
      /* adds all to scene */
      this.scene.add(this.paths)

      /* ladders */
      let ladder;
      this.ladders = new THREE.Group()
      this.assets.ladders.scene.children.forEach((c) => {
        ladder = c.clone()
        ladder.material = ladder.material.clone()
        //ladder.material.envMap = this.environmentRenderTarget.texture
        this.ladders.add(ladder)
      })
      /* adds all to scene */
      this.scene.add(this.ladders)
    },
  }
}
