<template>
  <div>
    <div :class="['ee', 'ee-'+easter]">{{ 5 - easter }}</div>
    <div
      v-if="debug"
      v-show="showControls"
      id="button-next-level"
      @click="$store.commit('setLevel', 1)"
    >
      RESTART
    </div>
    <div
      v-if="debug"
      v-show="showControls"
      id="stats-container"
    />
    <canvas />
  </div>
</template>

<script>
/* VUE imports */
import { mapState } from 'vuex'

/* THREEJS */
import * as THREE from 'three'

/* SPHINX */
import { pauseMixin } from '@/components/biomes/pause.js'

import constants from '@/components/biomes/biome4/constants.js'

import { audioMixin } from '@/components/biomes/biome4/audio.js'
import { cameraAnimationMixin } from '@/components/biomes/biome4/camanim.js'
import { desertMixin } from '@/components/biomes/biome4/desert.js'
import { keyboardMixin } from '@/components/biomes/biome4/keyboard.js'
import { objectMixin } from '@/components/biomes/biome4/objects.js'
import { playerMixin } from '@/components/biomes/biome4/player.js'
import { sceneMixin } from '@/components/biomes/biome4/scene.js'
import { sphinxMixin } from '@/components/biomes/biome4/sphinx.js'

export default {
  name: 'Biome4',
  components: {},
  mixins: [
    audioMixin,
    cameraAnimationMixin,
    desertMixin,
    keyboardMixin,
    objectMixin,
    pauseMixin,
    playerMixin,
    sceneMixin,
    sphinxMixin,
  ],
  props: {
    godMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: function () {
    return {
      /* Cameras */
      /* ab: could probably get rid of it */
      mainCamera: {},
      /* animation */
      animationRequestId: null,
      // 'clock' and 'dt' are to update player.mixer
      // 'now' is to compute the end times of animations
      // could they be merged?
      clock: new THREE.Clock(),
      dt: 0,
      now: 0,
      biomeState: 'INTRO',
      isReady: false,
      animationState: '',
      mixers: [],
      // game
      ghettoInPlace: false,
      intervalId: null,
      /* Develop/Experiment controls */
      showReloadButton: false,
      showControls: false,
      controls: {},
      stats: null,
    }
  },
  computed: {
    debug: () => process.env.NODE_ENV == 'development',
    ...mapState({
      audioCtx: (state) => state.audioCtx,
      assets: (state) => {
        return {
          ...state.biome4,
          'players': state.biome3.players,
          'tracks': [
            ...state.biome3.voices,
            ...state.biome4.musics,
          ],
        }
      },
      animating: (state) => state.animating,
    }),
  },
  mounted: function () {
    console.debug('BIOME 4 MOUNTED')
    
    this.initScene()
    this.createDesert()
    this.createSphinx()
    this.createObjects()
    this.createPlayer()
    this.setEnvironmentalMap()
    this.initCollision()

    this.initAudio()

    /* event handlers */
    this.onResize()
    window.addEventListener('resize', this.onResize)
    window.addEventListener('pointermove', this.onPointerMove)
    window.addEventListener('keypress', this.onKeyPress)
    window.addEventListener('keydown', this.onKeyDown)
    window.addEventListener('keyup', this.onKeyUp)
    window.addEventListener('click', this.onMouseClick)
    
    this.animate()
  },
  created: function () {
    console.debug('BIOME 4 CREATED')
  },
  beforeDestroy: function () {
    console.debug('BIOME 4 DESTROY')
    cancelAnimationFrame(this.animationRequestId)
    this.audioCleanUp()
    this.freeScene()
    this.renderer.dispose()
  },
  methods: {
    /**
     * Animation frame handler
     */
    animate: function() {
      this.animationRequestId = requestAnimationFrame(this.animate)
      if (this.pause) return null
      
      this.now = performance.now()
      this.dt = this.clock.getDelta()
      if (this.biomeState == 'INTRO') {
        this.$store.commit('setAnimating', true)
        if (!this.isReady) {
          this.isReady = true
          this.$store.commit('setHidden', false)
          const mixer = this.startCameraIntroAnimation()
          mixer.addEventListener('finished', (event) => {
            this.$store.commit('setAnimating', false)
            this.biomeState = 'PLAYING'
            this.ghetto.position = this.getElevation(this.ghetto.position)
            this.ghetto.position.setY(this.ghetto.position.y + 0.004)
          })
        }
        this.updateCameraIntroAnimation()
      } else if (this.biomeState == 'PLAYING') {
        this.updatePlayer()
        this.updateGhettoBlasterVolume()
        this.updateWater()
        this.findNearestInteractiveObject()
        if (this.ghettoInPlace) {
          this.biomeState = 'OUTRO'
          this.$store.commit('setAnimating', true)
          this.startSphinxAnimation()
          this.startCameraOutroAnimation()
          this.startPlayerExitAnimation()
        }
      } else if (this.biomeState == 'OUTRO') {
        this.updatePlayerExitAnimation()
        this.updateSphinxAnimation()
        this.updateCameraOutroAnimation()
        this.updateWater()
        this.updatePlantAnimation()
      } else if (this.biomeState == 'EASTER') {
        this.updatePlayer()
        this.updateWater()
      }
      
      this.render()

      if (this.stats) this.stats.update()
    },

    /**
     * Starts rotation between screens.
     */
    countdownQuit: function () {
      if (this.intervalId) {
        clearInterval(this.intervalId)
      }
      this.intervalId = setInterval(() => {
        clearInterval(this.intervalId)
        this.$store.commit('setAnimating', false)
        this.$store.commit('setLevel', 0)
      }, constants.QUIT_TIME)
    },

    onResize: function() {
      // console.debug("resize !", this.sceneContainer.clientWidth, this.sceneContainer.clientHeight)
      this.renderWidth = this.sceneContainer.clientWidth
      this.renderHeight = this.sceneContainer.clientHeight
      this.mainCamera.aspect = this.renderWidth / this.renderHeight
      this.mainCamera.updateProjectionMatrix()
      this.renderer.setPixelRatio(window.devicePixelRatio)
      this.renderer.setSize(this.renderWidth, this.renderHeight)
    },
  }
}
</script>

<style scoped>
.button {
  border: 1px solid #fff;
}

.button:hover {
  box-shadow: 2px 2px 2px #fff;
}
/* TEMPORARY */
#button-next-level {
  position: absolute;
  z-index: 2;
  bottom: 0;
  right: 0;
  padding: 5px;
  cursor: pointer;
}
.fadeout {
  opacity: 0;
}
/* EASTER */
@-webkit-keyframes blink {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}
@-webkit-keyframes reblink {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}
.ee {
  position: absolute;
  opacity: 0;
  z-index: 3000;
  width: 100%;
  color: #FFF;
  text-align: center;
}
.ee-1, .ee-3 {
  animation: 1s blink;
}
.ee-2, .ee-4 {
  animation: 1s reblink;
}
</style>
