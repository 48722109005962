import { render, staticRenderFns } from "./Biome3.vue?vue&type=template&id=6d3d8db4&scoped=true&"
import script from "./Biome3.vue?vue&type=script&lang=js&"
export * from "./Biome3.vue?vue&type=script&lang=js&"
import style0 from "./Biome3.vue?vue&type=style&index=0&id=6d3d8db4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../../usr/local/share/envs/nodeenv-sphinx/lib/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d3d8db4",
  null
  
)

export default component.exports