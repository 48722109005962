<template>
  <div>
    <div id="button-next-level" @click="$store.commit('setLevel', 3)">
      SUIVANT
    </div>
    <div v-show="showControls" id="stats-container" />
    <canvas id="threed" />
    <canvas id="capt" />
  </div>
</template>

<script>
/* VUE imports */
import { mapState } from 'vuex'
/* THREEJS */
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import * as THREE from 'three'
import Stats from 'three/examples/jsm/libs/stats.module'
import { OrbitControls } from 'three/addons/controls/OrbitControls.js'
import { ImprovedNoise } from 'three/addons/math/ImprovedNoise.js'

/* SPHINX */
import { pauseMixin } from'@/components/biomes/pause.js'

export default {
  name: 'testScene0',
  components: {},
  props: {},
  mixins: [ pauseMixin ],
  data: function () {
    return {
      environmentRenderTarget: {},
      /* Canvas DOM */
      GL: new GLTFLoader(),
      sceneContainer: {},
      canvas: {},
      /* Main renderer */
      renderer: {},
      animationRequestId: null,
      /* Main scene */
      axesHelper: new THREE.AxesHelper(5),
      scene: {},
      /* Cameras */
      mainCamera: {},
      /* Develop/Experiment controls */
      controls: {},
      stats: null,
      showControls: false,
      now: 0,
      dt: 0.01,
      showReloadButton: false,
      object: null,
      mesh: null,
      clock: null,
      mixers: [],
      animations: null,
      skeletonHelper: null,
    }
  },
  computed: {
    ...mapState({
      audioCtx: (state) => state.audioCtx,
    }),
  },
  mounted: async function () {
    console.log('TEST COMPOSANT MOUNTED')
    this.initScene()
    requestAnimationFrame(this.animate)
  },
  created: function () {
    console.log('TEST COMPONENT CREATED')
    // if (process.env.NODE_ENV == 'development') {
    //   this.showControls = true
    // }
  },
  beforeDestroy: function () {
    console.log('TEST COMPONENT DESTROY')
    cancelAnimationFrame(this.animationRequestId)
    // this.audioCleanUp()
    this.renderer.dispose()
  },
  methods: {
    initBones: function() {
      // this display a fucked up character
      this.animations = this.$store.state.biome3.players.animations
      let object = this.$store.state.biome3.players.scene.children[0]
      let mesh = object.children[1]
      let skeleton = mesh.skeleton
      let bones = mesh.skeleton.bones
      this.mesh = mesh
      // this.mesh.add(bones[0])
      // this.mesh.bind(skeleton.clone())
      
      this.skeletonHelper = new THREE.SkeletonHelper(this.mesh)
      this.skeletonHelper.material.linewidth = 2
      this.scene.add(this.skeletonHelper)


      this.object = object
      //this.object.scale.multiplyScalar(.001);
      this.object.position.set(0, 0, 0)
      //this.scene.add(this.mesh)
      this.scene.add(this.object)

      let animation = this.animations[0]
      this.mixer = new THREE.AnimationMixer(this.object);
      let action = this.mixer.clipAction(animation)
      action.play()

      // this.animations = this.$store.state.biome3.animations.animations
      // let object = this.$store.state.biome3.players.scene.children[0]
      // // let mesh = object.children[1].clone()
      // // let skeleton = mesh.skeleton
      // // let bones = mesh.skeleton.bones
      // // this.mesh = mesh
      // // this.mesh.add(bones[0])
      // // this.mesh.bind(skeleton.clone())
      
      // // this.skeletonHelper = new THREE.SkeletonHelper(this.mesh)
      // // this.skeletonHelper.material.linewidth = 2
      // // this.scene.add(this.skeletonHelper)

      // let animation = this.animations[0]
      // console.log(animation.uuid)
      // this.mixer = new THREE.AnimationMixer(object.children[1])
      // let action = this.mixer.clipAction(animation)
      // action.play()

      // // this.mesh.scale.multiplyScalar(.001);
      // object.position.set(0, 0, 0)
      // //object.scale.multiplyScalar(.001)
      // console.log(object)
      // this.scene.add(object)
    },

    initBrick: function() {
      let brick = Object.create(this.$store.state.biome3.brick.scene.children[0])
      console.log(brick)
      brick.position.set(0, 0, 0)
      brick.rotation.y = 0
      brick.rotation.x = 0
      brick.rotation.z = Math.PI / 2
      brick.scale.setScalar(.005)
      brick.material = brick.material.clone()
      // brick.material.transparent = true
      // brick.material.opacity = .5
      console.log(brick)
      return brick
    },

    initChars: function() {
      let cnt = 0
      for (let modelChar of this.$store.state.biome3.anims.scene.children.slice(7)) {
        let c = Object.create(modelChar)
        console.log("char", c)
        c.scale.setScalar(10)
        this.scene.add(c)
        for (let child of c.children) {
          child.frustumCulled = false
        }

        let animation = this.$store.state.biome3.anims.animations[cnt]
        let mixer = new THREE.AnimationMixer(c)
        // mixer.timeScale = 1
        c.path = mixer.clipAction(animation)
        c.path.play()
        this.mixers.push(mixer)
        
        let animation2 = this.$store.state.biome3.anims.animations[cnt + 1]
        let mixer2 = new THREE.AnimationMixer(c)
        c.walk = mixer2.clipAction(animation2)
        c.walk.play()
        this.mixers.push(mixer2)
        
        this.mesh = c

        cnt += 2
      }
    },

    initScene: function () {
      /* THREEJS 3D CANVAS AND RENDERER */
      this.$store.commit("setHidden", false)
      this.sceneContainer = document.getElementById('biome')
      this.canvas = document.getElementById('threed')

      /* SCENE initialisation */
      this.scene = new THREE.Scene()

      /* RENDERER */
      this.renderer = new THREE.WebGLRenderer({
        antialias: true,
        canvas: this.canvas,
        alpha: true
      })

      
      /* FPS-meter */
      this.stats = new Stats()
      this.$el.querySelector('#stats-container').append(this.stats.dom)

      this.renderWidth = this.sceneContainer.clientWidth
      this.renderHeight = this.sceneContainer.clientHeight
      this.renderer.setPixelRatio(window.devicePixelRatio)
      this.renderer.setSize(this.renderWidth, this.renderHeight)

      /* axis helper */
      const ax = new THREE.AxesHelper(5)
      this.scene.add(ax)

      this.mainCamera = new THREE.PerspectiveCamera(50, this.renderWidth / this.renderHeight, .1, 5000)
      this.mainCamera.position.set(0, 1, -10)

      // const geo = new THREE.BoxGeometry(2, 1, 0)
      // const mat = new THREE.MeshBasicMaterial({ color: 0xff0000 })
      // const mesh = new THREE.Mesh(geo, mat)
      // mesh.position.set(0, -.6, 9)
      // this.scene.add(mesh)
      // this.mesh = mesh

      const ambiant = new THREE.AmbientLight(0xffffff, 1)
      this.scene.add(ambiant)
      const sun = new THREE.PointLight(0xffffff, 1)
      sun.position.set(10, 10, 10)
      this.scene.add(sun)
      this.clock = new THREE.Clock()
      
      // this.scene.add(this.$store.state.biome2.hands)
      console.log(this.mainCamera)

      // this.scene.add(this.initBrick())
      let elements = this.$store.state.biome4.columns.scene.children
      let animations = this.$store.state.biome4.columns.animations
      for (let element of elements) {
        let tmp = Object.create(element)
        let animation = animations.find(anim => anim.name.startsWith(tmp.name.replace("cell", "cell.")))
        console.log(tmp.name, animation)
        if (animation) {
          let mixer = new THREE.AnimationMixer(tmp)
          let action = mixer.clipAction(animation)
          action.loop = THREE.LoopOnce
          action.play()
          this.mixers.push(mixer)
        }
        this.scene.add(tmp)
      }
      console.log(this.$store.state.biome4.columns)
      
      this.controls = new OrbitControls( this.mainCamera, this.sceneContainer );
    },
    animate: function () {
      this.animationRequestId = requestAnimationFrame(this.animate)
      this.controls.update()
      this.render()
    },
    render: function () {
      //this.mainCamera.lookAt(this.mesh.position)
      const time = Date.now() * 0.001;
      this.dt = this.clock.getDelta()
      for (const mixer of this.mixers) {
        mixer.update(this.dt)
      }
      this.renderer.render(this.scene, this.mainCamera)
    },
  },
}
</script>

<style scoped>
#biome-2-container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

#scene-2-container {
  width: 100vw;
  height: 100vh;
}

#threed {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(90deg, rgba(150,100,31,1) 0%, rgba(56,56,195,1) 46%, rgba(0,212,255,1) 100%);
}

#help-screen {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  user-select: none;
}

.button {
  border: 1px solid #fff;
}

.button:hover {
  box-shadow: 2px 2px 2px #fff;
}
/* TEMPORARY */
#button-next-level {
  position: absolute;
  z-index: 2;
  bottom: 0;
  right: 0;
  padding: 5px;
  cursor: pointer;
}

#capt {
  position: absolute;
  left: 0;
  top: 0;
  border:1px solid black;
  
}
</style>
