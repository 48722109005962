/**
 * builds the interactive elements: dog, ghetto blaster, amplyfing 
 */
import * as THREE from 'three'

import constants from './constants.js'

export const objectMixin = {
  data: function() {
    return {
      dog: {},
      ghetto: {},
      cone: {},
      dropArea: {},
    } 
  },
  methods: {
    /**
     * creates the interactive objects
     * - dog
     * - ghetto blaster
     * - amplifying cone
     */
    createObjects: async function() {
      this.dog = Object.create(
        this.assets.objects.scene.children.find(elem => elem.name == "Chien")
      )
      this.scene.add(this.dog)

      this.cone = Object.create(
        this.assets.objects.scene.children.find(elem => elem.name == "Cone")
      )
      this.scene.add(this.cone)

      this.dropArea = Object.create(
        this.assets.objects.scene.children.find(elem => elem.name == "DropArea")
      )
      this.scene.add(this.dropArea)
      this.ghetto = Object.create(
        this.assets.objects.scene.children.find(elem => elem.name == "Ghetto")
      )
      this.ghetto.scale.setScalar(constants.GHETTO_INIT.scale)
      this.ghetto.position.set(...constants.GHETTO_INIT.position)
      this.scene.add(this.ghetto)
    },

    findNearestInteractiveObject: function () {
      const sp = this.player.position
      this.nearestObject = null
      for (const object of [this.dog, this.dropArea, this.ghetto]) {
        const distance = object.position.clone().distanceTo(sp)
        if (distance < constants.INTERACTION_DISTANCE && this.isInSight(object)) {
          this.nearestObject = object
        }
      }
    },

    interactWithNearestObject: function() {
      if (this.nearestObject) {
        if (this.nearestObject.name == "Chien") {
          this.dogBarkAudio.play()
        }
        else if (this.nearestObject.name == "Ghetto" || this.player.hasGhetto == true) { 
          if (this.ghettoBlasterAudio.isPlaying) {
            this.ghettoBlasterAudio.stop()
          } else {
            this.ghettoBlasterAudio.play()
          }
        }
      }
    }
  }
}
