/**
 * builds the paths
 * that is non interactive elements 
 */
import * as THREE from 'three'

import constants from '@/components/biomes/biome3/constants.js'

export const playerMixin = {
  data: function() {
    return {
      player: new THREE.Group(),
      deltaCamera: new THREE.Vector3(),
      nearestCharacter: null,
    } 
  },
  methods: {
    /**
     * creates the wandering characters
     */
    createPlayer: async function() {
      /* randomly select one of the two avatars */
      const avatarIdx = Math.floor(Math.random() * this.assets.players.scene.children.length)
      this.player = Object.create(this.assets.players.scene.children[avatarIdx])

      this.player.scale.setScalar(constants.AVATAR_INITS[avatarIdx].scale)
      this.player.rotation.setFromVector3(new THREE.Vector3(...constants.AVATAR_INITS[avatarIdx].rotation))

      // init
      this.player.moveDir = new THREE.Vector3(0, 0, 0)
      this.player.runningFactor = 1
      this.player.position.set(-3100, 265, 0)
            
      // set camera
      this.deltaCamera.set(0, 200, 2000)
      this.mainCamera.position.copy(this.player.position).add(this.deltaCamera)
      this.mainCamera.lookAt(this.player.position)

      // set animation
      let animation = this.assets.players.animations.find(
        anim => anim.name.startsWith(this.player.name.split("_")[0])
      )
      let mixer = new THREE.AnimationMixer(this.player)
      this.player.walk = mixer.clipAction(animation)
      this.mixers.push(mixer)

      this.scene.add(this.player)
    },

    updatePlayer: function() {
      if (this.player.moveDir.x == 0) {
        this.player.walk.stop()
      } else {
        const newX = Math.max(
          constants.BOUNDS[0], Math.min(
            (!this.glow) ? constants.BOUNDS[1] : 8000,
            this.player.position.x + this.player.moveDir.x * this.player.runningFactor * constants.MOVE_SPEED
          )
        )
        this.player.rotation.z = -(this.player.moveDir.x / Math.abs(this.player.moveDir.x)) * Math.PI / 2
        this.player.walk.play()
        this.player.position.setX(newX)
        this.mainCamera.position.setX(newX)
        //this.mainCamera.lookAt(this.player.position)
      }
      if (this.player.moveDir.z != 0) {
        const newZ = Math.max(
          constants.BOUNDS[2], Math.min(
            constants.BOUNDS[3],
            this.mainCamera.position.z + this.player.moveDir.z * this.player.runningFactor * constants.ZOOM_SPEED
          )
        )
        this.mainCamera.position.setZ(newZ)
      }
    },

    findNearestCharacter: function () {
      const sp = this.player.position
      this.nearestCharacter = null
      for (const character of this.characters.children) {
        const distance = character.position.clone().distanceTo(sp)
        //console.debug(character.name, playerToCharacter_d)
        if (distance < constants.INTERACTION_DISTANCE) {
          //console.log(character.name, "is near")
          if (!character.voice.isPlaying) character.voice.play()
          if (character.hasBrick)
            this.nearestCharacter = character
        } else if (character.voice.isPlaying) character.voice.stop()
      }
    },
  }
}
