<template>
  <div>
    <h1>
      <img
        src="@/assets/images/en/about.png"
        class="image-title"
      />
    </h1>
    <div class="text">
      <p>
        <i>Sphinx</i> invites you to cross an enigma. The enigma of idea incarnation.                
      </p>
      <p>
        By treating ideas and concepts as materials, we can place them at human level, so that they can be manipulated. The figure of the Sphinx embodies, in the game, the idea that knowledge is to be used like any other matter. In this way, the video game invokes the theory of the Noosphere. Defined by Vladimir Vernadsky and Pierre Teilhard de Chardin as the Sphere of Ideas, the Noosphere encompasses the earth, like the stratosphere or atmosphere, and is embodied in physical terms. It is constantly reconfigured by the transformation we make of it. Ideas seem to descend from this vaporous emanation that is the noosphere, feeding our thoughts. Once digested, they are embodied in words and formulations. This translation modifies the raw material of the initial idea and replenishes the noosphere.
      </p>  
      <p>
        <i>Sphinx</i> was born out of workshops conducted by artist Io Burgard with students in the Ulis class at Collège Beau Soleil in Chelles, Seine-et-Marne, a program supported by Orange Rouge. Committed to the question of encounters, the students and the artist worked on a space that would encourage them. These exchanges led the artist to imagine an interactive work that draws on the codes of video games, as digital space has become a preferred meeting place for teenagers.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutEn'
}
</script>

<style scoped src="@/assets/css/menus.css" />
