import assets from './assets'

import introSound from '@/assets/biomes/biome1/audio/biome1-liminal-sound.wav'
import outroSound from '@/assets/biomes/biome1/audio/biome1-outro-sound.wav'
import defaultSoundBlob from '@/assets/biomes/biome3/audio/voice4.mp3'


export default {
  namespaced: true,
  state: {
    ...assets.state,
    name: 'biome1',
    introSoundBuffer: null,
    outroSoundBuffer: null,
    /* biome1-2 */
    soundBlob: defaultSoundBlob,
  },
  mutations: {
    ...assets.mutations,
    /* Special biome 1 asset setters */
    setSoundBlob: function (state, val) {
      state.soundBlob = val
    },
  },
  actions: {
    ...assets.actions,
    /* BIOME 1 ASSETS PRELOADING 
     * only two audio files
     */
    preload: async function({dispatch, state}) {
      return Promise.all([
        dispatch(
          'preloadAsset', [state.AL, introSound, 'introSoundBuffer']
        ),
        dispatch(
          'preloadAsset', [state.AL, outroSound, 'outroSoundBuffer'],
        ),
      ])
    },
  },
}
