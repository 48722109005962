/**
* defines camera movements:
*/

import * as THREE from 'three'

import constants from '@/components/biomes/biome2/constants.js'

export const cameraAnimationMixin = {
  data: function() {
    return {
      cameraStart: {
        fov: null,
        position: null,
        quaternion: null,
        time: null,
      },
      cameraEnd: {
        fov: null,
        position: null,
        quaternion: null,
        time: null,
      },
      cameraTarget: null
    }
  },
  methods: {
    /**
     * Prepares mixer and vectors for introduction animation:
     * falling into the cavern
     */
    startCameraIntroAnimation: function() {
      const sp = this.player
      // this.cameraStart.position = new THREE.Vector3(68.2, 100, 81.8)
      // this.cameraEnd.position = new THREE.Vector3(68.2, 0, 81.8)
      this.cameraStart.position = new THREE.Vector3().copy(this.player.position).setY(80)
      this.cameraEnd.position = new THREE.Vector3().copy(this.player.position)
      //this.cameraTarget = new THREE.Vector3(0, 0, 0)
      sp.mixer = new THREE.AnimationMixer(sp)
      const spArrivalPositionTrack = new THREE.VectorKeyframeTrack(
        '.position',
        [0, constants.INTRO_DURATION],
        [...this.cameraStart.position, ...this.cameraEnd.position],
        THREE.InterpolateSmooth
      )
      const spArrivalClip = new THREE.AnimationClip('ARRIVAL', constants.INTRO_DURATION, [
        spArrivalPositionTrack,
      ])
      sp.animations = [spArrivalClip]
      const action = sp.mixer.clipAction(spArrivalClip)
      action.loop = THREE.LoopOnce
      action.clampWhenFinished = true

      action.play()
      return sp.mixer
    },

    /**
     * Updates introduction animation
     */
    updateCameraIntroAnimation: function() {
      this.player.mixer.update(this.dt)
      this.player.lookAt(this.caveCenter.clone())
    },

    /**
     * Prepares camera animation when a container is attached to a waterfall:
     * slowly moves camera backwards and upwards
     */
    startCameraWCAnimation: function() {
      const spec = this.player
      
      /* camera animation */
      this.cameraStart.fov = spec.camera.fov
      this.cameraStart.position = spec.position.clone()
      this.cameraStart.quaternion = spec.camera.quaternion.clone()
      this.cameraStart.time = this.now

      this.cameraEnd.fov = 90
      this.cameraEnd.position = new THREE.Vector3()
        .subVectors(this.nearestWaterfall.position, this.cameraStart.position)
        .normalize()
        .multiplyScalar(-15)
        .setY(constants.CONTAINER_HEIGHT)
        .add(this.cameraStart.position)
      this.cameraEnd.time = this.now + constants.CINEMATIC0_DURATION
      
      this.cameraTarget = this.nearestWaterfall.position
        .clone()
        .setY(constants.EYE_LEVEL)

      // /* waterfall modification */
      // spec.cinematicWaterfall = waterfall
      // /* waterfall sound source */
      // spec.cinematicWaterfall.soundSource.originalPosition =
      //   spec.cinematicWaterfall.soundSource.position.clone()
      // spec.cinematicWaterfall.soundSource.position = new THREE.Vector3(0, 0, 0)
      // spec.cinematicWaterfall.remove(spec.cinematicWaterfall.soundSource)
      // spec.camera.add(spec.cinematicWaterfall.soundSource)
      // console.log('SOUND SOURCE', spec.cinematicWaterfall.soundSource)
    },

    /**
     * Updates camera animation when a container is attached to a waterfall.
     * Does not update in case of collision
     */
    updateCameraWCAnimation: function(animT, collision) {
      /* do not move or update animation if colliding with an object 
         that block the view */
      if (collision && this.checkCollision()) return null

      /* camera position */
      const cameraPos = new THREE.Vector3().lerpVectors(
        this.cameraStart.position,
        this.cameraEnd.position,
        animT
      )
      /* camera focal */
      const fov = THREE.MathUtils.lerp(
        this.cameraStart.fov,
        this.cameraEnd.fov,
        animT
      )

      this.player.position.copy(cameraPos)
      this.player.fov = fov
      if (this.cameraTarget) {
        this.player.camera.lookAt(this.cameraTarget)
      } else if (this.cameraEnd.quaternion) {
        this.player.camera.quaternion.copy(this.cameraStart.quaternion).slerp(this.cameraEnd.quaternion, animT)
      }
      this.player.camera.updateProjectionMatrix()
    },
  }
}
