/**
* defines player:
* - moving camera
* - hands (3D objects)
*/

import * as THREE from 'three'

import constants from './constants.js'

export const playerMixin = {
  data: function() {
    return {
      /* player (for location) */
      player: new THREE.Group(),
      nearestObject: null,
      /* collisions */
      collideBoxVectors: [
        new THREE.Vector3(1, 0, 0),
        new THREE.Vector3(-1, 0, 0),
        new THREE.Vector3(0, 0, 1),
        new THREE.Vector3(0, 0, -1),
      ],
      collidableObjects: new THREE.Group(),
      raycaster: {},
      hasGhetto: false,
    }
  },
  methods: {
    /**
     * check if an object is currently in sight
     * the test is quite simple, it computes the angle between 
     * the camera's direction and the vector between the object and the player
     * if this angle is lower than pi/4, we consider the object to be in sight
     */
    isInSight: function(object) {
      const camDirection = new THREE.Vector3()
      let rayToContainer = new THREE.Vector3()
      this.mainCamera.getWorldDirection(camDirection)
      rayToContainer.subVectors(object.position, this.player.position).normalize()
      return rayToContainer.angleTo(camDirection) < Math.PI / 4
    },

    /**
     * Player group creation and attach camera
     */
    createPlayer: function() {
      /* camera */
      const sceneRatio = this.renderWidth / this.renderHeight
      this.player.camera = new THREE.PerspectiveCamera(60, sceneRatio, 0.001, 200)
      this.player.moveDir = new THREE.Vector3(0, 0, 0)
      
      /* creates and animates avatar */
      const avatarIdx = Math.floor(Math.random() * this.assets.players.scene.children.length)
      this.player.avatar = Object.create(this.assets.players.scene.children[avatarIdx])
      this.player.avatar.scale.setScalar(constants.AVATAR_INITS[avatarIdx].scale)
      this.player.avatar.rotation.setFromVector3(new THREE.Vector3(...constants.AVATAR_INITS[avatarIdx].rotation))
      this.player.avatar.position.set(0, 0, 0)
      this.player.add(this.player.avatar)
      this.player.position.setY(-0.012)
      
      // set animation
      let animation = this.assets.players.animations.find(
        anim => anim.name.startsWith(this.player.avatar.name.split("_")[0])
      )
      let mixer = new THREE.AnimationMixer(this.player.avatar)
      this.player.walk = mixer.clipAction(animation)
      this.mixers.push(mixer)

      // set camera
      this.mainCamera = this.player.camera
      this.player.add(this.player.camera)

      this.player.isFree = true
      this.player.hasGhetto = false

      this.scene.add(this.player)
    },

    grabOrDropNearestObject: function() {
      if (this.player.hasGhetto) {
        this.dropGhettoBlaster()
      }
      if (this.nearestObject == null) {
        return null
      } else {
        if (this.nearestObject.name == "Ghetto") {
          this.grabGhettoBlaster()
        } else {
          this.player.grunt.play()
        }
      }
    },
    
    /**
     * grabs the ghetto blaster
     */
    grabGhettoBlaster: function() {
      this.player.hasGhetto = true
      this.player.add(this.ghetto)
      this.ghetto.position.set(0, .012, .005)
      this.ghetto.rotation.set(Math.PI / 2, 0, 0)
      this.collidableObjects.pop()
    },

    /**
     * drops the ghetto blaster. If player is in front
     * of the cone smallest end, the ghetto blaster
     * is positioned correctly to trigger the end of the game
     */
    dropGhettoBlaster: function() {
      this.player.hasGhetto = false
      this.collidableObjects.push(this.ghetto)
      if (this.nearestObject && this.nearestObject.name == "DropArea") {
        const direction = new THREE.Vector3()
        this.player.getWorldDirection(direction)
        let dropPosition = this.dropArea.position.clone()
        dropPosition = this.getElevation(dropPosition)
        dropPosition.setY(dropPosition.y + 0.004)
        this.ghetto.position.copy(dropPosition)
        this.ghetto.rotation.set(Math.PI / 2, 0, Math.PI / 10)
        this.scene.add(this.ghetto)
        this.ghettoInPlace = true
      } else {
        /* simply drops ghetto blaster in front of player */
        const direction = new THREE.Vector3()
        this.player.getWorldDirection(direction)
        let dropPosition = this.player.position.clone()
        dropPosition.add(direction.multiplyScalar(-.025))
        dropPosition = this.getElevation(dropPosition)
        dropPosition.setY(dropPosition.y + 0.004)
        this.ghetto.position.copy(dropPosition)
        this.ghetto.rotation.copy(this.player.rotation)
        this.ghetto.rotateX(Math.PI / 2)
        this.scene.add(this.ghetto)
      }
    },

    /**
     * Player update
     * sets view angle then translates the player group.
     */
    updatePlayer: function() {
      if (!this.player.isFree) return false
      if (this.player.moveDir.length() < 0.01) {
        this.player.walk.stop()
        return false
      }

      const originalP = this.player.position.clone()

      /* view angle update */
      if (this.player.moveDir.x != 0) {
        if (this.player.isRunning) {
          this.player.rotateY(this.player.moveDir.x * constants.MOVE_GYRO_SPEEDFACTOR * 2)
        } else {
          this.player.rotateY(this.player.moveDir.x * constants.MOVE_GYRO_SPEEDFACTOR)
        }
      }

      /* view position update */
      if (this.player.moveDir.z != 0) {
        /* reverse animation (+1 / -1 for timeScale) */
        this.player.walk.timeScale = this.player.moveDir.z
        if (this.player.hasGhetto) {
          const ratio = this.player.walk.time / this.player.walk.getClip().duration
          this.ghetto.position.setY(0.012 + Math.cos(2 * ratio * 2 * Math.PI) / 4000)
        }
        if (this.player.isRunning) {
          this.player.translateZ(
            this.player.moveDir.z * constants.MOVE_AXIAL_SPEEDFACTOR * 5
          )
        } else {
          this.player.translateZ(
            this.player.moveDir.z * constants.MOVE_AXIAL_SPEEDFACTOR
          )
        }
      }

      /* collision */
      const collision = this.checkCollision()
      if (collision) {
        const nc = collision.face.normal.clone().setY(0).multiplyScalar(0.002)
        // sky is inside out
        // if (collision.object.name == "Cylinder") nc.multiplyScalar(-1)
        
        const p = originalP
              .clone()
              .setY(0)
              .sub(this.player.position.clone().setY(0)) // déplacement
              .add(nc)
        this.player.position.add(p)
      }

      /* teleport to the other side of the map */
      if (this.getBorderDistance() < 0.05) {
        this.player.position.setX(-this.player.position.x)
        this.player.position.setZ(-this.player.position.z)
        this.player.zap.play()
      }

      /* set player elevation */
      let elevation = this.getElevation(this.player.position)
      if (elevation) {
        this.player.position.setY(elevation.y)
      }

      /* animate */
      this.player.walk.play()

      this.player.updateMatrixWorld()
      return true
    },

    initCollision: function() {
      this.collidableObjects = [
        ...this.columns.children,
        this.dog, this.cone,
        this.ghetto
      ]
    },

    /**
     * Returns the smallest distance between the player and 
     * the border of the world
     * 1. Casts a ray from center of the world to player's position
     * to intersect the sky
     * 2. Returns the difference between the ray length and the
     * player's distance
     */
    getBorderDistance: function() {
      let raycaster = new THREE.Raycaster(
        new THREE.Vector3(0, 0, 0), 
        new THREE.Vector3(this.player.position.x, 0, this.player.position.z)
      )
      raycaster.far = 2
      raycaster.near = 1.4
      let intersects = raycaster.intersectObject(this.sky, false)
      if (intersects && intersects.length > 0) {
        const playerDistance = Math.sqrt(
          Math.pow(this.player.position.x, 2) + Math.pow(this.player.position.z, 2)
        )
        return intersects[0].distance - playerDistance
      }
      return 2
    },
    
    /**
     * Collision manager.
     * Works with vectors define in data (4 directions)
     */
    checkCollision: function() {
      /* with raycaster */
      let intersects
      for (let i = 0; i < this.collideBoxVectors.length; i++) {
        this.raycaster.set(this.player.position, this.collideBoxVectors[i])
        intersects = this.raycaster.intersectObjects(this.collidableObjects, false)
        for (let intersect of intersects) {
          return intersect
        }
      }
      return null
    },

    startPlayerExitAnimation: function() {
      let rotationMatrix = new THREE.Matrix4()
      let playerQuaternion = new THREE.Quaternion()
      let directionToDog = new THREE.Vector3(this.dog.position.x, this.player.position.y, this.dog.position.z)
      rotationMatrix.lookAt(this.player.position, directionToDog, this.player.up)
      this.player.quaternion.setFromRotationMatrix(rotationMatrix)
      this.collidableObjects = []
      this.player.isFree = false
    },

    updatePlayerExitAnimation: function() {
      if (Math.sqrt(Math.pow(this.player.position.x - this.dog.position.x, 2) + Math.pow(this.player.position.z - this.dog.position.z, 2)) > constants.INTERACTION_DISTANCE) {
        this.player.moveDir.z = 1
        this.player.isFree = true
        this.updatePlayer()
        this.player.isFree = false
      } else {
        this.player.moveDir.z = 0
        this.player.walk.stop()
      } 
    }
  }
}
