import assets from './assets'

import BasreliefModels from '@/assets/biomes/biome2/3D/basreliefs.glb'
import ColumnModels from '@/assets/biomes/biome2/3D/columns.glb'
import ContainerModels from '@/assets/biomes/biome2/3D/containers.glb'
import DropletModels from '@/assets/biomes/biome2/3D/droplet.glb'
import GroundModel from '@/assets/biomes/biome2/3D/ground.glb'
import HandModels from '@/assets/biomes/biome2/3D/hands.glb'
import LadderModels from '@/assets/biomes/biome2/3D/ladder.glb'
import RockModels from '@/assets/biomes/biome2/3D/rocks.glb'
import SourceModels from '@/assets/biomes/biome2/3D/sources.glb'
import WallModels from '@/assets/biomes/biome2/3D/walls.glb'
import exrFile from '@/assets/biomes/biome2/3D/worldmap1024.exr'

import ee from '@/assets/biomes/biome2/3D/ee.png'

import midiTrackFile from '@/assets/biomes/biome2/audio/chorale-105bpm.mid'
import ambiantMusic from '@/assets/biomes/biome2/audio/chorale-105bpm.mp3'
import ladderSound from '@/assets/biomes/biome2/audio/ladder.wav'
import waterfallSound from '@/assets/biomes/biome2/audio/waterfall.mp3'
import victorySound from '@/assets/biomes/biome2/audio/earthquake.mp3'

import { Midi } from '@tonejs/midi'

export default {
  namespaced: true,
  state: {
    ...assets.state,
    name: 'biome2',
    
    env: null,

    ee : null,
    
    basreliefs: null,
    columns: null,
    containers: null,
    droplet: null,
    ground: null,
    hands: null,
    ladder: null,
    rocks: null,
    siflinx: null,
    sources: null,
    walls: null,
    
    ambiantMusicBuffer: null,
    waterfallSoundBuffer: null,
    ladderSoundEffectBuffer: null,
    victorySoundBuffer: null,
    midiTracks: null,
  },
  
  mutations: {
    ...assets.mutations,
    /* Special biome 2 asset setters */
    setContainers: function (state, val) {
      state.containers = [...val.scene.children.map((c) => c.clone())]
    },
    setDroplet: function(state, val) {
      state.droplet = val.scene.children[0]
    },
    setLadder: function(state, val) {
      state.ladder = val.scene.children[0]
    },
    setHands: function (state, val) {
      state.hands = val.scene.children[0]
    },
    setRocks: function(state, val) {
      state.rocks = [...val.scene.children]
    },
  },

  actions: {
    ...assets.actions,
    /* BIOME 2 ASSETS PRELOADING */
    preloadMidiTracks: async function(context) {
      context.commit('setLoading', {}, { root: true })
      let midiTracks = await Midi.fromUrl(midiTrackFile)
      midiTracks.header.setTempo(105)
      context.commit('setAsset', ['midiTracks', midiTracks])
      context.commit('setLoaded', {}, { root: true })
      console.debug(`STORE | biome2 | midiTracks loaded`)
    },
    
    preload: async function({dispatch, state}) {
      return Promise.all([
        dispatch('preloadAsset', [state.EL, exrFile, 'env']),
        dispatch('preloadAsset', [state.GL, WallModels, 'walls']),
        dispatch('preloadAsset', [state.GL, GroundModel, 'ground']),
        dispatch('preloadAsset', [state.GL, ColumnModels, 'columns']),
        dispatch('preloadAsset', [state.GL, BasreliefModels, 'basreliefs']),
        dispatch('preloadAsset', [state.GL, SourceModels, 'sources']),
        dispatch('preloadAsset', [state.GL, RockModels, 'rocks', 'setRocks']),

        dispatch('preloadAsset', [state.TL, ee, 'ee']),

        dispatch('preloadAsset', [state.GL, ContainerModels, 'containers', 'setContainers']),
        dispatch('preloadAsset', [state.GL, DropletModels, 'droplet', 'setDroplet']),
        dispatch('preloadAsset', [state.GL, LadderModels, 'ladder', 'setLadder']),
        dispatch('preloadAsset', [state.GL, HandModels, 'hands', 'setHands']),


        dispatch(
          'preloadAsset', [
            state.AL, ambiantMusic, 'ambiantMusicBuffer'
          ]),
        dispatch(
          'preloadAsset', [
            state.AL, ladderSound, 'ladderSoundEffectBuffer'
          ]),
        dispatch(
          'preloadAsset', [
            state.AL, waterfallSound, 'waterfallSoundBuffer'
          ]),
        dispatch(
          'preloadAsset', [
            state.AL, victorySound, 'victorySoundBuffer'
          ]),

        dispatch('preloadMidiTracks')
      ])
    }
  }
}
