/**
* defines player:
* - moving camera
* - hands (3D objects)
*/

import * as THREE from 'three'

import constants from './constants.js'

export const playerMixin = {
  data: function() {
    return {
      /* player (for location) */
      player: new THREE.Group(),
      /* hands */
      hands: {},
      handsMat: {},
      /* collisions */
      collideBoxVectors: [
        new THREE.Vector3(1, 0, 0),
        new THREE.Vector3(-1, 0, 0),
        new THREE.Vector3(0, 0, 1),
        new THREE.Vector3(0, 0, -1),
      ],
      collidableObjects: new THREE.Group(),
      raycaster: {},
    }
  },
  methods: {
    /**
     * check if an object is currently in sight
     * the test is quite simple, it computes the angle between 
     * the camera's direction and the vector between the object and the player
     * if this angle is lower than pi/4, we consider the object to be in sight
     */
    isInSight: function(object) {
      const camDirection = new THREE.Vector3()
      let rayToContainer = new THREE.Vector3()
      this.mainCamera.getWorldDirection(camDirection)
      rayToContainer.subVectors(object.position, this.player.position).normalize()
      return rayToContainer.angleTo(camDirection) < Math.PI / 4
    },

    /**
     * Player group creation and attach camera
     */
    createPlayer: function() {
      /* camera */
      const sceneRatio = this.renderWidth / this.renderHeight
      this.player.camera = new THREE.PerspectiveCamera(90, sceneRatio, 0.1, 200)
      this.player.moveDir = new THREE.Vector3(0, 0, 0)

      this.mainCamera = this.player.camera
      this.player.add(this.player.camera)
      this.player.camera.position.set(0, constants.EYE_LEVEL - 0.5, -0.75)
      //this.player.camera.position.copy(this.findAvailablePosition()).setY(constants.EYE_LEVEL - 0.5)
      this.player.camera.lookAt(0, constants.EYE_LEVEL, 1)

      /* defines hands and container in hands position */
      this.createHands()
      this.player.containerHandle = new THREE.Group()
      this.player.containerHandle.position.set(0, constants.EYE_LEVEL - 1, 0.9)
      this.player.add(this.player.containerHandle)

      /* player data */
      this.player.isFree = true

      /* locks a location for player and adds it to scene */
      this.player.position.copy(this.findAvailablePosition())
      this.scene.add(this.player)
    },

    /**
     * Hands model loading and attach to player
     */
    createHands: function() {
      const mat = new THREE.MeshStandardMaterial({
        metalness: 0,
        roughness: 1.0,
        transparent: true,
        opacity: 0.4,
        side: THREE.DoubleSide,
      })
      this.hands = this.assets.hands.clone()
      this.hands.material = mat
      this.hands.position.set(0, 1.8, 5.3)
      this.hands.updateMatrixWorld()
      console.log(this.hands)
      this.player.add(this.hands)
    },

    /**
     * grabs the nearest visible container
     * nearestContainer is set in 'updateContainersInteraction'
     */
    grabNearestContainer: function() {
      if (this.nearestContainer == null) return null

      let container = this.nearestContainer
      this.nearestContainer = null
      this.containers.remove(container)
      this.player.containerHandle.add(container)

      /* stores original position then centers it in hands */
      container.originalPosition = container.position.clone()
      container.originalScale = container.scale.clone()
      let h = new THREE.Vector3()
      container.geometry.boundingBox.getSize(h)
      // top of the container in hands
      container.position.set(0, -h.y * container.scale.y, 0)
    },

    /**
     * drops container
     */
    dropContainer: function () {
      const container = this.player.containerHandle.children[0]
      const waterfall = this.nearestWaterfall
      this.player.containerHandle.remove(container)

      if (waterfall) {
        this.player.isFree = false
        container.position.set(0, waterfall.position.y, 0)
        container.material.emissiveIntensity = 0.0
        container.name = "container"
        waterfall.add(container)
      } else {
        /* simply drops container in front of player */
        const direction = new THREE.Vector3()
        this.player.getWorldDirection(direction)
        let dropPosition = this.player.position.clone()
        dropPosition.add(direction.multiplyScalar(4))
        dropPosition.y = container.originalPosition.y
        container.position.copy(dropPosition)
        this.nearestContainer = container
        this.containers.add(container)
      }
    },

    /**
     * Player update
     * sets view angle then translates the player group.
     */
    updatePlayer: function() {
      if (!this.player.isFree) return false
      if (this.player.moveDir.length() < 0.01) return false
      
      const originalP = this.player.position.clone()

      /* view angle update */
      if (this.player.moveDir.x != 0) {
        const spec = new THREE.Euler(0, 0, 0, 'YXZ').setFromQuaternion(
          this.player.quaternion
        )
        spec.y += this.player.moveDir.x * constants.MOVE_GYRO_SPEEDFACTOR
        this.player.quaternion.setFromEuler(spec)
      }

      /* view position update */
      if (this.player.isRunning) {
        this.player.translateZ(
          this.player.moveDir.z * constants.MOVE_AXIAL_SPEEDFACTOR * 5
        )
      } else {
        this.player.translateZ(
          this.player.moveDir.z * constants.MOVE_AXIAL_SPEEDFACTOR
        )
      }

      /* light walking oscillation */
      // const yOriginal = this.player.position.y
      // if (!this.godMode) {
      //   const f = 0.2
      //   this.player.position.y =
      //     yOriginal + 0.002 * Math.cos(2 * PI * f * this.now * 0.001)
      // }

      /* collision */
      const collision = this.checkCollision()
      if (collision) {
        const nc = collision.face.normal.clone().setY(0)
        const p = originalP
              .clone()
              .setY(0)
              .sub(this.player.position.clone().setY(0)) // déplacement
              .multiplyScalar(-1)
              .add(nc)
        this.player.position.add(p)
      }
      this.player.updateMatrixWorld()
      return true
    },

    initCollision: function() {
      this.collidableObjects = [
        this.cave,
        ...this.columns.children,
      ]
    },


    /**
     * Collision manager.
     * Works with vectors define in Biome2.vue.
     */
    checkCollision: function() {
      /* with raycaster */
      let intersects
      for (let i = 0; i < this.collideBoxVectors.length; i++) {
        this.raycaster.set(this.player.position, this.collideBoxVectors[i])
        intersects = this.raycaster.intersectObjects(this.collidableObjects, false)
        if (intersects.length) {
          return intersects[0]
        }
      }
      return null
    },
  }
}
