/**
 * handles key controls for biome 4
 */
export const keyboardMixin = {
  data: function() {
    return {
      easterAvailable: false,
      easter: 0,
    }
  },
  methods: {
    /**
     * 'Space' or 'Enter': grab or drop the closest container
     * 'V': show controls
     * 'C': debug in console
     */
    onKeyPress: function(event) {
      if (event.code == 'Space') {
        this.grabOrDropNearestObject()
      }
      else if (event.code == 'Enter') {
        this.interactWithNearestObject()
      }
      else if (event.code == 'KeyV') {
        this.showControls = !this.showControls
      }

      // if (event.code == 'KeyN') {
      //   this.scene.add(this.flow)
      //   this.ghettoInPlace = true
      // }

      if (this.easterAvailable == true) {
        if (
          (event.code == 'Space' && (this.easter == 0 || this.easter == 3)) ||
            (event.code == 'Enter' && (this.easter == 1 || this.easter == 2 || this.easter == 4))
        ) {
          this.easter += 1
        } else {
          this.easter = 0
        }
        
        if (this.easter == 5) {
          this.biomeState = 'EASTER'
          this.player.add(this.mainCamera)
          this.mainCamera.position.set(0, .08, .1)
          this.mainCamera.lookAt(this.player.position.x, this.player.position.y + 0.045, this.player.position.z)
          this.player.isFree = true
          this.player.moveDir.z = 0
          this.player.walk.stop()
          this.$store.commit('setAnimating', false)
        }
      }
    },

    /**
     * Set player movements
     * 'up' or 'W': go forward
     * 'down' or 'S': go backward
     * 'left' or 'A': rotate left
     * 'right' or 'D': rotate right
     * 'shift': run
     */
    onKeyDown: function(event) {
      const keyCode = event.code
      const movementSpeed = 1
      if (keyCode == 'ArrowUp' || keyCode == 'KeyW') {
        this.player.moveDir.z = movementSpeed
      }
      if (keyCode == 'ArrowDown' || keyCode == 'KeyS') {
        this.player.moveDir.z = -movementSpeed
      }
      if (keyCode == 'ArrowLeft' || keyCode == 'KeyA') {
        this.player.moveDir.x = movementSpeed
      }
      if (keyCode == 'ArrowRight' || keyCode == 'KeyD') {
        this.player.moveDir.x = -movementSpeed
      }
      if (['ShiftLeft', 'ShiftRight'].includes(event.code)) {
        this.player.isRunning = true
      }

      if (this.easterAvailable == true) {
        clearInterval(this.intervalId)
      }
    },

    /**
     * Stop player movements
     * 'up' or 'W': go forward
     * 'down' or 'S': go backward
     * 'left' or 'A': rotate left
     * 'right' or 'D': rotate right
     * 'shift': run
     */
    onKeyUp: function(event) {
      const keyCode = event.code
      if (keyCode == 'ArrowUp' || keyCode == 'KeyW') {
        this.player.moveDir.z = 0
      }
      if (keyCode == 'ArrowDown' || keyCode == 'KeyS') {
        this.player.moveDir.z = 0
      }
      if (keyCode == 'ArrowLeft' || keyCode == 'KeyA') {
        this.player.moveDir.x = 0
      }
      if (keyCode == 'ArrowRight' || keyCode == 'KeyD') {
        this.player.moveDir.x = 0
      }
      if (['ShiftLeft', 'ShiftRight'].includes(event.code)) {
        this.player.isRunning = false
      }

      if (this.easterAvailable == true) {
        this.countdownQuit()
      }
    }
  }
}
