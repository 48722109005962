import { render, staticRenderFns } from "./Biome3En.vue?vue&type=template&id=5aba8760&scoped=true&"
import script from "./Biome3En.vue?vue&type=script&lang=js&"
export * from "./Biome3En.vue?vue&type=script&lang=js&"
import style0 from "@/assets/css/menus.css?vue&type=style&index=0&id=5aba8760&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../usr/local/share/envs/nodeenv-sphinx/lib/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5aba8760",
  null
  
)

export default component.exports