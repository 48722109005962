import * as THREE from 'three'
import { EXRLoader } from 'three/examples/jsm/loaders/EXRLoader'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

/* unique loaders for EXR files, GLB files and audio files */
const EL = new EXRLoader()
const TL = new THREE.TextureLoader()
const GL = new GLTFLoader()
const AL = new THREE.AudioLoader()

/**
 * creates a Promise containing 'loader.load'
 * use it to 'block' the app while assets are not loaded
 */
function customLoader(loader, url) {
  return new Promise((resolve, reject) => {
    loader.load(
      url,
      (data) => resolve(data),
      (progress) => { },
      reject
    )
  })
}

export default {
  state: {
    /* preloading */
    EL: EL,
    TL: TL,
    GL: GL,
    AL: AL,
  },
  mutations: {
    /* General asset setter */
    setAsset: function(state, [assetName, val]) {
      state[assetName] = val
    },
  },
  actions: {
    /** 
     * generic asset preload function
     */
    preloadAsset: async function(context, [loader, url, assetName, mutation]) {
      context.commit('setLoading', {}, { root: true })
      customLoader(loader, url).then((val) => {
        if (mutation) {
          context.commit(mutation, val)
        } else {
          context.commit('setAsset', [assetName, val])
        }
        context.commit('setLoaded', {}, { root: true })
        console.debug(`STORE | ${context.state.name} | ${assetName} loaded`)
      })                             
    },
  }
}
