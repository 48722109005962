/**
 * mixin to handle pause in biomes.
 * based on the principle that each biome has a 'animate' method that is
 * the loop
 */
import { mapState } from 'vuex'

export const pauseMixin = {
  data: function() {
    return {
      pauseStart: 0,
      pauseDuration: 0,
    }
  },
  computed: {
    ...mapState({
      pause: (state) => state.pause,
    })
  },
  watch: {
    pause: function (pausing, waspaused) {
      console.debug("pause from", waspaused, "to", pausing)
      console.log(this.audioCtx.state)
      if (pausing) {
        this.pauseStart = this.now
        cancelAnimationFrame(this.animationRequestId)
        if (this.audioCtx.state === 'running') {
          this.audioCtx.suspend()
        }
      } else { // resuming
        this.animationRequestId = requestAnimationFrame(this.animate)
        if (this.audioCtx.state === 'suspended') {
          this.audioCtx.resume()
        }
        this.pauseDuration += this.now - this.pauseStart
      }
    },
  },
}
