<template>
  <div>
    <div
      v-if="debug"
      v-show="showControls"
      id="button-next-level"
      @click="$store.commit('setLevel', 4)"
    >
      SUIVANT
    </div>
    <div
      v-if="debug"
      v-show="showControls"
      id="stats-container"
    />
    <canvas />
  </div>
</template>

<script>
/* VUE imports */
import { mapState } from 'vuex'
/* THREEJS */
import * as THREE from 'three'

/* SPHINX */
import { pauseMixin } from'@/components/biomes/pause.js'

import constants from '@/components/biomes/biome3/constants.js'

import { audioMixin } from'@/components/biomes/biome3/audio.js'
import { brickMixin } from'@/components/biomes/biome3/brick.js'
import { cameraAnimationMixin } from'@/components/biomes/biome3/camanim.js'
import { characterMixin } from'@/components/biomes/biome3/character.js'
import { doorMixin } from'@/components/biomes/biome3/door.js'
import { keyboardMixin } from'@/components/biomes/biome3/keyboard.js'
import { pathMixin } from'@/components/biomes/biome3/path.js'
import { playerMixin } from'@/components/biomes/biome3/player.js'
import { sceneMixin } from'@/components/biomes/biome3/scene.js'

export default {
  name: 'biome3',
  components: {},
  props: {},
  mixins: [
    audioMixin,
    brickMixin,
    cameraAnimationMixin,
    characterMixin,
    doorMixin,
    keyboardMixin,
    pathMixin,
    pauseMixin,
    playerMixin,
    sceneMixin
  ],
  data: function () {
    return {
      isReady: false,
      /* Canvas DOM */
      sceneContainer: {},
      canvas: {},
      /* Main renderer */
      renderer: {},
      animationRequestId: null,
      mixers: [],
      /* Main scene */
      scene: {},
      /* Cameras */
      mainCamera: {},
      clock: new THREE.Clock(),
      dt: 0,
      now: 0,
      biomeState: 'INTRO',

      animationState: '',
      talkingStart: null,
      talkingEnd: null,
      /* Develop/Experiment controls */
      controls: {},
      stats: null,
      showControls: false,
      showReloadButton: false,
    }
  },
  computed: {
    debug: () => process.env.NODE_ENV == 'development',
    ...mapState({
      audioCtx: (state) => state.audioCtx,
      assets: (state) => state.biome3,
      animating: (state) => state.animating,
    }),
  },
  mounted: async function () {
    this.initScene()
    this.initAudio()

    this.setEnvironmentalMap()

    this.createEnvironment()
    this.createPaths()
    this.createCharacters()
    this.createPlayer()
    this.createInventory()
    this.createCloud()

    this.onResize()

    // for (let name of constants.CHARACTERS) {
    //   this.nearestCharacter = this.characters.children.find(c => c.name == name)
    //   this.createBrick()
    //   this.updateBrickAnimation(0.8)
    //   this.updateBrickAnimation(1.1)
    //   this.ownedBricks.push(this.newBrick)
    // }
    // this.newBrick = null

    window.addEventListener('resize', this.onResize)
    window.addEventListener('pointermove', this.onPointerMove)
    window.addEventListener('keypress', this.onKeyPress)
    window.addEventListener('keydown', this.onKeyDown)
    window.addEventListener('keyup', this.onKeyUp)
    window.addEventListener('click', this.onMouseClick)

    this.animate()
  },
  created: function () {
    console.log('BIOME 3 CREATED')
    // if (process.env.NODE_ENV == 'development') {
    //   this.showControls = true
    // }
  },
  beforeDestroy: function () {
    console.log('BIOME 3 DESTROY')
    cancelAnimationFrame(this.animationRequestId)
    // this.audioCleanUp()
    this.renderer.dispose()
  },
  methods: {
    animate: function () {
      this.animationRequestId = requestAnimationFrame(this.animate)

      this.dt = this.clock.getDelta()
      this.now = performance.now()

      if (this.biomeState == 'INTRO') {
        if (!this.isReady) {
          this.isReady = true
          this.$store.commit('setHidden', false)

          this.renderer.compile(this.scene, this.mainCamera)
          const mixer = this.startCameraIntroAnimation()
          mixer.addEventListener('finished', (event) => {
            this.biomeState = 'PLAYING'
            this.createInventory()
            this.$store.commit('setAnimating', false)
          })
        }
        this.updateCameraIntroAnimation()
      } else if (this.biomeState == 'PLAYING') {
        this.$store.commit('setAnimating', false)
        this.resizeInventory()
        if (this.glow) {
          this.updateGlow()
        }
        this.updatePlayer()
        this.findNearestCharacter()

        if (this.ownedBricks.length == 7 && this.player.position.x > 2800) {
          this.$store.commit('setAnimating', true)
          this.biomeState = 'BRIDGE_BUILDING'
          this.startDoorAnimation()
        }

        if (this.door && this.player.position.x > 3000) {
          this.$store.commit('setAnimating', true)
          this.biomeState = 'OUTRO'
          this.startOutroAnimation()
        }
        
        if (this.newBrick) {
          this.startTalkingAnimation()
        }
      } else if (this.biomeState == 'TALKING') {
        this.updateTalkingAnimation()
      } else if (this.biomeState == 'BRIDGE_BUILDING') {
        this.updateDoorAnimation()
      } else if (this.biomeState == 'OUTRO') {
        this.updateOutroAnimation()
      }
      this.render()
    },

    startOutroAnimation: function() {
      this.player.walk.stop()

      this.cameraStart = { position: new THREE.Vector3() }
      this.cameraEnd = { position: new THREE.Vector3() }
      this.outroStart = this.now
      this.outroEnd = this.now + 3000
      this.cameraStart.position.copy(this.player.position)
      this.cameraEnd.position.copy(this.glow.position)
      setTimeout(() => {
        this.$store.commit('setHidden', true)
      }, 2000)
      setTimeout(() => {
        this.$store.commit('setLevel', 4)
      }, 3000)
    },

    updateOutroAnimation: function() {
      if (this.animating) {
        const animT = THREE.MathUtils.mapLinear(
          this.now, this.outroStart, this.outroEnd, 0, 1)

        const cameraPos = new THREE.Vector3().lerpVectors(
          this.cameraStart.position,
          this.cameraEnd.position,
          animT
        )
        
        this.player.position.copy(cameraPos)
        this.deltaCamera.setZ(this.deltaCamera.z / 1.05)
        this.deltaCamera.setY(this.deltaCamera.y / 1.05)
        this.mainCamera.position.copy(cameraPos).add(this.deltaCamera)

        this.player.rotation.x -= 0.4
        this.player.rotation.z -= 0.32
        this.player.scale.divideScalar(1.1)
        this.mainCamera.lookAt(this.player.position)

        if (this.now > this.outroEnd) {
          this.$store.commit('setAnimating', false)
        }
      }  
    },
    
    startTalkingAnimation: function() {
      this.biomeState = 'TALKING'
      this.animationState = 'WAITING'
      this.nearestCharacter.stop()
    },

    updateTalkingAnimation: function() {
      const animT = THREE.MathUtils.mapLinear(
        this.now, this.talkingStart, this.talkingEnd, 0, 1)

      if (this.animating) {
        console.log("animating", this.animationState)
        if (this.animationState == 'MOVING_CAMERA' || this.animationState == 'BRICK_DONE') {
          this.updateCameraTalkingAnimation(animT)
        } else if (this.animationState == 'MATERIALIZING_BRICK') {
          this.updateBrickAnimation(animT)
        }
        if (animT > 1) {
          this.$store.commit('setAnimating', false)
        }
      } else {
        if (this.animationState != 'OVER') {
          const stateIdx = constants.TALK_ANIMATION_STATES.indexOf(this.animationState)
          this.animationState = constants.TALK_ANIMATION_STATES[stateIdx + 1]
          this.talkingStart = this.now
          this.talkingEnd = this.now + constants.TALK_ANIMATION_DURATIONS[stateIdx + 1]
        }

        if (this.animationState == 'MOVING_CAMERA') {
          this.startCameraTalkingAnimation()
          this.$store.commit('setAnimating', true)
        } else if (this.animationState == 'MATERIALIZING_BRICK') {
          this.startBrickAnimation()
          this.$store.commit('setAnimating', true)
        } else if (this.animationState == 'BRICK_DONE') {
          this.inventory.remove(this.cloud)
          this.startCameraBackAnimation()
          this.$store.commit('setAnimating', true)
        } else {
          this.ownedBricks.push(this.newBrick)
          this.newBrick = null
          this.nearestCharacter.play()

          this.biomeState = 'PLAYING'
        }
      }
    },
    
    onResize: function() {
      // console.debug("resize !", this.sceneContainer.clientWidth, this.sceneContainer.clientHeight)
      this.renderWidth = this.sceneContainer.clientWidth
      this.renderHeight = this.sceneContainer.clientHeight
      this.mainCamera.aspect = this.renderWidth / this.renderHeight
      this.mainCamera.updateProjectionMatrix()
      this.renderer.setPixelRatio(window.devicePixelRatio)
      this.renderer.setSize(this.renderWidth, this.renderHeight)
      this.resizeInventory()
    },
  },
}
</script>

<style scoped>
#biome-3-container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  opacity: 0;
  transition: opacity 2s linear;
}

#biome-3-container.ready {
 opacity: 1;
}

#biome-3-container .band {
  position: absolute;
  width: 100vw;
  height: 100vh;
  border-top: 0 solid black;
  border-bottom: 0 solid black;
  box-sizing: border-box;
  transition: all 1s linear;
}

#biome-3-container.animating .band {
  border-top: 12vh solid black;
  border-bottom: 12vh solid black;
}


#biome-3-container canvas {
  width: 100vw;
  height: 100vh;
}

#help-screen {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  user-select: none;
}

.button {
  border: 1px solid #fff;
}

.button:hover {
  box-shadow: 2px 2px 2px #fff;
}
/* TEMPORARY */
#button-next-level {
  position: absolute;
  z-index: 2;
  bottom: 0;
  right: 0;
  padding: 5px;
  cursor: pointer;
}
.fadeout {
  opacity: 0;
}
</style>
