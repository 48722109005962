<template>
  <div>
    <h1>
      <img
        src="@/assets/images/en/instructions.png"
        class="image-title"
      />
    </h1>
    <div class="text">
      <p>Welcome to Sphinx.</p>
      
      <p>Bringing the enigma down from its pedestal, the resolution lies in the journey. Instructions are guiding you through your experience. You are advancing with the dough of the Noosphere, the sphere of human thought. And you embody it.</p>
      
      <template v-if="appVersion == 'controller'">
        <p>
          In the first world, you can only interact through the microphone (in the controller). In the three next worlds, you can move using the joystick, and interact with the red and yellow buttons.
        </p>
        <p>
          At any time, you can press the right button (white) to pause the game.
        </p>
      </template>
      
      <template v-else-if="appVersion == 'arcade'">
        <p>In the first world, you can only interact through the microphone (below the screen). In the next three worlds, you can move around using the joystick, and interact with the left and center buttons.</p>
        <p>
          At any time, you can press the right button to pause the game.
        </p>
      </template>
      
      <template v-else>
        <p>
          In the first world, you can only interact through the microphone. In the next three worlds, you can move around using the arrows on your keyboard or W, A, S, D; and interact pressing the space bar and enter key.
        </p>
        <p>
          At any time, you can press P to pause the game.
        </p>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InstructionsEn',
  computed: {
    appVersion: () => process.env.VUE_APP_VERSION,
  }
}
</script>

<style scoped src="@/assets/css/menus.css" />
