import * as THREE from 'three'

import { GPUComputationRenderer } from 'three/examples/jsm/misc/GPUComputationRenderer.js'

/* local custom shaders */
import waterVertexShader from '@/shaders/biome1/vert_water.glsl'
import heightmapFragmentShader from '@/shaders/biome1/frag_gpgpu-heightmapcustom.glsl'
import alphaFragShader from '@/shaders/biome1/frag_alpha.glsl'
import skyDomeFragmentShader from '@/shaders/biome1/frag_skydome.glsl'
import skyDomeVertexShader from '@/shaders/biome1/vert_skydome.glsl'

/* composer */
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass'
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass'
import { SMAAPass } from 'three/examples/jsm/postprocessing/SMAAPass'

import constants from '@/components/biomes/biome1/constants.js'


const TL = new THREE.TextureLoader()

export const sceneMixin = {
  data: function() {
    return {
      /* canvas and scene */
      sceneContainer: {},
      canvas: {},
      scene: {},
      renderer: {},
      center: new THREE.Vector3(0, 0, 0),
      renderedFrames: 0,

      /* Light */
      sunLight: {},
      
      /* Water mesh and compute */
      waterMesh: {},
      extendedWaterMesh: {},
      gpuCompute: {},
      heightmapVariable: {},
      waterHeightMap: {},
      waveCoefficient: 1,
      positiveHole: false,
      meshRay: {},
      waterGroup: new THREE.Group(),
      
      /* environmental skydome */
      skyDomeMaterial: {},
      skyDome: {},
      skyDomeScale: 1.2,
      
      /* environmental render */
      cubeCam: {},
      cubeRenderTarget: {},
      cubeRenderTargetSize: 64,

      /* composer */
      composer: {},
      renderPass: {},
      smaaPass: {},
      bloomPass: {},
    }
  },
  
  methods: {
    initScene: function () {
      /* THREEJS 3D CANVAS AND RENDERER */
      this.sceneContainer = document.getElementById('biome')
      this.canvas = this.sceneContainer.querySelector('canvas')
      /* SCENE initialisation */
      this.scene = new THREE.Scene()
      /* RENDERER */
      this.renderer = new THREE.WebGLRenderer({
        antialias: true,
        canvas: this.canvas,
      })
      this.renderer.physicallyCorrectLights = true
      this.renderer.toneMappingExposure = 1.0
      this.renderer.outputEncoding = THREE.sRGBEncoding
      this.renderer.toneMapping = THREE.ACESFilmicToneMapping
      
      /* axes helper */
      // const helper = new THREE.AxesHelper(10)
      // this.scene.add(helper)
    },
    initEnvironment: function () {
      this.sunLight = new THREE.PointLight(0x0000ff, 250.0)
      this.sunLight.position.set(0, 10, 50)
      this.scene.add(this.sunLight)
    },
    initPostProcessing: function() {
      this.composer = new EffectComposer(this.renderer)
      this.renderPass = new RenderPass(this.scene, this.mainCamera)
      this.composer.addPass(this.renderPass)
      this.bloomPass = new UnrealBloomPass(
        new THREE.Vector2(window.innerWidth, window.innerHeight),
        0.4,
        0.4,
        0.2
      )
      this.composer.addPass(this.bloomPass)
      this.smaaPass = new SMAAPass(window.innerWidth, window.innerHeight)
      this.composer.addPass(this.smaaPass)
    },

    /**
     * ENVIRONMENT MESH AND MAP
     */
    createSkyDome: function (theta) {
      /* sizings */
      const SKYDOME_RADIUS = constants.OCEAN_WIDTH * 4
      const BOX_S = SKYDOME_RADIUS * 4
      /* texture choice */
      const textureFilePath = '/media/img/fonds/IMG_5099-bis.jpg'
      let texture = TL.load(textureFilePath)
      texture.encoding = THREE.sRGBEncoding
      texture.format = THREE.RGBAFormat
      
      /* SkyDome geometry */
      let geo = new THREE.SphereGeometry(
        SKYDOME_RADIUS,
        24,
        12,
        0,
        2 * Math.PI,
        0,
        0.5 * Math.PI
      )
      
      this.skyDomeMaterial = new THREE.ShaderMaterial({
        uniforms: {
          quietAnimationIndex: { value: this.quietAnimationIndex },
          pitchAnimationIndex: { value: new THREE.Vector2(0.0, 0.0) },
          tDiffuse: { type: 't', value: texture },
        },
        vertexShader: skyDomeVertexShader,
        fragmentShader: skyDomeFragmentShader,
        side: THREE.BackSide,
      })
      /* skydome mesh */
      this.skyDome = new THREE.Mesh(geo, this.skyDomeMaterial)
      this.skyDome.scale.set(0.2, 0.2, 0.2)
      if (this.useSphere) {
        this.skyDome.position.y = -20
      }
      this.skyDome.rotation.y = theta

      this.scene.add(this.skyDome)
      
      /* cube texture render target */
      this.cubeRenderTarget = new THREE.WebGLCubeRenderTarget(
        this.cubeRenderTargetSize,
        {
          format: THREE.RGBAFormat,
          encoding: THREE.sRGBEncoding,
        }
      )
      this.cubeCam = new THREE.CubeCamera(1, 200, this.cubeRenderTarget)
      this.scene.environment = this.cubeRenderTarget.texture
    },

    /**
     * Creates the body of water, attributes shaders, and gets heightmap
     */
    createOcean: function (theta) {
      /* water geometry */
      const geo = new THREE.PlaneGeometry(
        constants.OCEAN_WIDTH,
        constants.OCEAN_HEIGHT,
        constants.OCEAN_WIDTH_SUBDIV - 1,
        constants.OCEAN_WIDTH_SUBDIV - 1
      )
      /* water shader material */
      const mat = new THREE.MeshStandardMaterial({
        metalness: 0.5,
        roughness: 0,
        color: 0x888888,
        transparent: true,
        depthWrite: true,
        depthTest: true,
        side: THREE.DoubleSide,
      })
      /* customizing standard material shaders */
      mat.onBeforeCompile = (shader) => {
        shader.vertexShader = waterVertexShader
        shader.fragmentShader = alphaFragShader
        shader.uniforms.heightmap = { value: this.waterHeightMap }
        shader.uniforms.showHole = { value: false }
        shader.uniforms.holeSize = { value: 0.0 }
        mat.userData.shader = shader
        mat.userData.uniforms = shader.uniforms
      }
      /* shaders constants */
      mat.defines.WIDTH = constants.OCEAN_WIDTH_SUBDIV.toFixed(1)
      mat.defines.BOUNDS = constants.BOUNDS.toFixed(1)
      /* gestion du mesh */
      this.waterMesh = new THREE.Mesh(geo, mat)
      this.waterMesh.rotation.x = -0.5 * Math.PI
      this.waterMesh.updateMatrix()
      this.waterMesh.renderOrder = 1
      this.waterGroup.add(this.waterMesh)
      /* gpgpu class */
      this.gpuCompute = new GPUComputationRenderer(
        constants.OCEAN_WIDTH_SUBDIV,
        constants.OCEAN_WIDTH_SUBDIV,
        this.renderer
      )
      /* heightmap */
      const hmap0 = this.gpuCompute.createTexture()
      // this.fillTexture(hmap0)
      this.heightmapVariable = this.gpuCompute.addVariable(
        'heightmap',
        heightmapFragmentShader,
        hmap0
      )
      this.gpuCompute.setVariableDependencies(this.heightmapVariable, [
        this.heightmapVariable,
      ])
      const hmVarMat = this.heightmapVariable.material
      hmVarMat.uniforms['mousePos'] = {
        value: new THREE.Vector2(10000, 10000),
      }
      /* heightmap uniforms */
      hmVarMat.uniforms.centerHoleHeight = { value: 2.0 }
      hmVarMat.uniforms.mouseSize = { value: 20.0 }
      hmVarMat.uniforms.viscosityConstant = { value: 0.97 }
      // hmVarMat.uniforms.viscosityConstant = { value: 0.96 }
      hmVarMat.uniforms.heightCompensation = { value: 0.0 }
      hmVarMat.uniforms.positiveHole = { value: this.positiveHole }
      hmVarMat.defines.BOUNDS = constants.BOUNDS.toFixed(1)
      hmVarMat.defines.WIDTH = constants.OCEAN_WIDTH.toFixed(1)
      /* initialisation GPGPU */
      const error = this.gpuCompute.init()
      if (error) console.error(error)
      /* extendedWater */
      const alphaMap = TL.load('/media/img/masque-rond-petit.jpg')
      alphaMap.format = THREE.RGBAFormat
      // alphaMap.repeat.set(0.5,0.5)
      const extendedWaterGeometry = new THREE.PlaneGeometry(
        constants.OCEAN_WIDTH * 6,
        constants.OCEAN_WIDTH * 6
      )
      const extendedWaterMaterial = mat.clone()
      extendedWaterMaterial.depthWrite = false
      this.extendedWaterMesh = new THREE.Mesh(
        extendedWaterGeometry,
        extendedWaterMaterial
      )
      // this.extendedWaterMesh.renderOrder = 1
      this.extendedWaterMesh.rotation.x = -0.5 * Math.PI
      this.extendedWaterMesh.position.y = -1
      this.waterGroup.add(this.extendedWaterMesh)
      this.waterGroup.rotation.y = theta
      
      this.scene.add(this.waterGroup)
    },

    /**
     * Water mesh animation
     */
    animateHole: function () {
      const uniforms = this.heightmapVariable.material.uniforms

      if (this.micVolume > this.MIC_VOL_THRESHOLD) {
        /* waves from microphone */
        uniforms.centerHoleHeight.value = -this.waveCoefficient * this.micVolume
      } else {
        /* waves from intro sound */
        uniforms.centerHoleHeight.value = -4 * this.introVolume
      }

      /* gotohole */
      if (this.biomeState == 'OUTRO') {
        uniforms.centerHoleHeight.value = -10
      }
    },
    handleGPUCompute: function () {
      this.gpuCompute.compute()
      this.waterHeightMap = this.gpuCompute.getCurrentRenderTarget(
        this.heightmapVariable
      ).texture
    },
    /**
     * ANIMATION / RENDERING
     */

    renderEnvironment: function () {
      this.waterMesh.visible = false
      this.extendedWaterMesh.visible = false
      // this.scene.fog.near = 1000
      this.cubeCam.update(this.renderer, this.scene)
      this.waterMesh.visible = true
      this.extendedWaterMesh.visible = true
    },

    render: function () {
      this.renderEnvironment()
      this.handleGPUCompute()
      if (this.composer) {
        this.composer.render()
      } else {
        this.renderer.render(this.scene, this.mainCamera)
      }
    },
  }
}
