/**
 * builds the exit door
 */
import * as THREE from 'three'

import glowTexture from '@/assets/biomes/biome3/3D/glow.png'
import constants from '@/components/biomes/biome3/constants.js'

export const doorMixin = {
  data: function() {
    return {
      door: new THREE.Group(),
      glow: null,
      doorStart: 0,
      doorEnd: 0
    } 
  },
  methods: {
    /**
     * creates the exit door
     * glow effect from
     * https://stemkoski.github.io/Three.js/Shader-Glow.html
     */
    createDoor: async function() {
      for (const child of this.assets.door.scene.children) {
        let element = Object.create(child)
        //this.door.material = this.door.material.clone()
        // this.door.material.envMap = this.environmentRenderTarget.texture
        //this.door.visible = true
        // element.material.blending = THREE.NormalBlending
        // element.material.side = THREE.BackSide
        element.material.opacity = 0
        element.material.transparent = true
        element.material.blending = THREE.NormalBlending
        this.door.add(element)
      }
      this.door.position.setZ(this.door.position.z - 50)
      this.scene.add(this.door)

      const vertices = new Float32Array(
        [20.0, -750.0,  1.0, // v0
	 40.0, -750.0,  1.0, // v1
	 85.0,  750.0,  1.0, // v2
         
	 85.0,  750.0,  1.0, // v3
	 -90.0,  750.0,  1.0, // v4
	 20.0, -750.0,  1.0  // v5
        ])
      
      const geometry = new THREE.BufferGeometry()
      geometry.setAttribute('position', new THREE.BufferAttribute(vertices , 3))

      const material = new THREE.MeshBasicMaterial(
        {
          color: 0x000000,
          side: THREE.BackSide,
          opacity: 0,
          transparent: true
        })
      const plane = new THREE.Mesh(geometry, material)
      plane.rotation.x = Math.PI / 2
      plane.position.set(3030, 275, -800)
      this.door.add(plane)
      
      // SUPER SIMPLE GLOW EFFECT
      // use sprite because it appears the same from all angles
      var spriteMaterial = new THREE.SpriteMaterial( 
	{ 
	  map: new THREE.TextureLoader().load(glowTexture),
	  useScreenCoordinates: false,
          transparent: false,
          blending: THREE.AdditiveBlending,
	})
      this.glow = new THREE.Sprite(spriteMaterial)
      this.glow.scale.set(100, 100, 1.0)
      this.glow.position.set(3060, 370, -1200)
      this.glow.visible = false
      this.scene.add(this.glow)
    },

    updateGlow: function() {
      const scale = 90 + 20 * Math.random()
      this.glow.scale.set(scale, scale, 1.0)
    },
    
    startDoorAnimation: function() {
      this.createDoor()
      this.doorStart = this.now
      this.doorEnd = this.now + 3000
      this.player.walk.stop()
      
      let position = new THREE.Vector3()
      let cnt = 0
      for (let brick of this.ownedBricks) {
        brick.getWorldPosition(position)
        this.scene.add(brick)
        brick.position.copy(position)
        brick.startPosition = new THREE.Vector3().copy(position)
        cnt += 1
      }
      console.log(this.ownedBricks)
      console.log(this.door)

    },

    updateDoorAnimation: function() {
      if (this.animating) {
        let animT = Math.min(
          Math.pow(
            THREE.MathUtils.mapLinear(this.now, this.doorStart, this.doorEnd, 0, 1),
            6
          ), 1
        )
        
        const scaleT = THREE.MathUtils.mapLinear(
          animT, 0, 1, 0.0002, 0.038)
        
        
        let cnt = 0
        for (let brick of this.ownedBricks) {
          const xT = THREE.MathUtils.mapLinear(
            animT, 0, 1, brick.startPosition.x, 2955.0 + cnt * 24)
          const yT = THREE.MathUtils.mapLinear(
            animT, 0, 1, brick.startPosition.y, 275.0)
          const zT = THREE.MathUtils.mapLinear(
            animT, 0, 1, brick.startPosition.z, 30.0)
          
          brick.scale.setScalar(scaleT)
          brick.position.set(xT, yT, zT)
          
          cnt += 1
        }
        for (let element of this.door.children) {
          element.material.opacity = animT
        }
        
        if (this.now > this.doorEnd) {
          this.$store.commit('setAnimating', false)
          this.biomeState = 'PLAYING'
          this.ownedBricks = []

          for (let element of this.door.children) {
            element.material.transparent = false
          }
          this.glow.visible = true
        }
      }
    }
  }
}
