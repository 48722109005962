/**
 * builds the main environment: ground, water, sky and clouds
 * that is non interactive elements 
 */
import * as THREE from 'three'

import constants from '@/components/biomes/biome4/constants.js'

export const desertMixin = {
  data: function() {
    return {
      floor: {},
      sky: {},
      water: {},
      tide: 0,
      plants: new THREE.Group(),
      plantMixers: []
    } 
  },
  methods: {
    /**
     * creates the desert
     * - sand floor
     * - sky
     * - water
     * - plants
     */
    createDesert: async function() {
      this.floor = Object.create(this.assets.desert.scene.children.find(elem => elem.name == "Desert"))
      this.sky = Object.create(this.assets.desert.scene.children.find(elem => elem.name == "Cylinder"))
      this.water = Object.create(this.assets.desert.scene.children.find(elem => elem.name == "Eau")).clone()

      this.sky.material.side = THREE.BackSide

      this.water.material.bumpMap = this.assets.tide
      this.water.material.bumpScale = 0.001
      this.water.material.bumpBias = 0
      this.water.material.needsUpdate = true

      this.scene.add(this.floor)
      this.scene.add(this.sky)
      this.scene.add(this.water)

      this.createPlants()
    },

    createPlants: async function() {
      let rotWorldMatrix = new THREE.Matrix4()
      let axis = new THREE.Vector3(0, 1, 0).normalize()
      
      for (let model of this.assets.plants.scene.children) {
        for (let instance of constants.PLANT_INSTANCES.filter(
          mesh => mesh.name.startsWith(model.name)
        )) {
          let tmp = Object.create(model).clone()
          const animdata = constants.PLANT_ANIMATIONS[model.name]
          if (instance.translation) tmp.position.set(...instance.translation)

          if (!model.name.startsWith("Plant_2")) {
            rotWorldMatrix.makeRotationAxis(axis, Math.random() * 2 * Math.PI)
            rotWorldMatrix.multiply(tmp.matrix)
            tmp.rotation.setFromRotationMatrix(rotWorldMatrix)
          }
              
          tmp.scale.setScalar(animdata.scale[0])

          let start = 10 + Math.random() * 20
          let duration = 10 + Math.random() * 20
          let mixer = new THREE.AnimationMixer(tmp)
          let track = new THREE.VectorKeyframeTrack(
            '.position',
            [start, start + duration / 5, start + duration],
            [
              tmp.position.x,
              tmp.position.y + animdata.y[0],
              tmp.position.z,
              tmp.position.x,
              tmp.position.y + animdata.y[1],
              tmp.position.z,
              tmp.position.x,
              tmp.position.y + animdata.y[2],
              tmp.position.z,
            ]
          )
          let track2 = new THREE.VectorKeyframeTrack(
            '.scale',
            [start, start + duration / 5, start + duration],
            [
              animdata.scale[0],
              animdata.scale[0],
              animdata.scale[0],
              animdata.scale[1],
              animdata.scale[1],
              animdata.scale[1],
              animdata.scale[2],
              animdata.scale[2],
              animdata.scale[2],
            ]
          )
          const animationClip = new THREE.AnimationClip(null, start + duration, [track, track2])
          const animationAction = mixer.clipAction(animationClip)
          animationAction.loop = THREE.LoopOnce
          animationAction.clampWhenFinished = true
          animationAction.play()
          mixer.update(0)
          this.plantMixers.push(mixer)
          this.plants.add(tmp)
        }
      }
      this.plantMixers[0].addEventListener('finished', (event) => {
        this.countdownQuit()
        this.easterAvailable = true
      })
      this.scene.add(this.plants)
    },

    updatePlantAnimation: function() {
      if (this.animationState == 'FLOODING' || this.animationState == 'FLOURISHING') {
        for (let mixer of this.plantMixers) {
          mixer.update(this.dt)
        }
      }
    },
    
    /**
     * creates a "tide" using a sinusoid function to update bumpScale between
     * -0.3 and 0.1.
     */
    updateWater: function() {
      this.tide += this.dt / 2
      this.water.material.bumpScale = Math.sin(this.tide % (2 * Math.PI)) / 200 - 0.0025
      this.water.material.bumpMap.rotation = (this.water.material.bumpMap.rotation + 0.0001) % (2 * Math.PI)
    },
    
    /**
     * to move player.
     */
    getElevation: function(point) {
      const raycaster = new THREE.Raycaster()
      // vertical ray to bottom
      raycaster.set(point, new THREE.Vector3(0, 1, 0))
      let intersects = raycaster.intersectObject(this.floor)
      if (intersects.length) {
        return intersects[0].point
      }
      raycaster.set(point, new THREE.Vector3(0, -1, 0))
      intersects = raycaster.intersectObject(this.floor)
      if (intersects.length) {
        return intersects[0].point
      }
      return null
    },

  }
}
