<template>
  <div class="fullpage">
    <Home v-if="currentMenu == 'Home'" />
    <div
      v-else
      class="container"
    >
      <Content :is="menuContent" />
      <div class="bottom-menu">
        <a
          v-for="(button, idx) in buttons"
          :key="`button-link-${idx}`"
          :class="['link', { active: button == buttons[currentButton] }]"
          @click="button.action"
          >
          <img :src="button.imgSrc" class="image-title" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import Home from '@/components/menus/Home'
import AboutEn from '@/components/menus/AboutEn'
import AboutFr from '@/components/menus/AboutFr'
import Art from '@/components/menus/Art'
import CreditsEn from '@/components/menus/CreditsEn'
import CreditsFr from '@/components/menus/CreditsFr'
import InstructionsEn from '@/components/menus/InstructionsEn'
import InstructionsFr from '@/components/menus/InstructionsFr'

const IDLE_TIME = 65000

export default {
  name: 'Menu',
  components: {
    Home,
    AboutEn,
    AboutFr,
    Art,
    CreditsEn,
    CreditsFr,
    InstructionsEn,
    InstructionsFr,
  },
  watch: {
    /**
     * Sorry for this watch:
     * eventlisteners being attached on the window, if both Home and Menu
     * onKeyUp are added, there is a conflict. Consequently, we add/remove
     * Menu.onKeyUp if the active menu is Home (Home has its own onKeyUp)
     * to activate the secret screen
     */
    currentMenu(nv, ov) {
      if (nv == "Home")
        window.removeEventListener('keyup', this.onKeyUp)
      else
        window.addEventListener('keyup', this.onKeyUp)
    }
  },
  data: function () {
    return {
      intervalId: null,
      currentButton: 0,
    }
  },
  computed: {
    ...mapState({
      lang: (state) => state.lang,
      currentMenu: (state) => state.currentMenu,
    }),
    otherLang: function() {
      return (this.lang == 'fr') ? 'en' : 'fr'
    },
    buttons: function() {
      return [
        {
          action: () => this.quit(),
          imgSrc: require('@/assets/images/' + this.lang + '/' + 'menu.png'),
        },
        {
          action: () => this.$store.commit('setLang', this.otherLang),
          imgSrc: require('@/assets/images/' + this.otherLang + '.png'),
        },
      ]
    },
    menuContent: function() {
      const contents = {
        'fr': {
          'About': 'AboutFr',
          'Art': 'Art',
          'Credits': 'CreditsFr',
          'Instructions': 'InstructionsFr',
        },
        'en': {
          'About': 'AboutEn',
          'Art': 'Art',
          'Credits': 'CreditsEn',
          'Instructions': 'InstructionsEn',
        }
      }
      return contents[this.lang][this.currentMenu]
    },
  },
  mounted: function () {
    // to reset buttons after a rotation
    this.currentButton = 0
    // reroute to Home page after 10s of inactivity
    this.rerouteIfIdle()
  },
  beforeDestroy: function () {
    clearInterval(this.intervalId)
    window.removeEventListener('keyup', this.onKeyUp)
  },
  methods: {
    quit: function () {
      this.$store.commit('setMenu', 'Home')
    },
    rerouteIfIdle: function () {
      if (this.intervalId) {
        clearInterval(this.intervalId)
      }
      this.intervalId = setInterval(this.quit, IDLE_TIME)
    },
    /**
     * keyboard events handler
     */
    onKeyUp: function(event) {
      /* 'Home' has its own keyboard events handler */
      if (this.currentMenu != 'Home') {
        const kc = event.code
        /* resets idle timer */
        this.rerouteIfIdle()
        /* keys handlers */
        if (kc == 'KeyW' || kc == 'ArrowUp' || kc == 'KeyA' || kc == 'ArrowLeft') {
          this.currentButton =
            (this.currentButton - 1 + this.buttons.length) % this.buttons.length
        }
        if (kc == 'KeyS' || kc == 'ArrowDown' || kc == 'KeyD' || kc == 'ArrowRight') {
          this.currentButton = (this.currentButton + 1) % this.buttons.length
        }
        if (kc == 'Enter' || kc == 'Space') {
          this.buttons[this.currentButton].action()
        }
      }
    },
  },
}
</script>

<style scoped src="@/assets/css/menus.css" />
