import assets from './assets'

import ColumnModels from '@/assets/biomes/biome4/3D/columns.glb'
import DesertModel from '@/assets/biomes/biome4/3D/desert.glb'
import ObjectModels from '@/assets/biomes/biome4/3D/objects.glb'
import SphinxModel from '@/assets/biomes/biome4/3D/sphinx.glb'
import BiomesModel from '@/assets/biomes/biome4/3D/biomes.glb'
import FluidModel from '@/assets/biomes/biome4/3D/fluid.glb'
import PlantModels from '@/assets/biomes/biome4/3D/plants.glb'
import exrFile from '@/assets/biomes/biome3/3D/envmap.exr'
import tide from '@/assets/biomes/biome4/3D/tide.jpg'

import Bark from '@/assets/biomes/biome4/audio/dog.mp3'
import Grunt from '@/assets/biomes/biome4/audio/grunt.mp3'
import Wind from '@/assets/biomes/biome4/audio/wind.mp3'
import Zap from '@/assets/biomes/biome4/audio/zap.mp3'

import Static1 from '@/assets/biomes/biome4/audio/static1.mp3'
import Static2 from '@/assets/biomes/biome4/audio/static2.mp3'

import Music1 from '@/assets/biomes/biome4/audio/chorale_1.mp3'
import Music2 from '@/assets/biomes/biome4/audio/chorale_2.mp3'
import Music3 from '@/assets/biomes/biome4/audio/chorale_3.mp3'
import Music4 from '@/assets/biomes/biome4/audio/chorale_4.mp3'
import Music5 from '@/assets/biomes/biome4/audio/jazz_1.mp3'
import Music6 from '@/assets/biomes/biome4/audio/jazz_2.mp3'
import Music7 from '@/assets/biomes/biome4/audio/jazz_3.mp3'

export default {
  namespaced: true,
  state: {
    ...assets.state,
    name: 'biome4',
    // textures and maps
    tide: null,
    env: null,
    // glb 3D models
    columns: null,
    objects: null,
    desert: null,
    sphinx: null,
    // audio
    bark: null,
    wind: null,
    grunt: null,
    statics: [],
    musics: [],
  },
  
  mutations: {
    ...assets.mutations,
    /* Special biome 4 asset setters */
    setStatic: function(state, val) {
      state.statics.push(val)
    },
    setMusic: function(state, val) {
      state.musics.push(val)
    },

    // setContainers: function (state, val) {
    //   state.containers = [...val.scene.children.map((c) => c.clone())]
    // },
  },

  actions: {
    ...assets.actions,
    /* BIOME 4 ASSETS PRELOADING */
    preload: async function({dispatch, state}) {
      return Promise.all([
        dispatch('preloadAsset', [state.TL, tide, 'tide']),
        dispatch('preloadAsset', [state.EL, exrFile, 'env']),

        dispatch('preloadAsset', [state.GL, ColumnModels, 'columns']),
        dispatch('preloadAsset', [state.GL, DesertModel, 'desert']),
        dispatch('preloadAsset', [state.GL, ObjectModels, 'objects']),
        dispatch('preloadAsset', [state.GL, SphinxModel, 'sphinx']),
        dispatch('preloadAsset', [state.GL, BiomesModel, 'biomes']),
        dispatch('preloadAsset', [state.GL, FluidModel, 'fluid']),
        dispatch('preloadAsset', [state.GL, PlantModels, 'plants']),
        
        dispatch('preloadAsset', [state.AL, Bark, 'bark']),
        dispatch('preloadAsset', [state.AL, Grunt, 'grunt']),
        dispatch('preloadAsset', [state.AL, Wind, 'wind']),
        dispatch('preloadAsset', [state.AL, Zap, 'zap']),
        dispatch('preloadAsset', [state.AL, Static1, 'statics', 'setStatic']),
        dispatch('preloadAsset', [state.AL, Static2, 'statics', 'setStatic']),

        dispatch('preloadAsset', [state.AL, Music1, 'musics', 'setMusic']),
        dispatch('preloadAsset', [state.AL, Music2, 'musics', 'setMusic']),
        dispatch('preloadAsset', [state.AL, Music3, 'musics', 'setMusic']),
        dispatch('preloadAsset', [state.AL, Music4, 'musics', 'setMusic']),
        dispatch('preloadAsset', [state.AL, Music5, 'musics', 'setMusic']),
        dispatch('preloadAsset', [state.AL, Music6, 'musics', 'setMusic']),
        dispatch('preloadAsset', [state.AL, Music7, 'musics', 'setMusic']),

      ])
    }
  }
}
