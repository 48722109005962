<template>
  <div>
    <!-- <h1> -->
    <!--   <img -->
    <!--     src="@/assets/images/fr/tendersound.png" -->
    <!--     class="image-title" -->
    <!--   /> -->
    <!-- </h1> -->
    <div class="text pause">
      <img src="@/assets/biomes/biome1/instructions.png">
      <h1>Faites du bruit !</h1>
      <p>
        Vérifiez que le micro de votre ordinateur est allumé.<br>
        À cette étape du jeu, l'interaction est acoustique.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TenderSoundFr'
}
</script>

<style scoped src="@/assets/css/menus.css" />
