<template>
  <div>
    <div v-if="debug" v-show="showControls">
      <div id="stats-container" />
      <div class="mic-control">
        <label>Mic threshold (higher value = less sensitive)</label>
        <input
          v-model="MIC_VOL_THRESHOLD"
          type="range"
          min="1"
          max="50"
          step="0.1"
        />
        {{ MIC_VOL_THRESHOLD }}
      </div>
      <div class="recorder">
        <div :class="['record-state', { on: isRecordingMic }]" />
        <button @click="$store.commit('setLevel', 2)">
          NEXT BIOME
        </button>
      </div>
    </div>
    <audio id="audio-player" controls />
    <canvas />
  </div>
</template>

<script>
/* VUE imports */
import { mapState } from 'vuex'

/* THREEJS and related modules */
import * as THREE from 'three'
import Stats from 'three/examples/jsm/libs/stats.module'

/* get somes constants */
import constants from '@/components/biomes/biome1/constants.js'

/* mixins for scene, camera and sound */
import { pauseMixin } from'@/components/biomes/pause.js'

import { audioMixin } from '@/components/biomes/biome1/audio.js'
import { cameraMixin } from '@/components/biomes/biome1/camera.js'
import { sceneMixin } from '@/components/biomes/biome1/scene.js'

export default {
  name: 'Biome1',
  mixins: [ audioMixin, cameraMixin, pauseMixin, sceneMixin ],
  data: function () {
    return {
      /* constant namespacing */
      MIC_VOL_THRESHOLD: constants.DEFAULT_MIC_VOL_THRESHOLD,
      
      /* Main DOM, canvas and scene */
      screenSize: window.innerWidth,
      
      /* quiet */
      quietAnimationIndex: 0.0,

      /* animation */
      biomeStart: 0,
      now: 0,
      biomeState: constants.BIOME_STATES[0],

      animationRequestId: null,
      animationStart: 0,
      animationEnd: 0,
      animationFactor: 1,
      biomeCompleted: false,
      
      /* Development tools */
      controls: {},
      stats: null,
      showControls: false,
    }
  },
  computed: {
    debug: () => process.env.NODE_ENV == 'development',
    ...mapState({
      audioCtx: (state) => state.audioCtx,
      assets: (state) => state.biome1,
    }),
  },
  watch: {
    MIC_VOL_THRESHOLD: function (nv, ov) {
      localStorage.setItem('micsensibility', nv)
    },
  },
  mounted: async function () {
    console.debug('BIOME 1 MOUNTED')
    let c = localStorage.getItem('micsensibility')
    if (c) {
      this.MIC_VOL_THRESHOLD = Number(c)
    }
    console.debug('LOCAL MIC SENSITIVITY', c)

    /* 3D initializations */
    this.initScene()
    this.initEnvironment()
    this.initCamera()
    this.initCameraAnimations()
    this.initPostProcessing()
    /* random dome/water initial rotation */
    const theta = Math.random() * 2 * Math.PI
    this.createSkyDome(theta)
    this.createOcean(theta)

    /* event handler */
    this.onResize()
    window.addEventListener('resize', this.onResize)
    if (this.debug) {
      window.addEventListener('keypress', this.onKeyPress)
      this.stats = new Stats()
      this.$el.querySelector('#stats-container').append(this.stats.dom)
    }

    /* sound initialization and biome start */
    await this.initAudio().then(() => {
      this.renderEnvironment()
      this.startIntroAnimation()
      this.animate()
    })
  },

  created: function () {
    console.debug('BIOME 1 CREATED')
    this.biomeStart = performance.now()
  },
  beforeDestroy: async function () {
    console.debug('BIOME 1 DESTROY')
    cancelAnimationFrame(this.animationRequestId)
    this.audioCleanUp()
    // this.freeSound()
    // this.freeCamera()
    // await this.cameraListener.context.close()
    // if (process.env.NODE_ENV == 'development') {
    //   location.reload()
    // }
  },
  methods: {
    startIntroAnimation: function () {
      this.$store.commit('setAnimating', true)
      this.animationStart = this.now
      this.animationEnd = this.animationStart + constants.INTRO_DURATION
      this.biomeState = 'INTRO'
      this.$store.commit('setHidden', false)
    },
    
    /**
     * CORE FUNCTION
     * handles pause, current state (game step), and consequently
     * camera, sound recording, and animations
     */
    animate: function () {
      this.animationRequestId = requestAnimationFrame(this.animate)
      // do not render or make any computation if biome completed
      if (this.biomeCompleted) {
        this.$store.commit('setHidden', true)
        cancelAnimationFrame(this.animationRequestId)
      }
      
      /* updates general timer */
      this.now = performance.now() - this.pauseDuration - this.biomeStart

      /* waits end of intro to play intro sound */
      if (this.biomeState == 'INTRO') {
        if (this.now >= this.animationEnd) {
          this.nextState()
        }
      }
      
      /* triggers intro sound and go to next step */
      if (this.biomeState == 'PLAY_INTRO_SOUND') {
        console.log("SHOULD PLAY")
        this.introSound.play()
        this.nextState()        
      }

      if (this.biomeState == "LISTENING") {
        this.$store.commit('setAnimating', false)
        this.analyzeIntroSound()
        this.analyzeMicSound()
        if (this.micActivityDuration >= constants.LISTENING_DURATION) {
          this.micActivityDuration = 0
          this.nextState()
        }
      }

      if (this.biomeState == "RECORDING") {
        /* recording handler */
        this.analyzeMicSound()
        this.updateRecordingState()
        if (this.recordingComplete) {
          this.nextState()
        }
      }

      if (this.biomeState == 'OUTRO' && this.biomeCompleted == false) {
        console.debug('BIOME 1 ENDED -- going to level 2')
        this.biomeCompleted = true
        this.outroSound.onEnded = () => {
          this.$store.commit('setLevel', 2)
        }
        this.outroSound.play()
      }

      /* handles smooth rotation of environment */
      this.waterGroup.rotation.y += 0.0005
      this.skyDome.rotation.y += 0.0005
      
      /* animations/interactions */
      this.moveCamera()
      this.animateHole()
      this.render()
      
      if (this.stats) this.stats.update()
    },

    /**
     * goes to the next state of the biome/level
     */
    nextState: function() {
      this.biomeState = constants.BIOME_STATES[constants.BIOME_STATES.indexOf(this.biomeState) + 1]
      console.debug('Switching state to', this.biomeState)
    },

    /**
     * callback to resize 3D world when the browser is resized
     */
    onResize: function () {
      // console.debug("resize !", this.sceneContainer.clientWidth, this.sceneContainer.clientHeight)
      this.mainCamera.aspect =
        this.sceneContainer.clientWidth / this.sceneContainer.clientHeight
      this.mainCamera.updateProjectionMatrix()
      // this.renderer.setPixelRatio(window.devicePixelRatio)
      if (this.composer)
        this.composer.setSize(
          this.sceneContainer.clientWidth,
          this.sceneContainer.clientHeight
        )
      this.renderer.setSize(
        this.sceneContainer.clientWidth,
        this.sceneContainer.clientHeight
      )
    },

    /**
     * callback to display debugging tool. Available only in development mode
     */
    onKeyPress: function(event) {
      if (event.code == 'KeyV') {
        this.showControls = !this.showControls
      }
    },
  },
}
</script>

<style scoped>
/* DEBUG RECORDER VIEW */
.mic-control {
  position: fixed;
  right: 0px;
  top: 0px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 300px;
  padding: 8px;
  color: #fff;
  font-size: 14px;
  background-color: #00000060;
}
.mic-control label {
  font-style: italic;
  text-align: center;
  flex-basis: 100%;
}
.mic-control input {
  width: 250px;
}

.recorder {
  position: fixed;
  bottom: 0px;
  right: 0px;
  height: 100px;
  width: 500px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #00000060;
}

.record-state {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: #444444;
  transition: 300ms linear;
}
.record-state.on {
  background-color: #ff0000;
}

.recorder audio {
  opacity: .7;
}

.recorder button {
  padding: 5px;
  border: 1px solid #000;
  opacity: .7;
}
</style>
