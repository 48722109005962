/**
 * builds the bricks and the cloud for the animation
 */
import * as THREE from 'three'

import cloudVertexShader from '@/shaders/biome3/cloud-vx.glsl'
import cloudFragmentShader from '@/shaders/biome3/cloud-frag.glsl'

import constants from '@/components/biomes/biome3/constants.js'
import { ImprovedNoise } from 'three/addons/math/ImprovedNoise.js'

export const brickMixin = {
  data: function() {
    return {
      inventory: null,
      newBrick: false,
      ownedBricks: [],
    }
  },
  methods: {
    /**
     * resizes inventory when camera moves
     */
    resizeInventory: function() {
      const distance = new THREE.Vector3(0, 0, 0).distanceTo(this.inventory.position)
      const fov = (this.mainCamera.fov * Math.PI) / 180 // convert vertical fov to radians
      const h = 2 * Math.tan(fov / 2) * distance // visible height
      const w = h * (this.renderWidth / this.renderHeight)
      this.inventory.width = w
      this.inventory.geometry.dispose()
      this.inventory.geometry = new THREE.BoxGeometry(w, 1, 0)
    },
    /**
     * creates inventory when biome is mounted
     * the inventory is a simple plane in front of the camera
     * the gathered bricks are stored in the inventory
     */
    createInventory: async function() {
      const geo = new THREE.BoxGeometry(1, 1, 0)
      const mat = new THREE.MeshBasicMaterial(
        {
          visible: true,
          map: this.assets.inventory
        }
      )
      this.inventory = new THREE.Mesh(geo, mat)
      this.inventory.position.setZ(-1.5)
      this.inventory.position.setY(-.9)
      this.mainCamera.add(this.inventory)
    },

    /**
     * creates a new brick
     * A new brick is created when a character talks to the player
     */
    createBrick: async function() {
      let newBrick = Object.create(this.assets.brick.scene.children[0].clone())
      newBrick.position.set(0, constants.BRICK_START_POSITION, .6)
      //newBrick.rotation.y = 0.3 + Math.random() * 0.1
      newBrick.scale.setScalar(.0002)
      newBrick.material = newBrick.material.clone()
      newBrick.material.transparent = true
      newBrick.material.opacity = constants.BRICK_START_OPACITY
      newBrick.material.envMap = this.environmentRenderTarget.texture

      this.newBrick = newBrick
      this.inventory.add(this.newBrick)
    },

    /**
     * creates a cloud
     * A new brick appears within the cloud
     * The cloud is created only once and used each time a brick is created
     */
    createCloud: async function() {
      // Texture
      const size = 128
      const data = new Uint8Array(size * size * size)
      
      const scale = 0.05
      const perlin = new ImprovedNoise()
      const vector = new THREE.Vector3()
      
      let i = 0
      for (let z = 0; z < size; z++) {
      	for (let y = 0; y < size; y++) {
          for (let x = 0; x < size; x++) {
            const d = 1.0 - vector.set(x, y, z)
                  .subScalar(size / 2)
                  .divideScalar( size ).length()
            data[i] = (128 + 128 * perlin.noise(x * scale / 1.5, y * scale, z * scale / 1.5)) * d * d
            i++
          }
        }
      }
      
      const texture = new THREE.Data3DTexture(data, size, size, size)
      texture.format = THREE.RedFormat
      texture.minFilter = THREE.LinearFilter
      texture.magFilter = THREE.LinearFilter
      texture.unpackAlignment = 1
      texture.needsUpdate = true

      const vertexShader = /* glsl */cloudVertexShader
      const fragmentShader = /* glsl */cloudFragmentShader
      const material = new THREE.RawShaderMaterial({
        glslVersion: THREE.GLSL3,
        uniforms: {
          base: { value: new THREE.Color(0x338aa0) },
          map: { value: texture },
          cameraPos: { value: new THREE.Vector3() },
          threshold: { value: constants.CLOUD_START_THRESHOLD },
          opacity: { value: 0 },
          range: { value: 0.1 },
          steps: { value: 50 },
          frame: { value: 0 }
        },
        vertexShader,
        fragmentShader,
        side: THREE.BackSide,
        transparent: true
      } )


      const cloudGeo = new THREE.BoxGeometry(1, 1, 1)
      this.cloud = new THREE.Mesh(cloudGeo, material)
      this.cloud.position.setY(1)
      this.cloud.position.setZ(0.6)
      this.inventory.add(this.cloud)
    },

    /**
     * start a brick animation
     */
    startBrickAnimation: function() {
      this.cloud.position.copy(this.newBrick.position)
      this.cloud.rotation.y = Math.random() * 2 * Math.PI
      this.cloud.material.uniforms.cameraPos.value.copy(this.mainCamera.position)
      this.cloud.material.uniforms.opacity.value = 0
      this.cloud.material.uniforms.threshold.value = constants.CLOUD_START_THRESHOLD
      this.cloud.material.visible = true
      this.inventory.add(this.cloud)
    },

    /**
     * updates a brick animation, that is makes it appear within a cloud
     */
    updateBrickAnimation: function(animT) {
      // from 0 to 0.2, cloud appears
      // from 0.2 to .8, brick appears and cloud shrinks
      // from 0.8 to 1, brick moves to inventory
      if (animT < 0.2) {
        this.cloud.material.uniforms.opacity.value = animT / 2
      }
      else if (animT <= 0.8) {
        const scaledAnimT = 1.66 * (animT - 0.2)
        const opacityT = scaledAnimT
              * (constants.BRICK_END_OPACITY - constants.BRICK_START_OPACITY)
              + constants.BRICK_START_OPACITY
        const thresholdT = scaledAnimT
              * (constants.CLOUD_END_THRESHOLD - constants.CLOUD_START_THRESHOLD)
              + constants.CLOUD_START_THRESHOLD
        
        this.newBrick.material.opacity = opacityT
        this.cloud.material.uniforms.threshold.value = thresholdT
      } else {
        //this.cloud.material.visible = false
        const scaledAnimT = Math.min(1, 5 * (animT - 0.8))
        const posYT = scaledAnimT
              * (constants.BRICK_END_POSITION - constants.BRICK_START_POSITION)
              + constants.BRICK_START_POSITION
        this.newBrick.position.setY(posYT)

        const startXT = 0
        const endXT = -this.inventory.width / 4
              + this.inventory.width / 14 * (constants.CHARACTERS.indexOf(this.nearestCharacter.name) + .5)
        const posXT = scaledAnimT * (endXT - startXT) + startXT 
        this.newBrick.position.setX(posXT)
      }
    },
  }
}
