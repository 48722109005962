<template>
  <div>
    <div class="text pause">
      <img src="@/assets/biomes/biome4/instructions.png">
      <h1>Bringing the idea down off its pedestal</h1>
      <p v-if="appVersion == 'web'">
        Use <img class="keys-2" src="@/assets/images/arrows.png"> or <img class="keys-2" src="@/assets/images/WASD.png"> to move around,<br>
        <img class="keys-1" src="@/assets/images/entree.png"> to pick up and put down objects,<br>
        <img class="keys-1" src="@/assets/images/space.png"> to interact with objects,
        <img class="keys-1" src="@/assets/images/p.png"> to pause.
      </p>
      <p v-else>
        Use <img class="keys-1" src="@/assets/images/cross.png"> to move around,<br>
        <img class="keys-1" src="@/assets/images/button_A.png"> to pick up and put down objects,<br>
        <img class="keys-1" src="@/assets/images/button_B.png"> to interact with objects,<br>
        <img class="keys-1" src="@/assets/images/button_C.png"> to pause.
      </p>
      
    </div>
  </div>
</template>

<script>
export default {
  name: 'Biome4En',
  computed: {
    appVersion: () => process.env.VUE_APP_VERSION,
  }
}
</script>

<style scoped src="@/assets/css/menus.css" />
