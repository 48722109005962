/**
 * builds the containers and their interactions
 */
import * as THREE from 'three'
import * as Tone from 'tone'
import dropletsVertexShader from '@/shaders/biome2/droplets-vx.glsl'

import constants from '@/components/biomes/biome2/constants.js'

export const containerMixin = {
  data: function() {
    return {
      /* containers */
      containers: new THREE.Group(),
      nearestContainer: null,
    }
  },
  methods: {
    /**
     * Finds a free spot for containers at level init
     */
    placeContainer: function(container) {
      container.position.copy(this.findAvailablePosition())

      // rotate and resize
      container.rotation.set(0, 2 * Math.PI * Math.random(), 0)
      container.geometry.computeBoundingBox()
      let bb = container.geometry.boundingBox
      container.scale.setScalar(3 / (bb.max.x - bb.min.x))
    },

    /**
     * Containers in 'containers.glb' share materials 
     * (that is good to save space).
     * But, for interactions, we want to have distinct material
     * for each object (emissivity changes when getting close)
     */
    createDistinctMaterial: function(container) {
      if (container.name.includes('WineGlass')) {
        const glassMat = new THREE.MeshPhysicalMaterial({
          transmission: 0.8,
          roughness: 0,
          clearcoat: 0.8,
          ior: 1.4,
          side: THREE.DoubleSide,
        })
        container.material = glassMat
      } else {
        container.material = container.material.clone()
      }
      // for interaction
      container.initialColor = new THREE.Color(1, .5, .5).getHex()
      container.material.emissive.setHex(container.initialColor)
      container.material.emissiveIntensity = 0.0
    },

    /**
     * Containers creation and init
     */
    createContainers: async function () {
      for (let container of this.assets.containers) {
        /* container position */
        this.placeContainer(container)
        this.createDistinctMaterial(container)
        this.containers.add(container)
        
        /* container properties for interactivity */
        container.levitationPhase = Math.random() * Math.PI
      }
      this.scene.add(this.containers)
    },
    
    /**
     * Containers interaction update
     * handles containers interaction hint by illuminating the nearest container.
     */
    findNearestContainer: function() {
      // do not highlight containers if player is carrying one
      if (this.player.containerHandle.children.length) return null
      
      this.nearestContainer = null
      let nearestDistance = 60 // cave's length
      
      for (const container of this.containers.children) {
        container.material.emissiveIntensity = 0.0
        const distance = container.position.distanceTo(this.player.position)
        if (distance <= constants.INTERACTION_DISTANCE &&
            distance < nearestDistance &&
            this.isInSight(container)
           ) {
          nearestDistance = distance
          this.nearestContainer = container
        }
      }
      
      if (this.nearestContainer) {
        this.nearestContainer.material.emissiveIntensity = constants.HINT_EMISSIVITY
      }
    },
    
    // attachContainerToWaterfall: function (container, waterfall) {
      
    //   /* container animation */
    //   /* audio fx */
    //   this.winningFx.firstPlay = true
    //   this.waterfallFx.firstPlay = true
    // },
  }
}
