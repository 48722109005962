export default {
  // defines gameplay states
  BIOME_STATES: [
    'INTRO',
    'PLAYING',
    'OUTRO',
  ],

  INTRO_KEYFRAMES: [0, 4, 5],
  INTRO_TOTAL_DURATION: 5,

  // equalizer
  GHETTO_VOLUME: .5,
  WIND_VOLUME: 1,
  BARK_VOLUME: .7,
  GRUNT_VOLUME: .3,
  ZAP_VOLUME: 1,
  
  // player
  MOVE_AXIAL_SPEEDFACTOR: -0.002,
  MOVE_GYRO_SPEEDFACTOR: 0.05,

  AVATAR_INITS : [
    {'scale': 0.002, 'rotation': [Math.PI / 2, 0, -Math.PI]},
    {'scale': 0.136674112 / 10500, 'rotation': [Math.PI / 2, 0, -Math.PI]},
  ],
  
  // interactivity
  INTERACTION_DISTANCE: .1,
  GHETTO_INIT: {
    'scale': 0.000015,
    'position': [-0.8271187543869019, 0.2111535519361496, 1.0295448303222656]
  },

  QUIT_TIME: 30 * 1000, // 30 s

  PLANT_ANIMATIONS: {
    "Plant_1": {"y": [0, 0.099968 + 0.0423, 0.153206 + 0.0423], "scale": [0.253271, 0.253271, 2.17796]},
    "Plant_2": {"y": [0, 0.07716 + 0.065108, 0.130398 + 0.065108], "scale": [0.00023, 0.00023, 0.001981]},
    "Plant_3": {"y": [0, 0.03342 + 0.108849, 0.086658 + 0.108849], "scale": [0.001583, 0.001583, 0.013612]},
    "Plant_4": {"y": [0, 0.066079 + 0.076189, 0.119317 + 0.076189], "scale": [0.001716, 0.001716, 0.014755]}
  },

  PLANT_INSTANCES: [
    // 1
    {"name":"Plant_1.001", "translation":[0.49978116154670715, -0.042299896478652954, 0.45280778408050537]}, 
    {"name":"Plant_1.002", "translation":[-0.04976414516568184, -0.012562066316604614, 0.48758184909820557]}, 
    {"name":"Plant_1.003", "translation":[-0.41590389609336853, -0.0045228153467178345, 0.32363077998161316]}, 
    {"name":"Plant_1.004", "translation":[-0.4529591202735901, -0.04532618075609207, 0.1464349627494812]}, 
    {"name":"Plant_1.005", "translation":[-0.6396195888519287, 0.05445374548435211, 0.32802292704582214]}, 
    {"name":"Plant_1.006", "translation":[-0.8665911555290222, -0.01680399477481842, -0.16830343008041382]}, 
    {"name":"Plant_1.007", "translation":[-0.6036732792854309, -0.05717770755290985, -0.37424173951148987]}, 
    {"name":"Plant_1.008", "translation":[-0.6809419989585876, -0.05488172173500061, -0.4658576250076294]}, 
    {"name":"Plant_1.009", "translation":[-0.5832040309906006, -0.06163262575864792, -0.5958521366119385]}, 
    {"name":"Plant_1.010", "translation":[-0.31018760800361633, -0.06116734445095062, -0.6776023507118225]}, 
    // 2
    {"name":"Plant_2", "translation":[-0.7338007092475891, -0.06510814279317856, -0.3030710220336914]}, 
    {"name":"Plant_2.001", "translation":[0.7215422987937927, 0.0040916502475738525, 0.47238755226135254]}, 
    {"name":"Plant_2.002", "translation":[0.04051250219345093, -0.08117173612117767, 0.562031090259552]}, 
    {"name":"Plant_2.003", "translation":[-0.32223111391067505, -0.07917378097772598, 0.206871896982193]}, 
    // 3
    {"name":"Plant_3", "translation":[-0.08778659999370575, -0.10884860157966614, 0.5688167810440063]}, 
    {"name":"Plant_3.001", "translation":[0.6466386318206787, -0.06464997678995132, 0.3058737814426422]}, 
    {"name":"Plant_3.002", "translation":[0.4490818381309509, -0.053683049976825714, 0.5219278931617737]}, 
    {"name":"Plant_3.003", "translation":[0.36380940675735474, -0.08076280355453491, 0.5582258105278015]}, 
    {"name":"Plant_3.004", "translation":[0.12080661952495575, -0.07102318108081818, 0.7420011758804321]}, 
    {"name":"Plant_3.005", "translation":[-0.0796196311712265, -0.0470704585313797, 0.559608519077301]}, 
    {"name":"Plant_3.006", "translation":[-0.08916941285133362, -0.07983925938606262, 0.3732938766479492]}, 
    {"name":"Plant_3.007", "translation":[-0.2272116094827652, -0.05710926651954651, 0.2705768346786499]}, 
    {"name":"Plant_3.008", "translation":[-0.5551800727844238, -0.052435919642448425, 0.16083842515945435]}, 
    {"name":"Plant_3.009", "translation":[-0.6729837656021118, -0.07052691280841827, 0.18046754598617554]}, 
    {"name":"Plant_3.010", "translation":[-0.7404928803443909, -0.031324610114097595, 0.2792994976043701]}, 
    {"name":"Plant_3.011", "translation":[-0.8923732042312622, -0.052417851984500885, -0.02941519021987915]}, 
    {"name":"Plant_3.012", "translation":[-0.7327095866203308, -0.0784277617931366, -0.17158091068267822]}, 
    {"name":"Plant_3.013", "translation":[-0.8360931277275085, -0.07714126259088516, -0.08450615406036377]}, 
    {"name":"Plant_3.014", "translation":[-0.8408799171447754, -0.03917321562767029, -0.14065635204315186]}, 
    {"name":"Plant_3.015", "translation":[-0.6433359384536743, -0.06627663969993591, -0.2538323998451233]}, 
    {"name":"Plant_3.016", "translation":[-0.5940583348274231, -0.07490561902523041, -0.35821378231048584]}, 
    // 4
    {"name":"Plant_4.001", "translation":[0.5484840869903564, -0.07618942856788635, 0.4089887738227844]}, 
    {"name":"Plant_4.002", "translation":[0.39591342210769653, -0.0710221379995346, 0.5453565120697021]}, 
    {"name":"Plant_4.003", "translation":[-0.0075643062591552734, -0.07788757234811783, 0.38675734400749207]}, 
    {"name":"Plant_4.004", "translation":[-0.1693500429391861, -0.05038030445575714, 0.30610179901123047]}, 
    {"name":"Plant_4.005", "translation":[-0.19860267639160156, -0.047287240624427795, 0.4129692614078522]}, 
    {"name":"Plant_4.006", "translation":[-0.625129759311676, -0.06032582372426987, 0.20106299221515656]}, 
    {"name":"Plant_4.007", "translation":[-0.7674610614776611, -0.07510928809642792, -0.11581644415855408]}, 
    {"name":"Plant_4.008", "translation":[-0.8570138812065125, 0.0020897239446640015, -0.4199883043766022]}, 
    {"name":"Plant_4.009", "translation":[-0.5817665457725525, -0.07117626070976257, -0.45121803879737854]}, 
    {"name":"Plant_4.010", "translation":[-0.5233752727508545, -0.0716795027256012, -0.5575571060180664]}, 
    {"name":"Plant_4.011", "translation":[-0.31613531708717346, -0.07749847322702408, -0.6226317882537842]}, 
    {"name":"Plant_4.012", "translation":[-0.24201339483261108, -0.0824640691280365, -0.68601393699646]}, 
    {"name":"Plant_4.013", "translation":[-0.19217780232429504, -0.08962099999189377, -0.7938388586044312]}, 
    {"name":"Plant_4.014", "translation":[-0.6194840669631958, -0.08323097229003906, -0.503482460975647]}
  ]  
}
