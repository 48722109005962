import * as THREE from 'three'

export const sceneMixin = {
  data: function() {
    return {
      /* Main scene */
      canvas: {},
      sceneContainer: {},
      scene: {},
      sceneCenter: new THREE.Vector3(0, 0, 0),
      renderWidth: 0,
      renderHeight: 0,
      renderer: {},
      mixer: {},
      /* env */
      environmentRenderTarget: {},
      environmentCubeCamera: {},
    }
  },
  methods: {
    freeScene: function() {
      window.removeEventListener('resize', this.onResize)
      window.removeEventListener('pointermove', this.onPointerMove)
      window.removeEventListener('keypress', this.onKeyPress)
      window.removeEventListener('keydown', this.onKeyDown)
      window.removeEventListener('keyup', this.onKeyUp)
      window.removeEventListener('click', this.onMouseClick)
    },

    initScene: function () {
      /* THREEJS 3D CANVAS AND RENDERER */
      this.sceneContainer = document.getElementById('biome')
      this.canvas = this.sceneContainer.querySelector('canvas')
      this.renderWidth = this.sceneContainer.clientWidth
      this.renderHeight = this.sceneContainer.clientHeight

      /* SCENE initialisation */
      this.scene = new THREE.Scene()
      // this.scene.add(this.axesHelper)

      /* RENDERER */
      this.renderer = new THREE.WebGLRenderer({
        antialias: true,
        canvas: this.canvas,
        alpha: true
      })
      this.renderer.toneMappingExposure = 1
      this.renderer.toneMapping = THREE.ACESFilmicToneMapping

      const sceneRatio = this.renderWidth / this.renderHeight
      this.mainCamera = new THREE.PerspectiveCamera(50, sceneRatio, .1, 5000)
      this.mainCamera.position.set(0, 300, 1000)
      this.mainCamera.lookAt(new THREE.Vector3(0, 10, 0))
      this.scene.add(this.mainCamera)
      
      const ambiant = new THREE.AmbientLight(0xffffff, .5)
      this.scene.add(ambiant)
      const sun = new THREE.DirectionalLight(0xffffff, 3)
      sun.position.set(500, 300, 500)
      sun.target.position.set(0, 0, 0)
      this.scene.add(sun)

      // const helper = new THREE.DirectionalLightHelper(sun);
      // this.scene.add(helper);
      // this.scene.add(new THREE.CameraHelper(sun.shadow.camera))

      this.scene.background = this.assets.background
      this.scene.background.colorSpace = THREE.SRGBColorSpace
      // })
    },

    createEnvironment: function() {
      /* general environment */
      this.environmentRenderTarget = new THREE.WebGLCubeRenderTarget(128, {
        format: THREE.RGBAFormat,
        encoding: THREE.sRGBEncoding,
        generateMipmaps: true,
      })
      this.environmentRenderTarget.texture.type = THREE.HalfFloatType
      this.environmentCubeCamera = new THREE.CubeCamera(
        1,
        50,
        this.environmentRenderTarget
      )
      this.environmentCubeCamera.position.set(50, 50, 50)
    },
    
    setEnvironmentalMap: function() {
      this.scene.environment = this.assets.env
      this.scene.environment.mapping = THREE.EquirectangularReflectionMapping
    },
    
    /**
     * Scene renderers update
     */
    render: function() {
      this.environmentCubeCamera.update(this.renderer, this.scene)
      for (const mixer of this.mixers) {
        mixer.update(this.dt)
      }
      this.renderer.render(this.scene, this.mainCamera)
    },
  }
}
