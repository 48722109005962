import * as THREE from 'three'
import * as Tone from 'tone'
import { Midi } from '@tonejs/midi'
import { PositionalAudioHelper } from 'three/examples/jsm/helpers/PositionalAudioHelper'

import constants from '@/components/biomes/biome4/constants.js'

export const audioMixin = {
  data: function() {
    return {
      dogBarkAudio: null,
      windAudio: null,
      ghettoBlasterAudio: null,
      tuned: true,
    }
  },
  methods: {
    /**
     * Audio initialisation:
     * - get, start, or resume audio context
     * - set Tone and audio listener to follow player
     * - init ambiant music
     */
    initAudio: function() {
      this.audioCtx.close()
      if (!this.audioCtx || this.audioCtx.state == 'closed') {
        console.debug('no available audiocontext, starting one')
        const audioCtx = new AudioContext()
        this.$store.commit('setAudioCtx', audioCtx)
      }
      if (this.audioCtx.state == 'suspended') {
        console.debug('Resuming available audioctx')
        this.audioCtx.resume()
      }
      THREE.AudioContext.setContext(this.audioCtx)

      /* audio listener init */
      this.player.listener = new THREE.AudioListener()
      this.player.add(this.player.listener)
      
      this.initWind()
      this.initObjectAudio()
      this.initPlayerAudio()
    },

    initWind: function() {
      this.windAudio = new THREE.Audio(this.player.listener)
      this.windAudio.setBuffer(this.assets.wind)
      this.windAudio.setVolume(constants.WIND_VOLUME)
      this.windAudio.setLoop(true)
      this.windAudio.play()
    },

    initPlayerAudio: function() {
      // prepare sound
      this.player.grunt = new THREE.Audio(this.player.listener)
      this.player.grunt.setBuffer(this.assets.grunt)
      this.player.grunt.setVolume(constants.GRUNT_VOLUME)
      this.player.zap = new THREE.Audio(this.player.listener)
      this.player.zap.setBuffer(this.assets.zap)
      this.player.zap.setVolume(constants.ZAP_VOLUME)
    },

    /**
     * inits water fallwater sound effects
     * loads sound and sets loop
     * adds positional audio players to change sound depending
     * on player position
     */
    initObjectAudio: function() {
      this.dogBarkAudio = new THREE.PositionalAudio(this.player.listener)
      this.dogBarkAudio.setBuffer(this.assets.bark)
      this.dogBarkAudio.setRefDistance(.1)
      this.dogBarkAudio.setVolume(constants.BARK_VOLUME)
      this.dog.add(this.dogBarkAudio)
      
      /* visual helper */
      // const helper = new PositionalAudioHelper(this.dogBarkAudio)
      // this.dog.add(helper)
      // console.log('REFDISTANCE', waterfallSound.getRefDistance())
      // console.log('ROLLOFF', waterfallSound.getRolloffFactor())
      //console.log(waterfallSound.panner)
      // }

      this.ghettoBlasterAudio = new THREE.Audio(this.player.listener)
      this.ghettoBlasterAudio.onEnded = () => {
        this.tuned = !this.tuned

        let track = this.assets.tracks[
          Math.floor(Math.random() * this.assets.tracks.length)
        ]
        if (!this.tuned) {
          track = this.assets.statics[
            Math.floor(Math.random() * this.assets.statics.length)
          ]
        }
        this.ghettoBlasterAudio.stop()
        this.ghettoBlasterAudio.setBuffer(track)
        this.ghettoBlasterAudio.play()
      }


      // starts with a random audio buffer
      this.ghettoBlasterAudio.setBuffer(
        this.assets.tracks[
          Math.floor(Math.random() * this.assets.tracks.length)
        ]
      )
      this.updateGhettoBlasterVolume()
      this.ghettoBlasterAudio.setLoop(false)
      this.ghettoBlasterAudio.play()
      this.ghetto.add(this.ghettoBlasterAudio)
    },

    updateGhettoBlasterVolume: function() {
      if (this.player.hasGhetto) {
        this.ghettoBlasterAudio.setVolume(constants.GHETTO_VOLUME)
      } else {
        const distance = Math.sqrt(
          Math.pow(this.player.position.x - this.ghetto.position.x, 2) +
            Math.pow(this.player.position.z - this.ghetto.position.z, 2)
        )
        this.ghettoBlasterAudio.setVolume(
          Math.max(0, (1.3 - distance) / 1.3 * constants.GHETTO_VOLUME)
        )
      }
    },
    
    audioCleanUp: function() {
      if (this.audioCtx.state != 'closed') this.audioCtx.close()
    }
  }
}
