import * as THREE from 'three'

import constants from '@/components/biomes/biome1/constants.js'

export const audioMixin = {
  data: function() {
    return {
      /* sound */
      userMediaStream: {},
      micReady: false,
      micSource: {},
      micAnalyzer: {},
      micVolumeAnalyzer: {},
      micVolume: 0,
      introSound: null,
      introVolume: 0,
      outroSound: null,

      /* microphone */
      micActive: false,
      micActivityDuration: 0,
      micLastActive: 0,
      micInactiveStartProgress: 0,
      micLastUpdate: 0,

      /* recording management */
      micRecorder: {},
      micBlobs: {},
      recordingMicStartTick: 0,
      recordingLength: 0,
      isRecordingMic: false,
      recordingProgress: 0,
      recordingComplete: false,
    }
  },
  methods: {
    /**
     * Loads audio files from store
     */
    loadSounds: function() {
      if (this.introSound == null) {
        this.introSound = new THREE.Audio(this.cameraListener)
        this.introSound.setBuffer(this.assets.introSoundBuffer)
        this.introSound.setLoop(false)

        this.introVolumeAnalyzer = this.introSound.context.createAnalyser()
        //this.introVolumeAnalyzer.fftSize = constants.INTRO_FFT_SIZE
        this.introSound.getOutput().connect(this.introVolumeAnalyzer)
      }
      if (this.outroSound == null) {
        this.outroSound = new THREE.Audio(this.cameraListener)
        this.outroSound.setBuffer(this.assets.outroSoundBuffer)
        this.outroSound.setLoop(false)
      }
    },
    
    /**
     * Sound initialisation:
     * - get, start, or resume audio context
     * - add AudioListener to camera (camera must be init first)
     * - load audio files
     * - init microphone
     * - start recorder
     */
    initAudio: async function() {

      let audioCtx = this.audioCtx

      /* Creates or resumes audio context from biome */
      if (!audioCtx || audioCtx.state == 'closed') {
        console.debug('no available audiocontext, starting one')
        audioCtx = new AudioContext()
        this.$store.commit('setAudioCtx', audioCtx)
      }
      if (audioCtx.state == 'suspended') {
        console.debug('Resuming available audioctx')
        audioCtx.resume()
      }
      THREE.AudioContext.setContext(audioCtx)

      /* Loads audio */
      this.cameraListener = new THREE.AudioListener()
      this.mainCamera.add(this.cameraListener)
      this.loadSounds()

      /* Inits microphone */
      this.userMediaStream = await this.getUserMediaStream()
      this.micSource = this.audioCtx.createMediaStreamSource(this.userMediaStream)
      
      /* Adds a highpass filter */
      const filter = this.audioCtx.createBiquadFilter()
      filter.Q.value = 8.3
      filter.frequency.value = 200
      filter.gain.value = 3
      filter.type = 'highpass'
      this.micSource.connect(filter)
      
      /* Creates a volume analyzer for microphone */
      this.micVolumeAnalyzer = this.audioCtx.createAnalyser()
      this.micVolumeAnalyzer.fftSize = constants.MIC_FFT_SIZE
      filter.connect(this.micVolumeAnalyzer)
      
      /* Starts recorder */
      this.initRecorder()
    },

    audioCleanUp: function() {
      if (this.audioCtx.state != 'closed') this.audioCtx.close()
    },
    
    /**
     * RECORDER
     */
    initRecorder: function() {
      /* creates media recorder */
      this.micBlobs = []
      this.micRecorder = new MediaRecorder(this.userMediaStream)
      this.micRecorder.ondataavailable = (e) => {
        this.micBlobs.push(e.data)
      }
      // this.micRecorder.onstart = () => {
      //   console.debug('START recording')
      // }
      // this.micRecorder.onresume = () => {
      //   console.debug('RESUME recording')
      // }
      // this.micRecorder.onpause = () => {
      //   console.debug('PAUSE recording')
      // }
      this.micRecorder.onstop = () => {
        this.getRecordedBlob()
      }
    },

    getRecordedBlob: function() {
      const blob = new Blob(this.micBlobs, { type: 'audio/ogg; codecs=opus' })
      // reset buffer
      this.micBlobs = []
      
      const audioURL = window.URL.createObjectURL(blob)
      this.$store.commit('biome1/setSoundBlob', audioURL)
      const audioEl = document.querySelector('#audio-player')
      /* defining length of audio recorded blob (chrome bug) */
      /* réf: https://stackoverflow.com/questions/38443084/how-can-i-add-predefined-length-to-audio-recorded-from-mediarecorder-in-chrome/39971175#39971175 */
      /* note ab: this code is badly organised. audioURL is pushed to store before editing */
      /* it probably works because it is a pointer copy but still */
      audioEl.src = audioURL
      audioEl.onloadedmetadata = () => {
        audioEl.currentTime = 100000
        audioEl.ontimeupdate = () => {
          audioEl.ontimeupdate = function () {}
          audioEl.currentTime = 0.1
          audioEl.currentTime = 0
        }
      }
    },
      
    /**
     * MICROPHONE device request and initialisation
     */
    getUserMediaStream: async function() {
      return await navigator.mediaDevices
        .getUserMedia({
          audio: {
            autoGainControl: true,
            noiseSuppression: false,
            echoCancellation: false,
            sampleRate: this.audioCtx.sampleRate
          },
        })
        .then((stream) => {
          this.micReady = true
          return stream
        })
        .catch((error) => console.error('MIC ACCESS NOT GRANTED', error))
    },
        
    startRecording: function() {
      if (this.micRecorder.state == 'paused') {
        this.micRecorder.resume()
      } else {
        this.micRecorder.start()
      }
      this.recordingMicStartTick = this.now
      this.isRecordingMic = true
      // console.debug('START RECORD at', this.recordingMicStartTick)
    },
    
    pauseRecording: function() {
      this.micRecorder.pause()
      this.isRecordingMic = false
      this.recordingLength += this.now - this.recordingMicStartTick
      // console.debug('PAUSE RECORD at ', this.recordingLength)
    },

    stopRecording: function() {
      this.micRecorder.stop()
      this.isRecordingMic = false
      this.recordingComplete = true
      // console.debug('STOP recording')
    },

    updateRecordingState: function() {
      const shouldRecord = (
        this.micActivityDuration >= constants.LISTENING_DURATION &&
          this.recordingProgress < 1 &&
          this.micActive
      )
      if (!this.isRecordingMic && shouldRecord) {
        this.startRecording()
      }
      if (this.isRecordingMic && !shouldRecord) {
        this.pauseRecording()
      }
      if (this.recordingProgress >= 1 && !this.recordingComplete) {
        this.stopRecording()
      }
    },

    handleMicOperations: function() {
      /* ---- updates microphone state ---- */
      if (this.micVolume >= this.MIC_VOL_THRESHOLD && !this.micActive) {
        this.micActive = true
      }
      if (this.micVolume < this.MIC_VOL_THRESHOLD && this.micActive) {
        this.micActive = false
      }
      /* ---- updates microphone activity timer ---- */
      if (this.micActive) {
        this.micActivityDuration += this.now - this.micLastUpdate
        this.micLastActive = this.now
        // console.debug(
        //   `MIC ACTIVE for ${(this.micActivityDuration * 1e-3).toFixed(2)}, ${this.micVolume}`
        // )
      }

      // handle microphone activity
      if (this.biomeState == 'RECORDING') {
        if (this.micActive) {
          /* last known active progress */
          if (this.recordingProgress >= 0) {
            this.micInactiveStartProgress = this.recordingProgress
          }
        } else {
          /* decrease mic duration to animate backwards */
          const inactiveDuration = this.now - this.micLastActive
          // if (inactiveDuration >= constants.DECREASE_DURATION)
          //   console.timeEnd('inactive mic')
          const inactiveProgress = Math.min(
            1 - inactiveDuration / constants.DECREASE_DURATION,
            1
          )
          this.micActivityDuration = Math.max(
            this.micInactiveStartProgress *
              inactiveProgress *
              constants.FULL_DURATION,
            0
          )
        }
        /* update etape progression which is used by camera animation */
        this.recordingProgress = THREE.MathUtils.mapLinear(
          this.micActivityDuration,
          0,
          constants.FULL_DURATION,
          0,
          1
        )
      }
      /* updates mic timer */
      this.micLastUpdate = this.now
    },


    /**
     * Analyzes a time-domain buffer to compute its RMS
     * (=average power of the signal).
     */
    getVolumeMeter: function(analyzer) {
      const buf = new Uint8Array(analyzer.fftSize)
      analyzer.getByteTimeDomainData(buf)
      let rms = 0
      for (let i = 0; i < buf.length; i++) {
        const a = 128 - buf[i]
        rms += a * a
      }
      rms /= analyzer.fftSize
      return Math.sqrt(rms) // - 128
    },

    analyzeIntroSound: function() {
      this.introVolume = this.getVolumeMeter(this.introVolumeAnalyzer)
    },
    
    analyzeMicSound: function() {
      this.micVolume = this.getVolumeMeter(this.micVolumeAnalyzer)
      this.handleMicOperations()
    }
  }
}
