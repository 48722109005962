<template>
  <div>
    <h1>
      <img
        src="@/assets/images/en/instructions.png"
        class="image-title"
      />
    </h1>
    <div class="text">
      <p>
        Bienvenue dans Sphinx.
      </p>
      
      <p>
        Faire descendre l'énigme de son piédestal, la résolution se trouve dans la traversée. Des instructions accompagnent votre expérience. Vous accompagnez la pâte de la Noosphère, la sphère de la pensée humaine, et vous vous incarnez avec elle.
      </p>

      <template v-if="appVersion == 'controller'">
        <p>
          Dans le premier monde, seul le micro (dans la manette) vous permettra d’interagir. Dans les trois mondes suivants, vous pouvez vous déplacer avec le joystick, et interagir avec les boutons jaune et rouge.
        </p>
        <p>
          À tout moment, vous pouvez appuyer sur le bouton de droite (blanc) pour mettre le jeu en pause.
        </p>
      </template>

      <template v-else-if="appVersion == 'arcade'">
        <p>
          Dans le premier monde, seul le micro (sous l'écran) vous permettra d’interagir. Dans les trois mondes suivants, vous pouvez vous déplacer avec le joystick, et interagir avec les boutons de gauche et du centre.
        </p>
        <p>
          À tout moment, vous pouvez appuyer sur le bouton de droite pour mettre le jeu en pause.
        </p>
      </template>
      
      <template v-else>
        <p>
          Dans le premier monde, seul le micro vous permettra d’interagir. Dans les trois mondes suivants, vous pouvez vous déplacer avec les flèches du clavier ou les touches Z, Q, S et D ; et interagir avec la barre espace et la touche entrée.
        </p>
        <p>
          À tout moment, vous pouvez appuyer sur la touche P pour mettre le jeu en pause.
        </p>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InstructionsFr',
  computed: {
    appVersion: () => process.env.VUE_APP_VERSION,
  }
}
</script>

<style scoped src="@/assets/css/menus.css" />
