import * as THREE from 'three'
import * as Tone from 'tone'
import { Midi } from '@tonejs/midi'
import { PositionalAudioHelper } from 'three/examples/jsm/helpers/PositionalAudioHelper'

import constants from '@/components/biomes/biome2/constants.js'

export const audioMixin = {
  data: function() {
    return {
      ambiantMusic: null,
      waterfallSound: null,
      // waterfallFx: null,
      winningFx: null,
      midiTrack: null,
      sampler: null,
      samplerFx: null,
      samplerReady: null,
    }
  },
  methods: {
    /**
     * Audio initialisation:
     * - get, start, or resume audio context
     * - set Tone and audio listener to follow player
     * - init ambiant music
     */
    initAudio: function() {
      if (!this.audioCtx || this.audioCtx.state == 'closed') {
        console.debug('no available audiocontext, starting one')
        const audioCtx = new AudioContext()
        this.$store.commit('setAudioCtx', audioCtx)
      }
      if (this.audioCtx.state == 'suspended') {
        console.debug('Resuming available audioctx')
        this.audioCtx.resume()
      }
      THREE.AudioContext.setContext(this.audioCtx)

      /* audio listener init */
      Tone.setContext(this.audioCtx)
      this.player.listener = new THREE.AudioListener()
      this.player.camera.add(this.player.listener)
      /* positional waterfall sound sources init */
      this.initWaterfallAudio()
      /* ambiance music init */
      this.initAmbiantMusic()
    },
    
    audioCleanUp: function() {
      if (this.audioCtx.state != 'closed') this.audioCtx.close()
      this.midiPart.dispose()
      this.sampler.dispose()
      Tone.getTransport().stop()
      Tone.getTransport().dispose()
      Tone.context.close()
      // delete this.samplerFx
      // delete this.sampler
      // delete this.midiPart
      // delete this.ambiantMusic
    },
    
    /**
     * ambiant music based on 3 components:
     * - wav/mp3 file of the score
     * - recorded blob from biome 1 as an "instrument"
     * - midi file to play the "instrument"
     */
    initAmbiantMusic: async function() {
      /* score */
      this.ambiantMusic = new THREE.Audio(this.player.listener)
      this.ambiantMusic.setBuffer(this.assets.ambiantMusicBuffer)
      this.ambiantMusic.setVolume(constants.AMBIANT_VOLUME)
      this.ambiantMusic.setLoop(true)
        
      /* midi track and sampler */
      this.midiTrack = this.assets.midiTracks.tracks[0]
      this.samplerFx = new Tone.Reverb({
        decay: 5.7,
        preDelay: 0.0005,
        wet: 0.52,
      })
      this.samplerFx.connect(this.audioCtx.destination)

      /* attach biome1 sound */
      this.sampler = new Tone.Sampler({
        urls: { D4: this.assets.soundBlob },
      })
      this.sampler.connect(this.samplerFx)
      this.sampler.volume.value = constants.VOICE_VOLUME

      Tone.loaded().then(() => {
        //this.sampler.sync()
        this.scheduleMidi()
        this.onSamplerReady()
      })
    },
    
    scheduleMidi: function() {
      this.midiPart = new Tone.Part((time, note) => {
        // console.debug('trigger', note, time.toFixed(2), Tone.now().toFixed(2))
        this.sampler.triggerAttackRelease(
          note.name,
          note.duration,
          note.time + time,
          note.velocity
        )
      }, this.midiTrack.notes)
    },
    
    /**
     * callback when sampler is ready.
     * starts audio file and midi track simultaneously
     */
    onSamplerReady: function() {
      this.ambiantMusic.play()
      this.midiPart.start(0)
      Tone.getTransport().start()

      // const trans = Tone.getTransport()
      // trans.bpm.value = 105
      // console.debug('TRANSPORT', trans)
    },

    /**
     * inits water fallwater sound effects
     * loads sound and sets loop
     * adds positional audio players to change sound depending
     * on player position
     */
    initWaterfallAudio: function() {
      this.winningFx = new THREE.Audio(this.player.listener)
      this.winningFx.setBuffer(this.assets.victorySoundBuffer)
      this.winningFx.setLoop(false)
      this.winningFx.setVolume(constants.EARTHQUAKE_VOLUME)

      this.waterfallSound = new THREE.PositionalAudio(this.player.listener)
      this.waterfallSound.name = 'WaterfallSound'

      const filter = this.player.listener.context.createBiquadFilter()
      filter.Q.value = 2
      filter.frequency.value = 0
      filter.type = 'highpass'
      this.waterfallSound.setFilter(filter)
      this.waterfallSound.setBuffer(this.assets.waterfallSoundBuffer)
      this.waterfallSound.setLoopEnd(58)
      this.waterfallSound.setLoop(true)
      this.waterfallSound.setRefDistance(3)
      this.waterfallSound.setVolume(constants.WATERFALL_VOLUME)
      //this.waterfallSound.setDistanceModel("inverse")

      // attach to any waterfall
      this.waterfalls.children[0].add(this.waterfallSound)

        /* visual helper */
      // const helper = new PositionalAudioHelper(this.waterfallSound)
      // this.waterfallSound.add(helper)
        // console.log('REFDISTANCE', waterfallSound.getRefDistance())
        // console.log('ROLLOFF', waterfallSound.getRolloffFactor())
      //console.log(waterfallSound.panner)
      // }
    },

    updateWCAudio: function(animT) {
      const son = this.nearestWaterfall.getObjectByName('WaterfallSound')
      if (son) {
        const filter = son.getFilter()
        const ff = THREE.MathUtils.mapLinear(animT, 0, 1, 0, 4000)
        filter.Q.value = 8
        filter.frequency.value = ff
      }
    },

    resetWCAudio: function() {
      const nearestWaterfall = this.findNearestWaterfall()
      const son = nearestWaterfall.getObjectByName('WaterfallSound')
      const filter = son.getFilter()
      filter.Q.value = 2
      filter.frequency.value = 0
    }
  }
}
