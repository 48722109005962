<template>
  <div>
    <h1>
      <img
        src="@/assets/images/en/art.png"
        class="image-title"
      />
    </h1>
    <transition-group
      name="slideshow"
      class="slideshow"
      ref="slideshow"
      tag="div"
    >
      <template v-for="(slide, idx) in slides">
        <img
          v-if="currentSlide == idx"
          :key="'slide-'+idx"
          :src="require('@/assets/images/art/' + slide)"
          class="slide"
          >
      </template>
    </transition-group>
  </div>
</template>

<script>
export default {
  name: 'Art',
  components: { },
  mounted: function() {
    // slideshow
    this.autoSlide()
  },
  beforeDestroy: function() {
    clearInterval(this.slideshowId)
  },
  data: function() {
    return {
      slideshowId: null,
      currentSlide: 1,
    }
  },
  computed: {
    slides: function() {
      return [
        "cartepostalecrotte.jpg",
        "biome1.png",
        "cartepostalepetitefille.jpg",
        "biome2.png",
        "cartepostaletour.png",
        "biome3.png",
        "cartepostalealien.jpg",
        "biome3b.png",
        "cartepostalesouris.jpg",
        "biome4.png",
        "cartepostalejambes.png",
        "biomes.jpg",
        "sphinxcroquis.jpg"
      ]
    }
  },
  methods: {
    autoSlide: function() {
      let slideshow = this.$refs.slideshow
      this.slideshowId = setInterval(() => {
        this.currentSlide = (this.currentSlide + 1) % this.slides.length
      }, 5000)
    },
  }
}
</script>

<style scoped src="@/assets/css/menus.css" />
<style>
.slideshow {
  height: 40vh;
  width: 60vh;
  margin: 2em auto;
  position: relative;
}
.slide {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  display: inline;
  box-shadow: black 0px 0px 80px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.slideshow-enter-active, .slideshow-leave-active {
  transition: all .5s;
}
.slideshow-enter-active {
  transition-delay: .4s;
}
.slideshow-enter {
  opacity: 0;
  left: 100%;
}
.slideshow-leave-to {
  opacity: 0;
  left: 0%;
}
</style>
