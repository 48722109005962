/**
* defines camera movements:
*/

import * as THREE from 'three'

import constants from '@/components/biomes/biome3/constants.js'

export const cameraAnimationMixin = {
  data: function() {
    return {
      cameraStart: {
        fov: null,
        position: null,
        quaternion: null,
        time: null,
      },
      cameraEnd: {
        fov: null,
        position: null,
        quaternion: null,
        time: null,
      },
      cameraTarget: null,
    }
  },
  methods: {
    /**
     * Prepares mixer and vectors for introduction animation:
     * falling into the cavern
     */
    startCameraIntroAnimation: function() {
      const sp = this.mainCamera
      this.cameraEnd.position = new THREE.Vector3().copy(this.player.position).add(this.deltaCamera)
      this.cameraStart.position = new THREE.Vector3().copy(this.player.position).setY(1400)
      
      sp.mixer = new THREE.AnimationMixer(sp)
      const spArrivalPositionTrack = new THREE.VectorKeyframeTrack(
        '.position',
        constants.INTRO_KEYFRAMES,
        [...this.cameraStart.position,
         ...this.cameraEnd.position],
        THREE.InterpolateSmooth
      )
      
      const spArrivalClip = new THREE.AnimationClip('ARRIVAL', constants.INTRO_TOTAL_DURATION, [
        spArrivalPositionTrack,
      ])
      sp.animations = [spArrivalClip]
      const action = sp.mixer.clipAction(spArrivalClip)
      action.loop = THREE.LoopOnce
      action.clampWhenFinished = true

      action.play()
      return sp.mixer
    },

    /**
     * Updates introduction animation
     */
    updateCameraIntroAnimation: function() {
      this.mainCamera.mixer.update(this.dt)
    },

    startCameraTalkingAnimation: function() {
      this.cameraStart.fov = this.mainCamera.fov
      this.cameraStart.position = this.mainCamera.position.clone()
      this.cameraStart.quaternion = this.mainCamera.quaternion.clone()

      this.cameraEnd.fov = this.mainCamera.fov
      this.cameraEnd.position = this.mainCamera.position.clone().setZ(constants.BOUNDS[2])
      this.cameraTarget = this.nearestCharacter.position
    },

    startCameraBackAnimation: function() {
      this.cameraEnd.fov = this.cameraStart.fov
      this.cameraEnd.position = this.cameraStart.position.clone()

      this.cameraStart.fov = this.mainCamera.fov
      this.cameraStart.position = this.mainCamera.position.clone()
      this.cameraTarget = this.player.position
    },

    updateCameraTalkingAnimation: function(animT) {
      /* camera position */
      const cameraPos = new THREE.Vector3().lerpVectors(
        this.cameraStart.position,
        this.cameraEnd.position,
        animT
      )
      /* camera focal */
      const fov = THREE.MathUtils.lerp(
        this.cameraStart.fov,
        this.cameraEnd.fov,
        animT
      )

      this.mainCamera.position.copy(cameraPos)
      this.mainCamera.lookAt(this.cameraTarget)
      this.mainCamera.updateProjectionMatrix()
    },
  }


}
