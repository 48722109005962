<template>
  <div>
    <div
      v-if="debug"
      v-show="showControls"
      id="button-next-level"
      @click="$store.commit('setLevel', 3)"
    >
      SUIVANT
    </div>
    <div
      v-if="debug"
      v-show="showControls"
      id="stats-container"
    />
    <canvas />
  </div>
</template>

<script>
/* VUE imports */
import { mapState } from 'vuex'

/* THREEJS */
import * as THREE from 'three'

/* SPHINX */
import { pauseMixin } from '@/components/biomes/pause.js'

import constants from '@/components/biomes/biome2/constants.js'

import { audioMixin } from '@/components/biomes/biome2/audio.js'
import { bumpMixin } from '@/components/biomes/biome2/bump.js'
import { cameraAnimationMixin } from '@/components/biomes/biome2/camanim.js'
import { caveMixin } from '@/components/biomes/biome2/cave.js'
import { containerMixin } from '@/components/biomes/biome2/container.js'
import { keyboardMixin } from '@/components/biomes/biome2/keyboard.js'
import { playerMixin } from '@/components/biomes/biome2/player.js'
import { sceneMixin } from '@/components/biomes/biome2/scene.js'
import { waterfallMixin } from '@/components/biomes/biome2/waterfall.js'

export default {
  name: 'Biome2',
  components: {},
  mixins: [
    audioMixin,
    bumpMixin,
    cameraAnimationMixin,
    caveMixin,
    containerMixin,
    keyboardMixin,
    pauseMixin,
    playerMixin,
    sceneMixin,
    waterfallMixin
  ],
  props: {
    godMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: function () {
    return {
      /* game */
      winningCounter: Math.floor(3 + Math.random() * 4),
      won: false,
      /* Cameras */
      /* ab: could probably get rid of it */
      mainCamera: {},
      /* animation */
      animationRequestId: null,
      // 'clock' and 'dt' are to update player.mixer
      // 'now' is to compute the end times of animations
      // could they be merged?
      clock: new THREE.Clock(),
      dt: 0,
      now: 0,
      biomeState: 'INTRO',
      isReady: false,
      animationState: '',
      
      /* Develop/Experiment controls */
      showReloadButton: false,
      showControls: false,
      controls: {},
      stats: null,
    }
  },
  computed: {
    debug: () => process.env.NODE_ENV == 'development',
    ...mapState({
      audioCtx: (state) => state.audioCtx,
      assets: (state) => {
        return { ...state.biome2, 'soundBlob': state.biome1.soundBlob }
      },
      animating: (state) => state.animating,
    }),
  },
  mounted: function () {
    console.debug('BIOME 2 MOUNTED')
    
    this.initScene()
    this.setEnvironmentalMap()
    this.createWaterfalls()
    
    this.createCave()
    this.createPlayer()
    this.createContainers()
    this.initAudio()
    this.initCollision()

    /* event handlers */
    this.onResize()
    window.addEventListener('resize', this.onResize)
    window.addEventListener('pointermove', this.onPointerMove)
    window.addEventListener('keypress', this.onKeyPress)
    window.addEventListener('keydown', this.onKeyDown)
    window.addEventListener('keyup', this.onKeyUp)
    window.addEventListener('click', this.onMouseClick)
    
    this.animate()
  },
  created: function () {
    console.debug('BIOME 2 CREATED')
  },
  beforeDestroy: function () {
    console.debug('BIOME 2 DESTROY')
    cancelAnimationFrame(this.animationRequestId)
    this.audioCleanUp()
    this.freeScene()
    this.renderer.dispose()
  },
  methods: {
    /**
     * Animation frame handler
     */
    animate: function() {
      this.animationRequestId = requestAnimationFrame(this.animate)
      if (this.pause) return null
      
      this.now = performance.now()
      this.dt = this.clock.getDelta()
      
      this.updateSiflinxGlow()
      this.updateWaterfalls()
      
      if (this.biomeState == 'INTRO') {
        if (!this.isReady) {
          this.$store.commit('setHidden', false)
          this.$store.commit('setAnimating', true)
          this.isReady = true
          
          this.renderer.compile(this.scene, this.mainCamera)
          
          const mixer = this.startCameraIntroAnimation()
          mixer.addEventListener('finished', (event) => {
            this.biomeState = 'WALKING'
            this.$store.commit('setAnimating', false)
            this.waterfallSound.play()
          })
        }
        this.updateCameraIntroAnimation()
      }

      if (this.biomeState == 'WALKING') {
        if (this.updatePlayer()) {
          this.findNearestContainer()
          this.findNearestWaterfall()
        }
        if (this.player.isFree == false) {
          this.biomeState = 'FILLING_CONTAINER'
        }
      }

      if (this.biomeState == 'FILLING_CONTAINER') {
        this.updateWCAnimation()
        if (this.player.isFree == true) {
          this.biomeState = 'WALKING'
          this.resetWCAudio()
        }
      }

      this.render()

      if (this.stats) this.stats.update()
    },

    startWCAnimation: function() {
      const waterfall = this.nearestWaterfall
      const flow = this.nearestWaterfall.getObjectByName("flow")
      const container = this.nearestWaterfall.getObjectByName("container")
      let tmp = new THREE.Vector3()
      container.geometry.boundingBox.getSize(tmp)
      // note ab: / 2 works miraculously well will all containers
      flow.position.setY(flow.position.y + tmp.y / 2)

      this.startCameraWCAnimation()
    },
    
    updateWCAnimation: function() {
      const spec = this.player
      const animT = THREE.MathUtils.mapLinear(
        this.now,
        this.cameraStart.time,
        this.cameraEnd.time,
        0,
        1
      )

      if (this.animating) {
        if (this.animationState == 'MOVING_CAMERA') {
          this.updateCameraWCAnimation(animT, true)
        }
        else if (this.animationState == 'ANIMATING_OBJECTS') {
          this.updateWCWaterfallAnimation(animT)
          this.updateWCBumpAnimation(animT)
          this.updateWCAudio(animT)
        }
        if (this.animationState == 'OBJECTS_DONE') {
          this.updateCameraWCAnimation(animT, false)
        }
        else if (this.animationState == 'DIVING') {
          this.updateEndAnimation(animT)
        }

        if (animT > 1) {
          this.$store.commit('setAnimating', false)
        }
      } else {
        // go to next animation step
        if (this.animationState != 'OVER') {
          const stateIdx = constants.WC_ANIMATION_STATES.indexOf(this.animationState)
          this.animationState = constants.WC_ANIMATION_STATES[stateIdx + 1]
        }
        
        if (this.animationState == 'MOVING_CAMERA') {
          this.hands.visible = false
          this.startWCAnimation()
          this.$store.commit('setAnimating', true)
        } else if (this.animationState == 'ANIMATING_OBJECTS') {
          this.cameraStart.time = this.now
          this.cameraEnd.time = this.now + constants.CINEMATIC1_DURATION
          this.startWCWaterfallAnimation()
          //this.startWCSoundAnimation()
          this.startWCBumpAnimation()
          this.$store.commit('setAnimating', true)
        } else if (this.animationState == 'OBJECTS_DONE') {
          this.cameraStart.time = this.now
          this.cameraEnd.time = this.now + constants.CINEMATIC0_DURATION
          /* check victory condition */
          this.won = this.bumps.children.length == this.winningCounter

          if (!this.won) {
            /* restores player position and camera */
            this.cameraEnd.fov = this.cameraStart.fov
            this.cameraStart.fov = this.player.fov

            this.cameraEnd.position = this.cameraStart.position.clone()
            this.cameraStart.position = this.player.position.clone()

            this.cameraEnd.quaternion = this.cameraStart.quaternion.clone()
            this.cameraStart.quaternion = this.player.camera.quaternion.clone()

            this.cameraTarget = null
            this.$store.commit('setAnimating', true)
          }
        } else if (this.animationState == 'DIVING') {
          this.hands.visible = true
          if (this.won) {
            this.cameraEnd.time = this.now + constants.CINEMATIC_END_DURATION
            this.startEndAnimation()

            this.$store.commit('setAnimating', true)
            console.log('END BIOME2')
            setTimeout(() => {
              this.$store.commit('setHidden', true)
            }, 3000)
            setTimeout(() => {
              this.$store.commit('setLevel', 3)
            }, 6000)
          } else {
            spec.isFree = true
            this.animationState = 'WALKING'
          }
        }
      }
    },

    onResize: function() {
      // console.debug("resize !", this.sceneContainer.clientWidth, this.sceneContainer.clientHeight)
      this.renderWidth = this.sceneContainer.clientWidth
      this.renderHeight = this.sceneContainer.clientHeight
      this.mainCamera.aspect = this.renderWidth / this.renderHeight
      this.mainCamera.updateProjectionMatrix()
      this.renderer.setPixelRatio(window.devicePixelRatio)
      this.renderer.setSize(this.renderWidth, this.renderHeight)
    },
  }
}
</script>

<style scoped>
.button {
  border: 1px solid #fff;
}

.button:hover {
  box-shadow: 2px 2px 2px #fff;
}
/* TEMPORARY */
#button-next-level {
  position: absolute;
  z-index: 2;
  bottom: 0;
  right: 0;
  padding: 5px;
  cursor: pointer;
}
.fadeout {
  opacity: 0;
}
</style>
