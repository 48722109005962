<template>
  <div>
    <div class="text pause">
      <img src="@/assets/biomes/biome4/instructions.png">
      <h1>Faire descendre l'idée de son piédestal</h1>
      <p v-if="appVersion == 'web'">
        Utilisez <img class="keys-2" src="@/assets/images/arrows.png"> ou <img class="keys-2" src="@/assets/images/ZQSD.png"> pour vous déplacer,<br>
        <img class="keys-1" src="@/assets/images/entree.png"> pour prendre et poser les objets,<br>
        <img class="keys-1" src="@/assets/images/space.png"> pour interagir avec les objets,
        <img class="keys-1" src="@/assets/images/p.png"> pour la pause.
      </p>
      <p v-else>
        Utilisez <img class="keys-1" src="@/assets/images/cross.png"> pour vous déplacer,<br>
        <img class="keys-1" src="@/assets/images/button_A.png"> pour prendre et poser les objets,<br>
        <img class="keys-1" src="@/assets/images/button_B.png"> pour interagir avec les objets,
        <img class="keys-1" src="@/assets/images/button_C.png"> pour la pause.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Biome4Fr',
  computed: {
    appVersion: () => process.env.VUE_APP_VERSION,
  }
}
</script>

<style scoped src="@/assets/css/menus.css" />
