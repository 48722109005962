/**
 * builds the paths
 * that is non interactive elements 
 */
import * as THREE from 'three'

import constants from '@/components/biomes/biome3/constants.js'

export const characterMixin = {
  data: function() {
    return {
      characters: new THREE.Group(),
    } 
  },
  methods: {
    /**
     * creates the wandering characters
     */
    createCharacters: async function() {
      this.characters = new THREE.Group()

      const voicesIdx = Array.from(
        Array(this.assets.voices.length).keys()
      ).sort(
        () => Math.random() -0.5
      )

      for (let cnt = 0; cnt < this.assets.characters.length; cnt++) {
        let c = Object.create(this.assets.characters[cnt])
        // Skinned meshes need to have frustumCulled set to false or they do not
        // appear correctly depending on camera (position)
        for (let child of c.children) {
          child.frustumCulled = false
        }

        // Load path
        let animation = this.assets.animations.find(
          anim => anim.name == c.name
        )

        let mixer = new THREE.AnimationMixer(c)
        mixer.timeScale = .5
        c.path = mixer.clipAction(animation)
        c.path.play()
        this.mixers.push(mixer)

        console.log(animation.duration)
        
        // //c.position.set(...constants.POSITIONS[c.name])

        // Load skeleton animation
        let animation2 = this.assets.animations.find(
          anim => anim.name.startsWith(c.name) && anim.name.indexOf("Rig") != -1
        )
        let mixer2 = new THREE.AnimationMixer(c)
        mixer2.timeScale = 1
        c.walk = mixer.clipAction(animation2)
        c.walk.play()
        this.mixers.push(mixer2)

        c.voice = new THREE.Audio(this.listener)
        c.voice.setBuffer(this.assets.voices[voicesIdx[cnt]])
        c.voice.setLoop(true)

        c.stop = function() {
          c.walk.timeScale = 0
          c.path.timeScale = 0
        }
        c.play = function() {
          c.walk.timeScale = 1
          c.path.timeScale = 1
          c.hasBrick = false
        }

        if (c.name == "Unko") {
          animation.duration = 16.6
          animation2.duration = 16.6
        }

        c.hasBrick = true
        this.characters.add(c)
      }
      
      /* adds all to scene */
      this.scene.add(this.characters)
    },
  }
}
