/**
 * handles key controls for biome 2
 */
export const keyboardMixin = {
  methods: {
    /**
     * 'Space' or 'Enter': grab or drop the closest container
     * 'V': show controls
     * 'C': debug in console
     */
    onKeyPress: function(event) {
      if (['Space', 'Enter'].includes(event.code)) {
        if (this.player.containerHandle.children.length) {
          this.dropContainer()
        } else {
          this.grabNearestContainer()
        }
      }
      else if (event.code == 'KeyV') {
        this.showControls = !this.showControls
      }
      else if (event.code == 'KeyC') {
        console.debug(this.player.position)
      }
    },

    /**
     * Set player movements
     * 'up' or 'W': go forward
     * 'down' or 'S': go backward
     * 'left' or 'A': rotate left
     * 'right' or 'D': rotate right
     * 'shift': run
     */
    onKeyDown: function(event) {
      const keyCode = event.code
      const movementSpeed = 1
      if (keyCode == 'ArrowUp' || keyCode == 'KeyW') {
        this.player.moveDir.z = movementSpeed
      }
      if (keyCode == 'ArrowDown' || keyCode == 'KeyS') {
        this.player.moveDir.z = -movementSpeed
      }
      if (keyCode == 'ArrowLeft' || keyCode == 'KeyA') {
        this.player.moveDir.x = movementSpeed * 2
      }
      if (keyCode == 'ArrowRight' || keyCode == 'KeyD') {
        this.player.moveDir.x = -movementSpeed * 2
      }
      if (['ShiftLeft', 'ShiftRight'].includes(event.code)) {
        this.player.isRunning = true
      }
    },

    /**
     * Stop player movements
     * 'up' or 'W': go forward
     * 'down' or 'S': go backward
     * 'left' or 'A': rotate left
     * 'right' or 'D': rotate right
     * 'shift': run
     */
    onKeyUp: function(event) {
      const keyCode = event.code
      if (keyCode == 'ArrowUp' || keyCode == 'KeyW') {
        this.player.moveDir.z = 0
      }
      if (keyCode == 'ArrowDown' || keyCode == 'KeyS') {
        this.player.moveDir.z = 0
      }
      if (keyCode == 'ArrowLeft' || keyCode == 'KeyA') {
        this.player.moveDir.x = 0
      }
      if (keyCode == 'ArrowRight' || keyCode == 'KeyD') {
        this.player.moveDir.x = 0
      }
      if (['ShiftLeft', 'ShiftRight'].includes(event.code)) {
        this.player.isRunning = false
      }
    }
  }
}

