/**
 * builds the sphinx, its interior and the 4 supporting columns
 */
import * as THREE from 'three'

export const sphinxMixin = {
  data: function() {
    return {
      sphinx: new THREE.Group(),
      biomes: new THREE.Group(),
      columns: new THREE.Group(),
      columnMixers: [],
      longestAnimation: 0,
      longestMixer: undefined,
      flow: new THREE.Group(),
      flowCnt: 65,
    } 
  },
  methods: {

    /**
     * assigns an animation to a mesh and resets the animation
     * returns the mixer
     */
    assignAnimation: function(mesh, animation) {
      let mixer = new THREE.AnimationMixer(mesh)
      let action = mixer.clipAction(animation)
      action.loop = THREE.LoopOnce
      action.clampWhenFinished = true
      action.play()
      mixer.update(0)
      return mixer
    },
    
    /**
     * creates the sphinx
     */
    createSphinx: async function() {
      // loads elements of the sphinx (it includes the clouds)
      let longestDuration = 0
      for (let model of this.assets.sphinx.scene.children) {
        // change some material properties of the "skin"
        let tmp = Object.create(model)
        if (tmp.name == "Sphynx") {
          tmp.material.transparent = false
          // tmp.material.emissiveIntensity = 0
          // tmp.material.opacity = 1
        }
        if (tmp.name == "Nuages_5") {
          tmp.material.opacity = .3
          tmp.visible = true
        }
        // look for animations for each loaded mesh (they will be used
        // at the end of the biome)
        let anims = this.assets.sphinx.animations.filter(
          anim => anim.name.startsWith(tmp.name)
        )
        for (let animation of anims) {
          let mixer = this.assignAnimation(tmp, animation)
          
          this.columnMixers.push(mixer)
          if (animation.duration > longestDuration) {
            longestDuration = animation.duration
            this.longestMixer = mixer
          }
        }
        this.sphinx.add(tmp)
      }

      // loads the biomes (inside the sphinx)
      for (let model of this.assets.biomes.scene.children) {
        let tmp = Object.create(model)
        this.biomes.add(tmp)
      }
      
      // loads the columns' fragments
      for (let frag of this.assets.columns.scene.children) {
        let tmp = Object.create(frag)
        tmp.position.setY(frag.position.y - 0.001)
        // look for the animation for each fragment (it will be used
        // at the end of the biome)
        let animation = this.assets.columns.animations.find(
          anim => anim.name.startsWith(
            tmp.name.replace("cell", "cell.")
          )
        )
        if (animation) {
          this.columnMixers.push(this.assignAnimation(tmp, animation))
        } 
        this.columns.add(tmp)
      }

      // loads the meshes of the liquid flow (they will be used
        // at the end of the biome)
      for (let flow of this.$store.state.biome4.fluid.scene.children) {
        flow.visible = false
        this.flow.add(Object.create(flow))
      }
      
      this.scene.add(this.sphinx)
      this.scene.add(this.biomes)
      this.scene.add(this.columns)
      this.scene.add(this.flow)
    },

    startSphinxAnimation: function() {
      this.biomes.visible = false
      this.longestMixer.addEventListener('finished', (event) => {
        this.animationState = 'FLOODING'
      })
    },
    
    updateSphinxAnimation: function() {
      if (this.animationState == 'TRANSFORMING') {
        for (let mixer of this.columnMixers) {
          mixer.update(this.dt)
        }
      }
      if (this.animationState == 'FLOODING') {
        this.flow.children[this.flowCnt].visible = false
        this.flowCnt = (this.flowCnt + 1) % this.flow.children.length
        this.flow.children[this.flowCnt].visible = true
        this.water.scale.sub(new THREE.Vector3(0.0001, 0.0001, 0))
        this.water.position.add(new THREE.Vector3(0, 0.0001, 0))
        if (this.water.position.y > 0.036 && this.flowCnt == 66) {
          this.animationState = 'FLOURISHING'
        }
      }
    }
  }
}
