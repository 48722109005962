import * as THREE from 'three'

import constants from '@/components/biomes/biome4/constants.js'

export const cameraAnimationMixin = {
  data: function() {
    return {
      animStart: 0
    }
  },
  methods: {
    startCameraIntroAnimation: function() {
      const sp = this.mainCamera
      const startPosition = new THREE.Vector3().set(0, 0.3, 0.03)
      const midPosition = new THREE.Vector3().set(0, 0.03, 0.03)
      const endPosition = new THREE.Vector3().set(0, .08, .1)
      
      sp.mixer = new THREE.AnimationMixer(sp)
      const spArrivalPositionTrack = new THREE.VectorKeyframeTrack(
        '.position',
        constants.INTRO_KEYFRAMES,
        [...startPosition,
         ...midPosition,
         ...endPosition],
        THREE.InterpolateSmooth
      )
      
      const spArrivalClip = new THREE.AnimationClip('INTRO', constants.INTRO_TOTAL_DURATION, [
        spArrivalPositionTrack,
      ])
      const action = sp.mixer.clipAction(spArrivalClip)
      action.loop = THREE.LoopOnce
      action.clampWhenFinished = true
      action.play()

      this.animStart = this.now
      return sp.mixer
    },

    updateCameraIntroAnimation: function() {
      this.mainCamera.mixer.update(this.dt)
      const angle = Math.max(0, (this.now - this.animStart - 4000) / 1000) * -0.25
      this.mainCamera.lookAt(0, 0, angle)
    },
    
    startCameraOutroAnimation: function() {
      const sp = this.mainCamera
      this.scene.add(sp)
      const startPosition = new THREE.Vector3().copy(this.player.position)
      const endPosition = new THREE.Vector3().set(.4, .3, -1.2)

      sp.mixer = new THREE.AnimationMixer(sp)
      const spArrivalPositionTrack = new THREE.VectorKeyframeTrack(
        '.position',
        [0, 5],
        [...startPosition,
         ...endPosition],
        THREE.InterpolateSmooth
      )
      
      const spArrivalClip = new THREE.AnimationClip('OUTRO', 5, [
        spArrivalPositionTrack,
      ])
      const action = sp.mixer.clipAction(spArrivalClip)
      action.loop = THREE.LoopOnce
      action.clampWhenFinished = true
      action.play()

      sp.mixer.addEventListener('finished', (event) => {
        this.animationState = 'TRANSFORMING'
      })
      this.ghettoBlasterAudio.setVolume(1)

      return sp.mixer
    },

    updateCameraOutroAnimation: function() {
      this.mainCamera.mixer.update(this.dt)
      this.mainCamera.lookAt(0, 0, 0)
    },
  }
}
