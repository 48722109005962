import * as THREE from 'three'

import constants from '@/components/biomes/biome2/constants.js'

export const bumpMixin = {
  data: function() {
    return {
      bumps: new THREE.Group(),
    }
  },
  methods: {
    startWCBumpAnimation: function() {
      const waterfall = this.nearestWaterfall

      const r = 1
      let theta = 0
      let bump = new THREE.Group()
      for (let i = 0; i < constants.NB_ROCKS; i++) {
        theta = (2 * Math.PI * i) / constants.NB_ROCKS
        const rock = this.assets.rocks[
          Math.floor(Math.random() * this.assets.rocks.length)
        ].clone()
        rock.scale.setScalar(1 + 2 * Math.random())
        rock.rotation.x = 2 * Math.PI * Math.random()
        rock.rotation.y = 2 * Math.PI * Math.random()
        rock.rotation.z = 2 * Math.PI * Math.random()
        rock.position.set(
          r * Math.cos(theta),
          0,
          r * Math.sin(theta)
        )
        bump.add(rock)
      }
      bump.position.copy(waterfall.position).setY(0)
      bump.scale.setScalar(0.2)
      console.log(THREE, bump)
      this.bumps.add(bump)
      this.scene.add(this.bumps)

      // add bump to collidable objects
      let box = new THREE.Box3().setFromObject(bump)
      const geo = new THREE.SphereGeometry(box.max.x - box.min.x, 32)
      const material = new THREE.MeshBasicMaterial( { color: 0xffffff, visible: false } )
      const sphere = new THREE.Mesh( geo, material )
      sphere.scale.setScalar(2)
      sphere.position.copy(bump.position)
      this.scene.add(sphere)
      this.collidableObjects.push(sphere)

    },

    updateWCBumpAnimation: function (animT) {
      const bump = this.bumps.children[this.bumps.children.length - 1]
      bump.scale.setScalar(0.2 + animT)
    },

    startEndAnimation: function() {
      console.log('START end winning animation')
      this.winningFx.play()
      this.winningFx.firstPlay = false

      const waterfall = this.nearestWaterfall

      const geo = new THREE.CircleGeometry(1.1, 32)
      const mat = new THREE.MeshBasicMaterial({
        color: 0x000000,
        side: THREE.DoubleSide
      })
      const hole = new THREE.Mesh(geo, mat)
      hole.name = 'hole'
      this.bumps.add(hole)

      hole.position.copy(waterfall.position).setY(0)
      hole.rotation.x = Math.PI / 2
      hole.scale.setScalar(0)

      const spec = this.player
      
      this.cameraStart.position = spec.position.clone()
      this.cameraEnd.position = waterfall.position.clone().setY(2)
    },

    updateEndAnimation: function(animT) {
      const bump = this.bumps.children[this.bumps.children.length - 2]
      const hole = this.bumps.children[this.bumps.children.length - 1]
      hole.scale.setScalar(5 * animT)

      for (let i = 0; i < constants.NB_ROCKS; i++) {
        const theta = (2 * Math.PI * i) / constants.NB_ROCKS
        const rock = bump.children[i]
        rock.position.set(
          (1 + 4 * animT) * Math.cos(theta),
          0,
          (1 + 4 * animT) * Math.sin(theta)
        )
      }

      const spec = this.player
      this.hands.material.opacity = 1 - animT

      this.ambiantMusic.setVolume(constants.AMBIANT_VOLUME - animT)
      const son = this.nearestWaterfall.getObjectByName('WaterfallSound')
      son.setVolume(constants.WATERFALL_VOLUME - animT)
      if (animT > 0.8) {
        this.cameraEnd.position.y -= animT
      }
      const cameraPos = new THREE.Vector3().lerpVectors(
        this.cameraStart.position,
        this.cameraEnd.position,
        animT
      )
      spec.position.copy(cameraPos)
      spec.camera.lookAt(this.cameraEnd.position)
      spec.camera.updateProjectionMatrix()
    },

  }
}
