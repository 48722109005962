/**
 * builds the main environment: ground, walls, columns, bas reliefs
 * that is non interactive elements 
 * (except for collisions, handled in player mixin)
 */
import * as THREE from 'three'

import constants from '@/components/biomes/biome2/constants.js'

import glowVertexShader from '@/shaders/biome2/siflinx-glow.glsl'
import glowFragmentShader from '@/shaders/biome2/siflinx-glow-frag.glsl'

export const caveMixin = {
  data: function() {
    return {
      /* cave */
      ee: null,
      cave: {},
      caveBlockingPoints: {},
      caveCenter: new THREE.Vector3(),
      floor: {},
      columns: new THREE.Group(),
      siflinxGlow: null,
      glowVariation: constants.GLOW_VARIATION,
      basreliefs: new THREE.Group(),
      unavailablePositions: [],
    } 
  },
  methods: {
    // unused
    // getVerticalCavePoint: function(p) {
    //   const raycaster = new THREE.Raycaster()
    //   raycaster.set(p, new THREE.Vector3(0, 1, 0))
    //   const intersects = raycaster.intersectObject(this.cave)
    //   if (intersects.length) {
    //     return intersects[0].point
    //   }
    // },

    /**
     * to place containers.
     * uses raycasting to put an object on the ground
     */
    getVerticalFloorPoint: function(point) {
      const raycaster = new THREE.Raycaster()
      // vertical ray to bottom
      raycaster.set(point, new THREE.Vector3(0, -1, 0))
      const intersects = raycaster.intersectObject(this.floor)
      if (intersects.length) {
        return intersects[0].point
      }
      return null
    },

    /**
     * uses emissive maps as light maps for all objects
     * it avoids using light sources and creates the 
     * strange ambiance of the cave
     */
    switchEmissiveForLightMap: function(src) {
      let object = src.clone()
      // console.debug(object.name, object.material)
      if (!object.material || !object.material.emissiveMap) {
        console.warn('no switch for', object.name)
        return object
      }
      const lm = object.material.emissiveMap.clone()
      object.material.emissiveMap = null
      object.material.emissiveIntensity = 0
      object.material.lightMap = lm
      // empirical values (Jo)
      object.material.lightMapIntensity = 3
      object.material.envMapIntensity = 0.1
      return object
    },

    /** glow effect from
     * https://stemkoski.github.io/Three.js/Shader-Glow.html
     */
    makeSiflinxGlow: function () {
      const siflinx = this.columns.getObjectByName('Colonne_11_sifflinx')
      const defaultMaterial = new THREE.MeshBasicMaterial()
      const glowGeom = new THREE.SphereGeometry(constants.GLOW_RADIUS, 64, 32)
      const glowMaterial = new THREE.ShaderMaterial({
	uniforms: 
	{ 
	  "c":   { type: "f", value: 0.1 },
	  "p":   { type: "f", value: 6 },
	  glowColor: { type: "c", value: new THREE.Color(0x777777) },
	  viewVector: { type: "v3", value: this.player.position }
	},
	vertexShader: glowVertexShader,
	fragmentShader: glowFragmentShader,
        blending: THREE.AdditiveBlending,
	transparent: true,
      })
      this.siflinxGlow = new THREE.Mesh(glowGeom, glowMaterial)
      //Screen: pixel = (src + dst) - (src * dst)
      /* resets siflinx material for blending to work */
      siflinx.material = defaultMaterial
      
      /* centers glow on siflinx */
      const center = new THREE.Vector3()
      siflinx.geometry.computeBoundingBox()
      siflinx.geometry.boundingBox.getCenter(center)
      center.add(siflinx.position)
      this.siflinxGlow.position.copy(center)
      this.scene.add(this.siflinxGlow)
    },

    updateSiflinxGlow: function () {
      this.siflinxGlow.material.uniforms.viewVector.value = 
        new THREE.Vector3().subVectors(this.player.position, this.siflinxGlow.position)
      this.siflinxGlow.material.uniforms.p.value += this.glowVariation
      if (this.siflinxGlow.material.uniforms.p.value > 6 ||
          this.siflinxGlow.material.uniforms.p.value < 4) {
        this.glowVariation *= -1
      }
    },
    
    /**
     * creates the whole cave
     * - walls and floor
     * - columns
     * - halo for siflinx
     * - bas-reliefs on walls
     */
    createCave: async function() {
      /* walls and floor */
      this.cave = this.switchEmissiveForLightMap(this.assets.walls.scene.children[0])
      this.floor = this.switchEmissiveForLightMap(this.assets.ground.scene.children[0])

      /* set center to cave center */
      this.cave.geometry.computeBoundingBox()
      const bb = new THREE.Box3().setFromObject(this.cave)
      bb.getCenter(this.caveCenter)
      this.caveCenter.setY(0)

      /* columns */
      this.columns = new THREE.Group()
      this.assets.columns.scene.children.forEach((c) => {
        this.columns.add(this.switchEmissiveForLightMap(c))
      })
      this.makeSiflinxGlow()
      
      /* bas-reliefs */
      this.basreliefs = new THREE.Group()
      this.assets.basreliefs.scene.children.forEach((c) => {
        this.basreliefs.add(this.switchEmissiveForLightMap(c))
      })
      
      /* adds all to scene */
      this.scene.add(this.cave)
      this.scene.add(this.floor)
      this.scene.add(this.columns)
      this.scene.add(this.basreliefs)

      this.unavailablePositions = [
        ...this.columns.children.map((c) => c.position),
        ...this.basreliefs.children.map((c) => c.position),
        ...this.waterfalls.children.map((b) => b.position)
      ]

      this.createEE()
    },

    createEE: function() {
      const geo = new THREE.BoxGeometry(20, 20, 0)
      const mat = new THREE.MeshBasicMaterial(
        {
          visible: true,
          transparent: true,
          map: this.assets.ee
        }
      )
      this.ee = new THREE.Mesh(geo, mat)
      this.ee.position.set(-90, 5, 70)
      this.ee.rotateY(-Math.PI / 2)
      this.scene.add(this.ee)
    },
    
    /**
     * Finds a free spot to add an object
     * A free spot is defined as a position at leas 10 unit away
     * from any unavailable position (parameter)
     */
    findAvailablePosition: function() {
      let positionOK = false
      const v = new THREE.Vector3()
      while (!positionOK) {
        // gets a random position in a circle centered on the cave's center.
        // const r = 40 * Math.random()
        // const x = r * Math.cos(theta)
        // const z = r * Math.sin(theta)
        // gets a random position in an ellipsis centered on the cave's center.
        const theta = 2 * Math.PI * Math.random()
        const tantheta2 = Math.tan(theta) * Math.tan(theta)
        // cavern is roughly an ellipsis with a = 60 and b = 35
        // avoid center
        const a = 20 + 38 * Math.random(), b = 10 + 24 * Math.random()
        const x = (Math.random() > .5 ? 1 : -1) * (a * b) / Math.sqrt(b * b + a * a * tantheta2)
        const z = (Math.random() > .5 ? 1 : -1) * Math.sqrt(1 - (x / a) * (x / a)) * b

        // check if it is not too close from another object.
        positionOK = true
        for (var position of this.unavailablePositions) {
          v.set(x, position.y, z).add(this.caveCenter)
          if (position.distanceTo(v) < 10) {
            positionOK = false
            break
          }
        }
      }
      this.unavailablePositions.push(v)
      return v
    }
  }
}
