<template>
  <div :class="['fullpage', 'black', {'resuming': resuming}]">
    <div class="container">
      <Content :is="pauseContent" />
      <div class="bottom-menu">
        <a
          v-for="(button, idx) in buttons"
          :key="`button-link-${idx}`"
          :class="['link', { active: button == buttons[currentButton] }]"
          @click="button.action"
          >
          <img :src="button.imgSrc" class="image-title" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import Biome1En from '@/components/pauses/Biome1En'
import Biome1Fr from '@/components/pauses/Biome1Fr'
import Biome2En from '@/components/pauses/Biome2En'
import Biome2Fr from '@/components/pauses/Biome2Fr'
import Biome3En from '@/components/pauses/Biome3En'
import Biome3Fr from '@/components/pauses/Biome3Fr'
import Biome4En from '@/components/pauses/Biome4En'
import Biome4Fr from '@/components/pauses/Biome4Fr'

export default {
  name: 'Pause',
  components: {
    Biome1En,
    Biome1Fr,
    Biome2En,
    Biome2Fr,
    Biome3En,
    Biome3Fr,
    Biome4En,
    Biome4Fr,
  },
  /**
   * data to keep track of the state of the Pause screen
   * currentButton: position in the menu
   */
  data: function () {
    return {
      currentButton: 0,
      resuming: false,
    }
  },
  computed: {
    ...mapState({
      currentLevel: (state) => state.currentLevel,
      isPaused: (state) => state.pause,
      lang: (state) => state.lang,
    }),
    otherLang: function() {
      return (this.lang == 'fr') ? 'en' : 'fr'
    },
    buttons: function() {
      return [
        {
          action: () => this.resume(),
          imgSrc: require('@/assets/images/' + this.lang + '/' + 'play.png'),
        },
        {
          action: () => this.quit(),
          imgSrc: require('@/assets/images/' + this.lang + '/' + 'menu.png'),
        },
        {
          action: () => this.$store.commit('setLang', this.otherLang),
          imgSrc: require('@/assets/images/' + this.otherLang + '.png'),
        },
      ]
    },
    pauseContent: function() {
      const contents = {
        'fr': [
          '', 'Biome1Fr', 'Biome2Fr', 'Biome3Fr', 'Biome4Fr'
        ],
        'en': [
          '', 'Biome1En', 'Biome2En', 'Biome3En', 'Biome4En'
        ]
      }
      return contents[this.lang][this.currentLevel]
    }
  },
  /**
   * Resets the button states and gives focus
   * to 'container' to be sure that the 'keyup' event is catched
   */
  mounted: function () {
    window.addEventListener('keyup', this.onKeyUp)
  },
  beforeDestroy: function () {
    window.removeEventListener('keyup', this.onKeyUp)
  },
  methods: {
    /**
     * resumes game
     */
    resume: function () {
      this.resuming = true
      setTimeout(() => {
        this.$store.commit('togglePause')
        this.resuming = false
      }, 400);
    },
    /**
     * quits game and go to home
     */
    quit: function () {
      this.$store.commit('setAnimating', false)
      this.$store.commit('restart')
    },
    /**
     * keyboard events handler
     */
    onKeyUp: function (event) {
      const kc = event.code
      // if (kc == 'KeyW' || kc == 'ArrowUp') {
      //   this.currentButton =
      //     (this.currentButton - 1 + this.buttons.length) % this.buttons.length
      // }
      // if (kc == 'KeyS' || kc == 'ArrowDown') {
      //   this.currentButton = (this.currentButton + 1) % this.buttons.length
      // }
      if (kc == 'KeyA' || kc == 'ArrowLeft') {
        this.currentButton =
          (this.currentButton - 1 + this.buttons.length) % this.buttons.length
      }
      if (kc == 'KeyD' || kc == 'ArrowRight') {
        this.currentButton = (this.currentButton + 1) % this.buttons.length
      }
      if (kc == 'Enter' || kc == 'Space') {
        this.buttons[this.currentButton].action()
      }
    },
  },
}
</script>

<style scoped src="@/assets/css/menus.css" />
<style scoped>
@keyframes slideout {
  from {
    margin-top: 0;
  }

  to {
    margin-top: -100%
  }
}

.fullpage.resuming {
  animation: .5s slideout linear;
  animation-fill-mode: forwards;
}
</style>
