<template>
  <div>
    <h1>
      <img
        src="@/assets/images/fr/credits.png"
        class="image-title"
      />
    </h1>
    <div class="text">
      <div class="scrollable">
        <p>
          Réalisation &mdash; Io Burgard<br/>
          Production &mdash; billie_ness<br/>
          <br/>
          Développement du jeu vidéo &mdash; Out of Pluto<br/>
          Borne d'arcade et manette de jeu &mdash; Out of Pluto<br/>
          <br/>
          Modélisation 3D &mdash; Anaïs Goupy, Lauren Sanchez<br/>
          Musique &mdash; Pierre Lucas
        </p>    
        <p>
          Ce projet a été sélectionné par la commission mécénat de la Fondation des artistes qui lui a apporté son soutien.<br>
          Avec le soutien du CNC (Centre national du cinéma et de l’image animée) et de la SCAM. 
        </p>
        <p>
          La manette de jeu a été produite avec le soutien du CAC (Centre d'Art Contemporain) La Traverse, Alfortville.
        </p>
        <br/>
        <p>
          Remerciements
          <br/>
          Programme Orange Rouge
          <br/>
          Eva Vlasamitzi
          <br/>
          Les collégiens de la classe ULIS du lycée Beau soleil à Chelles
          <br/>
          AIT IDIR Shana
          <br/>
          CALDEIRA Alex
          <br/>
          DIAKHITE Khassa
          <br/>
          DOVGAL Nikita EDDAM Nawel
          <br/>
          GUILLEMOT Océane
          <br/>
          LOUIS Romuald
          <br/>
          GHARSALLAH Chahine
          <br/>
          MIELLE Lucas
          <br/>
          POMMARAIS Bruce
          <br/>
          RAVINDRARAJAH Talisha
          <br/>
          OGBONI Enzo
          <br/>
          RODRIGUES-JAMAULT Chloé
          <br/>
          SIMOES Angelo
          <br/>
          Et leur enseignante Charlotte Veglia
          <br/><br/>
          Iommy Sanchez
          <br/>
          Anthony Baillard
          <br/>
          Jonathan Cotant
          <br/>
          Pauline Ohanna
          <br/>
          Gil Gharbi
          <br/>
          Laureline Deloingce
          <br/>
          Roch Burgard
        </p>
        <div class="logos">
          <div>
            <img src="@/assets/images/logos/billieness.png" alt="billie_ness" class="logo">
          </div>
          <div>
            <img src="@/assets/images/logos/outofpluto.png" alt="Out of Pluto" class="logo">
          </div>
        </div>
        <div class="logos">
          <div>
            <img src="@/assets/images/logos/cnc.png" alt="CNC" class="logo">
          </div>
          <div>
            <img src="@/assets/images/logos/scam.png" alt="SCAM" class="logo">
          </div>
          <div>
            <img src="@/assets/images/logos/fondation-des-artistes.png" alt="Fondation des artistes" class="logo">
          </div>
          <div>
            <img src="@/assets/images/logos/latraverse.png" alt="CAC La Traverse" class="logo">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CreditsFr'
}
</script>

<style scoped src="@/assets/css/menus.css" />
