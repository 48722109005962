import * as THREE from 'three'

import constants from '@/components/biomes/biome1/constants.js'

export const cameraMixin = {
  data: function() {
    return {
      spectateur: new THREE.Group(),
      mainCamera: {},
      camera1: {},
      camera2: {},
      mainCameraFov: 85, //138, //75,
      camCenterHeight: 23, //5, // 12,
      cameraPath: null,
      cameraIntroPath: null,
    }
  },
  methods: {
    initCamera: function () {
      const sceneRatio =
            this.sceneContainer.clientWidth / this.sceneContainer.clientHeight

      /* main camera */
      this.camera1 = new THREE.PerspectiveCamera(
        this.mainCameraFov, sceneRatio, 1, 300
      )
      this.camera1.position.set(0, 0, 0)
      // this.camera1.up.set(0,1,0)
      this.mainCamera = this.camera1

      /* spectateur group */
      this.spectateur.camera = this.camera1
      // this.spectateur.light = new THREE.PointLight(0xffffff, 1000.0)
      // this.spectateur.add(this.spectateur.light)
      this.spectateur.add(this.spectateur.camera)
      this.scene.add(this.spectateur)
      this.spectateur.camera.lookAt(0, 0, 1)

      // this.controls = new OrbitControls(this.mainCamera, this.sceneContainer)
    },

    initCameraAnimations: function () {
      this.initializeCameraEndPath()
      this.initializeCameraIntroPath()
      console.debug('camera animations initialized', ...this.spectateur.position)
    },

    moveCameraOnIntroPath: function () {
      /* animationProgress-dependant animations */
      this.animationProgress =
        THREE.MathUtils.mapLinear(
          this.now,
          this.animationStart,
          this.animationEnd,
          0,
          1
        ) ** 2 /* accélération / pesanteur */
      if (this.animationProgress <= 1.0) {
        this.cameraIntroPath.getPoint(
          this.animationProgress,
          this.spectateur.position
        )
        const tg0 = new THREE.Vector3(0, this.spectateur.position.y, 0)
        const tg1 = new THREE.Vector3(0, this.camCenterHeight, 0)
        const tg = new THREE.Vector3().lerpVectors(tg0, tg1, this.animationProgress)
        this.spectateur.lookAt(tg)
      }
    },

    moveCamera: function () {
      if (this.biomeState == 'INTRO') {
        this.moveCameraOnIntroPath()
      }
      /* --- camera move during recording ---- */
      if (this.biomeState == 'RECORDING') {
        this.moveCameraOnRecordingPath()
      }
    },
    
    moveCameraOnRecordingPath: function () {
      /* camera handler */
      // console.debug(this.recordingProgress, this.spectateur.position)
      this.recordingProgress = THREE.MathUtils.clamp(this.recordingProgress, 0, 1)
      this.cameraPath.getPoint(this.recordingProgress, this.spectateur.position)
      const tg = new THREE.Vector3(
        0,
        this.camCenterHeight - 40 * this.recordingProgress,
        0
      )
      this.spectateur.lookAt(tg)
    },

    initializeCameraEndPath: function () {
      const r = constants.CAM_RADIUS
      let phi = (90 - constants.CAM_H_ANGLE_DEG) * 0.01745
      this.cameraPath = new THREE.SplineCurve([
        new THREE.Vector3().setFromSphericalCoords(r, phi, 0.5 * Math.PI),
        new THREE.Vector3(30, 90, 0),
        new THREE.Vector3(0, -20, 0),
      ])
      
      /* un-comment to show debug camera path */
      // const points = this.cameraPath.getPoints(50)
      // const geo = new THREE.BufferGeometry().setFromPoints(points)
      // const mat = new THREE.LineBasicMaterial({color: 0xffffff})
      // const line = new THREE.Line(geo, mat)
      // this.scene.add(line)
      
      /* putting the spectateur at point 0 of camera path */
      // this.cameraPath.getPoint(0, this.spectateur.position)
      const tg = new THREE.Vector3(0, this.camCenterHeight, 0)
      this.spectateur.lookAt(tg)
    },

    initializeCameraIntroPath: function () {
      const r = constants.CAM_RADIUS
      const finalPos = new THREE.Vector3()
      this.cameraPath.getPoint(0, finalPos)
      const underWaterPos = new THREE.Vector3().copy(finalPos).setY(-3)
      const overWaterPos = new THREE.Vector3().copy(finalPos).setY(15)
      this.cameraIntroPath = new THREE.SplineCurve([
        new THREE.Vector3().setFromSphericalCoords(r, 0, 0.5 * Math.PI),
        underWaterPos,
        overWaterPos,
        finalPos,
      ])
      /* sinon un truc du genre oscillateur amorti : exp(-t/τ) * (cos(Ωt)+C*sin(Ωt)) */
    }
  }
}
