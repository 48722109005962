<template>
  <div>
    <div class="text pause">
      <img src="@/assets/biomes/biome2/instructions.png">
      <h1>À moitié vide ou complètement plein&nbsp;?</h1>
      <p v-if="appVersion == 'web'">
        Utilisez <img class="keys-2" src="@/assets/images/arrows.png"> ou <img class="keys-2" src="@/assets/images/ZQSD.png"> pour vous déplacer,<br>
        <img class="keys-1" src="@/assets/images/entree.png"> ou <img class="keys-1" src="@/assets/images/space.png"> pour prendre et poser les objets,<br>
        <img class="keys-1" src="@/assets/images/p.png"> pour la pause.
      </p>
      <p v-else>
        Utilisez <img class="keys-1" src="@/assets/images/cross.png"> pour vous déplacer,<br>
        <img class="keys-1" src="@/assets/images/button_A.png"> ou <img class="keys-1" src="@/assets/images/button_B.png"> pour prendre et poser les objets,<br>
        <img class="keys-1" src="@/assets/images/button_C.png"> pour la pause.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Biome2Fr',
  computed: {
    appVersion: () => process.env.VUE_APP_VERSION,
  }
}
</script>

<style scoped src="@/assets/css/menus.css" />
